import React from 'react' // class
import {withRouter} from 'react-router-dom' // class 
import './Agrivore.css';

class Produit extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            img_produit_80: null, // declare vars
        }
    }
    

    componentDidMount(){ // quand composant est monté / attaché au DOM cf cycle de vie d'un composant : https://fr.reactjs.org/docs/state-and-lifecycle.html
        // import("./" + this.props.produit.pr_ico).then((result) => this.setState({img_produit_80:  result.default})); // plus besoin si images dans /public 
    }
      

    render(){ 


        // let img_produit_80 = this.state.img_produit_80

        return(
            
            <div className="mb-3">
                <div className=" p-3" style={{ width: "100%" }}>
                    <div className="row">
                        <div className="col-12">
                            <h3 className="card-text text-center" style={{color:"grey", marginBottom: "0.5em", fontWeight: "bold" }}>
                                {/* {this.props.lProduits[0].pr_libelle}<br/> */}
                                {this.props.produit.pr_libelle}<br />
                            </h3>
                        </div>
                            <img className="centerBlock" width="50px" src={process.env.PUBLIC_URL + "/" + this.props.produit.pr_ico} alt=" " />
                            {/* {img_produit_80 && <img src={img_produit_80} alt="Card cap"/>  }  */}
                            {/* <img src={require(`${this.props.produit.pr_ico}`)} alt="Card cap"/> */}
                            {/* {img_producteur_160 && <img src={img_producteur_160} alt="Card cap" style={{ width:'14em', }}/>  }  */}
                        </div>
                        
                </div>
            </div>

        )
    }
}

export default withRouter(Produit);