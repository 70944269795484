import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import API from './api';


class ProducteurGestionArticles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dProducteur: {},
            lProduits: [],
            lProduitsDetail: [],
            lArticles: [],
            bAdminArticles: false
        }
        this.lIdArticles = []
    }

    updateBDD = e => {  //LB : methode appelée lorsqu'on submit le formulaire
        e.preventDefault()     //LB : annule le comportement par défaut (c'est à dire le refresh de la page)

        let lIdArticlesSelected = this.state.lArticles.filter(article => document.getElementById(article.idarticle).checked).map(article => article.idarticle.toString()) //Recupere la liste des id de tous les articles coches
        let lArticlesProdDefault = this.state.dProducteur.articles.map(article => article.idarticle.toString()) //Liste des id des articles que le producteur avait initialement
        let lArticles_Add = lIdArticlesSelected.filter(idarticle => !lArticlesProdDefault.includes(idarticle.toString())) //On determine les relations a ajouter
        let lArticles_Delete = lArticlesProdDefault.filter(idarticle => !lIdArticlesSelected.includes(idarticle.toString())) //Et celles a supprimer

        let urlArticles = '/producteur/' + this.props.dUserInformations.idProducteur + '/article/'
        let nbRequetesTotal = lArticles_Add.length + lArticles_Delete.length
        this.nbRequetesExecutees = 0
        for (let idArticle of lArticles_Add) { //Pour toutes les relations a ajouter
            API.post(urlArticles + idArticle).then(() => { //On effectue une requete AJAX d'Insert
                this.nbRequetesExecutees += 1
                if (this.nbRequetesExecutees === nbRequetesTotal) {
                    window.location.reload()
                }
            })
        }

        for (let idArticle of lArticles_Delete) { //Pour toutes les relations a supprimer
            API.delete(urlArticles + idArticle, { headers: { "Authorization": "***" } }).then(() => { //On effecute une requete AJAX de Delete
                this.nbRequetesExecutees += 1
                if (this.nbRequetesExecutees === nbRequetesTotal) {
                    window.location.reload()
                }
            })
        }
    }

    updateArticle = (idArticle, paramsRequest) => {
        API.put("/article/" + idArticle, { paramsRequest })
    }

    getTablArticlesPersos = () => {
        let lArticlesPersos = this.state.dProducteur.articles.filter(article => article.a_idproducteur === this.state.dProducteur.idproducteur)

        let tablArticlesPersos = lArticlesPersos.sort((a, b) => a.a_libelle > b.a_libelle ? 1 : -1).map(article =>
            <tr key={article.idarticle}>
                <td scope="row">
                    <span>{article.a_libelle}</span>
                </td>
                <td>
                    {this.state.bAdminArticles ?
                        <textarea defaultValue={parseFloat(article.a_prixvente1).toFixed(2).toString().replace(".", ",")} onBlur={(e) => {
                            let paramsRequest = { a_prixvente1: e.target.value.replace(",", ".").replace(/[^0-9.]+/g, "") }
                            this.updateArticle(article.idarticle, paramsRequest)
                        }}></textarea>
                        :
                        <span>{parseFloat(article.a_prixvente1).toFixed(2).toString().replace(".", ",")}€</span>
                    }
                </td>
                <td>
                    <img width="40px" src={process.env.PUBLIC_URL + "/" + article.a_img}></img>
                </td>
                <td>
                    {this.state.bAdminArticles ?
                        <textarea defaultValue={article.a_descriptif} onBlur={(e) => {
                            let paramsRequest = { a_descriptif: e.target.value }
                            this.updateArticle(article.idarticle, paramsRequest)
                        }}></textarea>
                        :
                        <span>{article.a_descriptif}</span>
                    }
                </td>
                <td>
                    {this.state.bAdminArticles ?
                        <textarea defaultValue={article.a_ingredient} onBlur={(e) => {
                            let paramsRequest = { a_ingredient: e.target.value }
                            this.updateArticle(article.idarticle, paramsRequest)
                        }}></textarea>
                        :
                        <span>{article.a_ingredient}</span>
                    }
                </td>
                <td>
                    {this.state.bAdminArticles ?
                        <input type="date" defaultValue={article.a_dispodatedeb} onInput={(e) => {
                            let value = e.target.value.length > 0 ? e.target.value : null
                            let paramsRequest = { a_dispodatedeb: value }
                            this.updateArticle(article.idarticle, paramsRequest)
                        }} />
                        :
                        <span>
                            {article.a_dispodatedeb !== "None" ?
                                article.a_dispodatedeb.split('-')[2] + "/" + article.a_dispodatedeb.split('-')[1] + "/" + article.a_dispodatedeb.split('-')[0]
                                :
                                ""
                            }
                        </span>
                    }
                </td>
                <td>
                    {this.state.bAdminArticles ?
                        <input type="date" defaultValue={article.a_dispodatefin} onInput={(e) => {
                            let value = e.target.value.length > 0 ? e.target.value : null
                            let paramsRequest = { a_dispodatefin: value }
                            this.updateArticle(article.idarticle, paramsRequest)
                        }} />
                        :
                        <span>
                            {article.a_dispodatefin !== "None" ?
                                article.a_dispodatefin.split('-')[2] + "/" + article.a_dispodatefin.split('-')[1] + "/" + article.a_dispodatefin.split('-')[0]
                                :
                                ""
                            }
                        </span>
                    }
                </td>
            </tr>
        )

        return (
            <table className="table table-striped table-bordered">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">
                            Libelle
                        </th>
                        <th scope="col">
                            Prix TTC
                        </th>
                        <th scope="col">
                            Image
                        </th>
                        <th scope="col">
                            Description
                        </th>
                        <th scope="col">
                            Ingrédients
                        </th>
                        <th scope="col">
                            Disponible à partir de...
                        </th>
                        <th scope="col">
                            Jusque...
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tablArticlesPersos}
                </tbody>
            </table>
        )
    }

    getArticles = () => {
        let lIdArticlesChecked = this.state.dProducteur.articles.map(article => article.idarticle)

        let lProduits = []

        for (let produit of this.state.lProduits) {
            let lProduitsDetail = []

            for (let produitDetail of this.state.lProduitsDetail.filter(prodDetail => prodDetail.prd_produit === produit.idproduit)) {
                let lArticles = []

                for (let article of this.state.lArticles.filter(art => art.a_idproduitdetail === produitDetail.idproduitdetail && art.a_idproducteur === null)) {
                    lArticles.push(
                        <div key={article.idarticle} className="form-check">
                            <input className="form-check-input" defaultChecked={lIdArticlesChecked.includes(article.idarticle)}
                                type="checkbox" id={article.idarticle} />
                            <label className="ml-2 mt-1 form-check-label" htmlFor={article.idarticle}>
                                {article.a_libelle}
                                <img className="ml-2" width='20px' src={process.env.PUBLIC_URL + "/" + article.a_img} alt="" />
                            </label>
                        </div>
                    )
                }
                if (lArticles.length > 0) {
                    lProduitsDetail.push(
                        <div key={produitDetail.idproduitdetail} style={{ maxHeight: "300px", overflow: "scroll" }} className="border m-2 p-2">
                            <h6 className="text-center">
                                {produitDetail.prd_libelle}
                                <img className="ml-2" width='20px' src={process.env.PUBLIC_URL + "/svg/" + produitDetail.prd_image} alt="" />
                            </h6>
                            <hr />
                            {lArticles}
                        </div>
                    )
                }
            }
            if (lProduitsDetail.length > 0) {
                lProduits.push(
                    <div key={produit.idproduit} className="border p-2 m-5">
                        <h4 className="text-center">
                            {produit.pr_libelle}
                            <img className="ml-2" width='30px' src={process.env.PUBLIC_URL + "/" + produit.pr_ico} alt="" />
                        </h4>
                        <div className="row m-2 justify-content-center">
                            {lProduitsDetail}
                        </div>
                    </div>
                )
            }
        }

        return (
            <>
                <h4 className="text-center mt-4">Autres articles que vous produisez</h4>
                <span className="text-center">
                    Ces articles ne peuvent pas être personnalisés. Ils ne sont donc pas disponibles à la vente.<br />
                    Ils permettent cependant de mettre en avant votre activité, et seront personnalisables prochainement.<br />
                    Cochez les articles que vous produisez, et enregistrez les modifications
                </span>
                <form>
                    <input type="submit" className="mt-2 centerBlock" value="Enregistrer les modifications" onClick={(e) => this.updateBDD(e)} />
                    <div className="row justify-content-center">
                        {lProduits}
                    </div>
                </form>
            </>
        )
    }

    getProducteur = () => {
        let idProducteurRecherche = document.getElementById("idProdRecherche").value
        if (idProducteurRecherche.length > 0) {
            window.location = ("/gestionArticles/" + idProducteurRecherche)
        }
    }

    componentDidMount = () => {
        this.controller = new AbortController()

        if (!isNaN(this.props.dUserInformations.idProducteur)) {
            API.get("/producteur/" + this.props.dUserInformations.idProducteur, { params: { type_json: "producteur", filterBy: "idproducteur" }, signal: this.controller.signal })
                .then(response => {
                    this.setState({
                        dProducteur: response.data.producteur,
                    })
                })
        }

        API.get("/produits", { signal: this.controller.signal }).then(response => this.setState({
            lProduits: response.data.produits
        }))

        API.get("/produitsDetail", { signal: this.controller.signal }).then(response => this.setState({
            lProduitsDetail: response.data.produitsDetail
        }))

        API.get("/articles", { signal: this.controller.signal }).then(response => {
            this.lIdArticles = response.data.articles.map(article => article.idarticle)
            let lArticlesDisponibles = response.data.articles.filter(article => article.a_idproducteur === null || article.a_idproducteur === this.props.dUserInformations.idProducteur)

            this.setState({ lArticles: lArticlesDisponibles })
        })
    }

    componentWillUnmount = () => {
        this.controller.abort()
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="card">
                        {(this.state.dProducteur !== null && this.props.dUserInformations.bIsConnected && this.props.dUserInformations.bIsProducteur) ?
                            (Object.entries(this.state.dProducteur).length > 0 ?
                                <>
                                    <h3 className="text-center mt-3">{this.state.dProducteur.label1}</h3>
                                    <Link to={"/producteur/" + this.state.dProducteur.p_url_servie} target="_blank" rel="noreferrer">
                                        <button className="centerBlock btn btn-outline-secondary">Votre fiche producteur</button>
                                    </Link>
                                    <hr />
                                    <svg    //LB : Svg pour scroll en haut de la page
                                        style={{ width: '50px', height: '50px', position: "fixed", right: '50px', bottom: '50px' }}
                                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                                    </svg>
                                    <h4 className="text-center">Voici vos articles personnalisés :</h4>
                                    <div className="custom-control custom-switch text-center">
                                        <input onInput={(e) => {
                                            if (!e.target.checked) window.location.reload()
                                            this.setState({ bAdminArticles: !this.state.bAdminArticles })
                                        }
                                        } type="checkbox" className="custom-control-input" id="adminArticles" />
                                        <label className="custom-control-label" htmlFor="adminArticles">Administrer les articles</label>
                                    </div>
                                    <div style={{ overflowX: "scroll" }}>
                                        {this.getTablArticlesPersos()}
                                    </div>
                                    {this.getArticles()}
                                </>
                                :
                                <div className="mt-5">
                                    <h3 className="text-center">Récupération des données...</h3>
                                </div>
                            )
                            :
                            <div className="mt-5 text-center">
                                <h3>Vous n'avez pas accès à cette page</h3>
                            </div>
                        }
                    </div>

                </div>
            </>
        )
    }
}

export default withRouter(ProducteurGestionArticles)