import React from 'react' // class
import {withRouter} from 'react-router-dom' // class 
import './Agrivore.css';
import api from './api';

class InfoClient extends React.Component{

    constructor(props){
        super(props)

        this.state = {
           isModif: false 
        }
    }

   

    modifInfoClient = () => { // PB 27/06/2022 Verif entrées + appel à l'api pour modfi
        this.setState({isModif: false})
        let adresseEntered = document.getElementById("adresseEntered").value
        let postalEntered = document.getElementById("postalEntered").value
        let villeEntered = document.getElementById("villeEntered").value
        let portableEntered = document.getElementById("portableEntered").value
        let telephoneEntered = document.getElementById("telephoneEntered").value
        //PB 27/06/2022 si portable >0 verif si num à 10 chiffres                       si telephone >0 verif si num à 10 chiffres                               code postal contient 5 chiffres        adresse renseignée ? obligatoire     ville renseignée ? obligatoire                                                                                          
        if(( portableEntered.length >0  && !/^([0-9]{10})$/.test(portableEntered)) || ( telephoneEntered.length>0 &&!/^([0-9]{10})$/.test(telephoneEntered)) ||  !/^([0-9]{5})$/.test(postalEntered) || adresseEntered.trim().length == 0 || villeEntered.trim().length ==0 ){
            document.getElementById("erreurCommande").innerHTML = "Information invalide :\n Les numéros de téléphone doivent contenir 10 chiffres, le code postal doit contenir 5 chiffres, adresse et ville doivent être renseignées"
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"
            return
        }

        let paramsRequest = {
            adresse : adresseEntered,
            postal : postalEntered,
            ville : villeEntered,
            portable : portableEntered,
            telephone : telephoneEntered
        }


        api.put("/Client/"+this.props.dUserInformations.idUtilisateur, { paramsRequest } ,{ headers: { "Authorization": "***" }} )
        .then(()=>{ // on MAJ pour l'affichage des nouvelles données
            let user = this.state.UserData
            user.c_adresse = adresseEntered
            user.c_postal = postalEntered
            user.c_ville = villeEntered
            user.c_portable = portableEntered
            user.c_telephone = telephoneEntered
            this.setState({
                UserData: user
            })
           

            document.getElementById("validationCommande").style.display = "block"
            document.getElementById("erreurCommande").style.display = "none"

        }) // on rafraichi les nouvelles données
        .catch(e => { // si erreur API
            document.getElementById("erreurCommande").innerHTML = "Une erreur est survenu, veuillez réessayer ultérieurement"
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"

        })
    }

    componentDidMount(){ 
        api.get("/Client/"+this.props.dUserInformations.idUtilisateur).then(result =>this.setState({UserData: result.data})).catch(e => alert(e)) // chargement des données personnelles 
    }
      

    render(){ 

        return( 

            <>
            <div className='w-100 p-2' >

                {this.props.dUserInformations.bIsConnected ?

                    typeof this.state.UserData!=='undefined' /*|| Object.entries(this.state.UserData).length > 0*/ ?
                        <div className="card p-3 container" style={{ width: "90%" }}>
                            <div className='d-flex'>
                                <span className='font-weight-bold mr-auto p-2'>Informations personnelles</span>
                                <img alt="modifier" onClick={()=>this.setState({isModif: true})} className='p-2 btn btn-outline-secondary' height="50px" src={process.env.PUBLIC_URL + "/svg/pen.svg"}/>
                            </div>
                            <div className="rounded pt-2 pb-2 my-2" style={{ border: "solid 1px #8E8E8E" }}>
                                {
                                    this.state.isModif ?
                                        <div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Nom : </span>
                                                <span className='pl-1 text-left m-1 '> {this.state.UserData.c_nom}</span>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25  m-1 text-right'> Mail : </span>
                                                <span className='pl-1 text-left m-1'> {this.state.UserData.c_mel}</span>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Adresse : </span>
                                                <div>
                                                    <input type='text' id='adresseEntered' className='text-left w-35 pl-1 m-1' defaultValue={this.state.UserData.c_adresse}/>
                                                    <input type='text' pattern='[0-9]{5}' id='postalEntered' className='text-left w-25 pl-1 m-1' defaultValue={this.state.UserData.c_postal}/>
                                                    <input type='text' className='text-left w-25 pl-1 m-1' id='villeEntered' defaultValue={this.state.UserData.c_ville}/>
                                                </div>
                                                
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Portable : </span>
                                                <input type='tel' pattern="[0-9]{10}" id='portableEntered' className='text-left pl-1 m-1' defaultValue={this.state.UserData.c_portable}/>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Telephone : </span>
                                                <input type='tel' pattern='[0-9]{10}' id='telephoneEntered' className='text-left pl-1 m-1' defaultValue={this.state.UserData.c_telephone}/>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Nom : </span>
                                                <span className='pl-1 text-left m-1 '> {this.state.UserData.c_nom}</span>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Courriel : </span>
                                                <span className='pl-1 text-left m-1'> {this.state.UserData.c_mel}</span>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Adresse : </span>
                                                <span className='pl-1 text-left m-1'>
                                                    {this.state.UserData.c_adresse},  {this.state.UserData.c_postal}, {this.state.UserData.c_ville}
                                                </span>
            
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Portable : </span>
                                                <span className='text-left pl-1 m-1'> {this.state.UserData.c_portable}</span>
                                            </div>
                                            <div className='d-flex p-2'>
                                                <span className='font-weight-bold w-25 m-1 text-right'> Téléphone : </span>
                                                <span className=' text-left pl-1 m-1'> {this.state.UserData.c_telephone}</span>
                                            </div>

                                        </div>
                                }

                                
                            </div>
                            <div>
                                <div className='d-flex justify-content-around'>
                                    {this.state.isModif && <button onClick={() => {this.modifInfoClient()}}  className='btn btn-secondary pr-3'><strong>Modifier</strong></button>}
                                    <button onClick={() => {this.props.methUtilisateurDeconnecte(); window.location='/'}} className='btn btn-danger p-3'><strong>se deconnecter</strong> </button>
                                </div>
                            </div>    
                                
                        
                            <div className='d-flex justify-content-center mt-3'>
                                <div style={{ display: "none", border: 'solid', maxWidth: "600px" }} className="rounded alert alert-danger text-center" id="erreurCommande"></div>
                                <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded alert alert-success text-center" id="validationCommande">
                                    Vos informations personnelles ont été modifiées.
                                </div>
                            </div>
                        </div>
                        :
                        <div className="card p-3" style={{ width: "90%" }}>
                            <span> Récupération des données  </span>
                        </div>
             
                    :

                    <div className="container">
                        <div className="card p-3 mt-5 bg-warning text-center">
                            <h3>Connectez-vous pour accéder à cette page</h3>
                        </div>
                    </div>
 
             }
            </div>
            </>
           

        )
    }
}

export default withRouter(InfoClient);