import React from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios'
import API from './api'

class Login extends React.Component {
    constructor(props){
        super(props)
        this.controller = new AbortController()
        this.state = {
            bRecupMdp: false //si false : affiche le formulaire de connexion ; si true : affiche le formulaire de recuperation du mdp
        }
    }

    demandeRecuperationMDP = (e) => {
        let saisieMailRecup = document.getElementById("inputRecupMdp").value
        document.getElementById("validationRecuperation").style.display="none"
        document.getElementById("erreurRecuperation").style.display="none"

        if(saisieMailRecup.trim() === ""){
            document.getElementById("erreurRecuperation").innerHTML="<span>Veuillez entrer une adresse mail.</span>"
            document.getElementById("erreurRecuperation").style.display="block"
            return false
        }

        API.get('/utilisateur/' + saisieMailRecup, {params:{filterBy:"email_address"}})
        .then(response => {
            let divReponse
            if(response.data.resultat){
                divReponse = document.getElementById("validationRecuperation")
            } else {
                divReponse = document.getElementById("erreurRecuperation")
            }
            divReponse.innerHTML="<span>"+response.data.message+"</span>"
            divReponse.style.display="block"
        })
        .catch(error => {
           alert("La récupération du mdp a echoué")
        })    }

    essaiConnexion = (retourBackend) => { // backend renvoie false (boolean) ou un dictionnaire user (object)
        if (typeof(retourBackend) === "boolean") {
            document.querySelector('#erreurConnexion').innerHTML = "<span>Identifiant ou mot de passe incorrect</span>"
            document.querySelector('#erreurConnexion').style.display = "block"
            return false
        } else if(typeof(retourBackend === "object")) {
            this.props.methUtilisateurConnecte(retourBackend)
            localStorage.setItem("connectedUser", JSON.stringify(retourBackend))

            if(!this.props.bPopup){
                if(localStorage.getItem("lastUrlVisited") !== null){
                    // this.props.history.push(localStorage.getItem("lastUrlVisited")) // AN modif url en cours sans recharger la page
                    window.location = localStorage.getItem("lastUrlVisited")  // AN recharge la page
                } else {
                    // this.props.history.push("/") 
                    window.location = "/"
                }
            } else {
                document.querySelector("#idPopup").style.display = "none"
            }
        }
    }

    formSubmit = () => {
        if(this.state.bRecupMdp){
            this.demandeRecuperationMDP()
            return false
        }
        document.querySelector('#erreurConnexion').style.display = "none" // RAZ masque champ alerte erreur connexion

        let enteredID  = document.querySelector('#inputIdentifiant').value
        let enteredPASSWORD = document.querySelector('#inputMDP').value

        if (enteredID.trim() === "" || enteredPASSWORD.trim() === "") {
            document.querySelector('#erreurConnexion').innerHTML = "Saisie incorrecte"
            document.querySelector('#erreurConnexion').style.display = "block"
            return false
        }

        API.get('/utilisateur/' + enteredID, {params: {identifiant: enteredID, mdp: enteredPASSWORD}, signal: this.controller.signal})
       .then(response => this.essaiConnexion(response.data))
       .catch(() => alert("Erreur serveur login"))
    }

    formLogin = (<>
        <div className="form-group">
            <label className="h4" htmlFor="inputIdentifiant">Identifiant</label>
            <input required type="text" className="form-control" id="inputIdentifiant" placeholder="Entrez votre identifiant..." />
        </div>
        <div className="form-group">
            <label className="h4" htmlFor="inputMDP">Mot de passe</label>
            <input required type="password" className="form-control" id="inputMDP" placeholder="Entrez votre mot de passe..." />
        </div>
        <input type="submit" className='btn btn-secondary' value="Connexion" />
        <input className="ml-3 btn btn-secondary" type="button" value="Mot de passe oublié ?" onClick={() => {
            this.setState({ bRecupMdp: true })
        }} />
        <div style={{ display: "none", border: 'solid', maxWidth: "400px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurConnexion"></div>
        <br/>
        <br/>
        <span>Vous n'avez pas de compte ?<a target="_blank" rel="noopener noreferrer" className="linkPasBlanc font-weight-bold" href="/inscriptionUtilisateur"> Inscrivez-vous ici !</a></span>
    </>)

    formRecupMdp = (
        <div className="mt-3" id="divMdpOublie">
            <label htmlFor="inputIdentifiant">Adresse mail du compte :</label>
            <input type="email" className="form-control" id="inputRecupMdp" placeholder="Entrez votre adresse mail..." />
            <input className="mt-2" type="button" value="Recevoir un mail de récupération" onClick={(e) => this.demandeRecuperationMDP(e)} />
            <input className="m-2" type="button" value="Retour" onClick={(e) => this.setState({bRecupMdp: false})} />
            <div style={{ display: "none", border: 'solid', maxWidth: "400px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurRecuperation"></div>
            <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationRecuperation"></div>
        </div>
    )

    componentWillUnmount = () => {
        this.controller.abort()
    }

    componentDidMount = () => {
        document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='Agrivore' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Connexion à Agrivore" />\n
                <meta name="twitter:description"    content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="twitter:image"      content="./images/agrivore_icon.png">\n

                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Agrivore" />\n
                <meta property="og:description"     content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="og:image"           content="./images/agrivore_icon.png">\n
                `)
    }

    render() {
        return (
            <>
                {!this.props.dUserInformations.bIsConnected ?
                    <div className="container">
                        <div className={this.props.bPopup ? "p-3" : "card p-3"}>
                            <h1>Connexion</h1>
                            <form onSubmit={(e) => { e.preventDefault(); this.formSubmit() }} autoComplete="off"> {/* desactive refresh by default du formulaire */}
                                {!this.state.bRecupMdp && this.formLogin}
                                {this.state.bRecupMdp && this.formRecupMdp}
                            </form>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className=" p-3 mt-5 bg-warning text-center">
                            <h3>Vous êtes déjà connecté</h3>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default Login