import React from 'react'
import { withRouter } from 'react-router-dom'
import Axios from 'axios';
import API from './api'
import { Link } from "react-router-dom"

class FormulaireContact extends React.Component {

    constructor(props) {
        super(props)
        localStorage.setItem("lastUrlVisited", window.location.pathname) // stocke url en cours en tant que derniere url consultee pour retour apres connexion si bes
        this.state = {
            largeurEcran: window.screen.availWidth, //LB : recupere la taille de l'ecran
        }
    }

    submitForm = e => { //methode appelee apres avoir submit le formulaire de contact
        e.preventDefault() //On annule le refresh automatique de la page (comportement par defaut du formulaire)

        //On recupere toutes les saisies de l'utilisateur
        let enteredNom = document.getElementById("nom").value.toUpperCase() 
        let enteredPrenom = document.getElementById("prenom").value
        let enteredMail = document.getElementById("mail").value
        let enteredObjet = document.getElementById("objet").value
        let enteredMessage = document.getElementById("message").value
        let enteredCodePostal = document.getElementById("codePostal").value.length > 0 ? document.getElementById("codePostal").value : "non renseigné"
        let enteredTelephone = document.getElementById("telephone").value.length > 0 ? document.getElementById("telephone").value : "non renseigné"
        let enteredTypeUtilisateur = document.forms.formContact.querySelector("input[name=radioTypeUtilisateur]:checked").value

        //On verifie qu'il y a bien un objet
        if (enteredObjet === "") {
            document.getElementById('erreurContact').innerHTML = "Veuillez sélectionner un objet"
            document.getElementById('erreurContact').style.display = 'block'
            document.getElementById('confirmationContact').style.display = 'none'
        } else if (enteredObjet === "Autre") {
            enteredObjet = document.getElementById('objetAutre').value
        }

        let paramsRequest = { //creation du dictionnaire pour la requete AJAX 
            nom: enteredNom,
            prenom: enteredPrenom,
            mail: enteredMail,
            objet: enteredObjet,
            message: enteredMessage,
            codePostal: enteredCodePostal,
            telephone: enteredTelephone,
            idUser: this.props.dUserInformations.idUtilisateur,
            typeUtilisateur: enteredTypeUtilisateur
        }

        API.post("/contact/message", { paramsRequest })
            .then(() => {
                document.getElementById('confirmationContact').style.display = 'block'
                document.getElementById('erreurContact').style.display = 'none'
                document.getElementById("nom").value = "" //PB_2022-06-22 on remets à 0 les champs pour eviter envoie en boucle 
                document.getElementById("prenom").value = ""
                document.getElementById("mail").value = ""
                document.getElementById("objet").value = ""
                document.getElementById("message").value = ""
                document.getElementById("codePostal").value = ""
                document.getElementById("telephone").value = ""
                document.getElementById('objetAutre').value = ""
                document.getElementById('divObjetAutre').style.display = 'none'

            })
            .catch(() => {
                document.getElementById('erreurContact').innerHTML = "Erreur lors de l'envoi du message"
                document.getElementById('erreurContact').style.display = 'block'
                document.getElementById('confirmationContact').style.display = 'none'
            })
    }

    componentDidMount = () => {
        window.onresize = () => { this.setState({ largeurEcran: window.screen.availWidth }) };

        document.getElementById('objet').addEventListener('change', (e) => {
            if (e.target.value === "Autre") {
                document.getElementById('divObjetAutre').style.display = 'block'
                document.getElementById('objetAutre').required = true
            } else {
                document.getElementById('divObjetAutre').style.display = 'none'
                document.getElementById('objetAutre').required = false
            }
        })
    }

    lTypesUtilisateurs = ["Producteur", "Consommateur", "Autre"].map(typeUtilisateur =>
        <div key={typeUtilisateur} className="form-check form-check-inline mt-0">
            <input required className="form-check-input" type="radio" name="radioTypeUtilisateur" id={typeUtilisateur} value={typeUtilisateur} />
            <label className="form-check-label pt-1" htmlFor={typeUtilisateur}>{typeUtilisateur}</label>
        </div>
    )

    render() {
        let fontSizeText = window.screen.availWidth > 600 ? "" : "12px"
        return (
            <div className="container">
                <div className="card p-3">
                    <h3 className="text-center">Une question, une remarque ?</h3>
                    <h4 className="text-center font-weight-bold">Contactez-nous !</h4>
                    <hr />
                    <div className="row">
                        <form className="col-md-6 col-12 row mb-3 pl-5" onSubmit={e => this.submitForm(e)} name="formContact">
                            <div className="form-group col-12 text-center">
                                <span>Vous êtes...</span><br />
                                {this.lTypesUtilisateurs}
                            </div>
                            <div className="form-group col-6">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} required className="form-control" placeholder="Nom (requis)" type="text" id="nom" />
                            </div>
                            <div className="form-group col-6">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} required className="form-control" placeholder="Prénom (requis)" type="text" id="prenom" />
                            </div>
                            <div className="form-group col-12">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} required className="form-control" placeholder="Adresse mail (requis)" type="email" id="mail" />
                            </div>
                            <div className="form-group col-6">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} className="form-control" placeholder="Téléphone" type="text" id="telephone" />
                            </div>
                            <div className="form-group col-6">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} className="form-control" placeholder="Code Postal" type="text" id="codePostal" />
                            </div>
                            <div className="form-group col-12">
                                <select style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} className="form-control" id="objet" defaultValue="">
                                    <option disabled value="">Objet</option>
                                    <option value="Signaler une erreur">Signaler une erreur</option>
                                    <option value="Faire une suggestion">Faire une suggestion</option>
                                    <option value="Demande de référencement sur Agrivore">Demande de référencement sur Agrivore</option>
                                    <option value="Autre">Autre</option>
                                </select>
                            </div>
                            <div className="form-group col-12" style={{ display: "none" }} id="divObjetAutre">
                                <input style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} className="form-control" placeholder="Précisez..." type="text" id="objetAutre" />
                            </div>
                            <div className="form-group col-12">
                                <textarea style={{ fontSize: fontSizeText, backgroundColor: "#f7f7f7" }} placeholder="Votre message..." className="form-control" id="message" rows="9"></textarea>
                            </div>
                            <input type="submit" value="Envoyer" className="centerBlock btn btn-secondary" />

                            <div style={{ display: "none", border: 'solid' }} className="rounded mt-3 alert alert-success text-center col-12" id="confirmationContact">
                                Message envoyé avec succès. L'équipe Agrivore vous remercie
                            </div>
                            <div style={{ display: "none", border: 'solid' }} className="rounded mt-3 alert alert-danger text-center col-12" id="erreurContact"></div>

                        </form>
                        <hr />
                        <div className="col-md-6 col-12 row mt-3 centerBlock">
                            <iframe title="map" className="col-md-12 col-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2840.3887238365123!2d2.029369215772442!3d44.60952657910011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ad16d48f350b99%3A0x547876cd4c34ee1a!2s31%20Boulevard%20Georges%20Juskiewenski%2C%2046100%20Figeac!5e0!3m2!1sfr!2sfr!4v1621497029247!5m2!1sfr!2sfr"
                                width="400"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="fast">
                            </iframe>
                            <div className="col-12 mt-md-0 mt-3">
                                <h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-geo-alt" style={{ color: "black" }} viewBox="0 0 16 16">
                                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg> : 31 Boulevard Georges Juskiewenski</h6>
                                <h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-at" style={{ color: "black" }} viewBox="0 0 16 16">
                                        <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z" />
                                    </svg> : agrivore @ gmail.com</h6>
                                <h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-telephone pl-1" style={{ color: "black" }} viewBox="0 0 16 16">
                                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                    </svg> : 05.65.48.55.00
                                </h6>
                                <h6>
                                <a href="https://www.facebook.com/agrivore" style={{ color: "#212529" }} className="linkPasBlanc" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg> :  
                                        Notre page Facebook</a>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="p-2">
                        <Link to="/"><input type="button" className="btn btn-secondary" value="Retour" /></Link>
                    </div>

                </div>
            </div >
        )
    }
}

export default withRouter(FormulaireContact)