import React from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios'
import API from './api'

class ActivationCompte extends React.Component {

    verifValidation = (retourBackend) => {
        if (typeof (retourBackend) === "boolean") {
            document.querySelector('#validationCompte').style.display="block"
            document.querySelector('#erreurValidation').style.display="none"
            setTimeout(() => this.props.history.push("/login.html"), 3000)
        } else {
            document.querySelector('#erreurValidation').innerHTML = retourBackend
            document.querySelector('#validationCompte').style.display="none"
            document.querySelector('#erreurValidation').style.display="block"
        }
    }

    componentDidMount = () => {
        let idUtilisateur = this.props.match.params.idUtilisateur 
        API.put('/utilisateur/' + idUtilisateur, {})
        .then(response => this.verifValidation(response.data))
        .catch(error => {
           alert("L'activation du compte a échoué")
        })
    }

    render() {
        return (
            <>
                <div className="container ">
                    <div className="card p-3">
                        <div style={{ display: "none", border: 'solid' }} className="rounded mt-3 alert alert-success text-center" id="validationCompte">
                            Compte validé avec succès. Vous allez être redirigé vers la <Link to="/login.html"><button>page de connexion</button></Link>
                        </div>
                        <div style={{ display: "none", border: 'solid' }} className="rounded mt-3 alert alert-danger text-center" id="erreurValidation"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(ActivationCompte)