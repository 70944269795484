import React from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import API from './api';
import Produit from './Produit';

class VisualisationCommande extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lCommandes: []
        }
        localStorage.setItem("lastUrlVisited", window.location.pathname)
    }

    informationsCommande = (typeCommandeProducteurDrive) => {
        let commandePasseeA;

        if(typeCommandeProducteurDrive === "producteur"){
            commandePasseeA = this.state.lCommandes[0].producteur.label1
        } else {
            commandePasseeA = this.state.lCommandes[0].pointretrait.pr_libelle
        }

        let dateCommandeSplit = this.state.lCommandes[0].commande.v_datecommande.split("-") //On travaille avec la 1ere commande du lot --> la date de commande d'un lot est la meme pour tous les articles
        return <div>
            <h3 className="font-weight-bold">
                Commande n° {this.state.lCommandes[0].commande.v_boncommande}
            </h3>
            <div className="row px-3 mt-4">
                <div className="col-md-3 col-12">
                    <h6 className="font-weight-bold mb-0">
                        Date de commande
                    </h6>
                    <span>
                        {dateCommandeSplit[2].split(" ")[0] + "/" + dateCommandeSplit[1] + "/" + dateCommandeSplit[0]}
                    </span>
                </div>
                <div className="ml-md-3 mt-md-0 mt-2 col-md-3 col-12">
                    <h6 className="font-weight-bold mb-0">
                        Paiement
                    </h6>
                    <span>
                        Sur place
                    </span>
                </div>
                <div className="ml-md-3 mt-md-0 mt-2 col-md-3 col-12">
                    <h6 className="font-weight-bold mb-0">
                        Commande passée à...
                    </h6>
                    <span>
                        {commandePasseeA}
                    </span>
                </div>
            </div>
        </div>
    }

    informationsArticles = (typeCommandeProducteurDrive) => {
        let lArticles = this.state.lCommandes.map(lot => //on cree une div pour toutes les commandes du lot
            <div key={lot.article.idarticle + lot.producteur.idproducteur} className="my-3">
                <div className="d-flex justify-content-between px-3 row m-2">
                    <div className="row" style={{ width: "300px" }}>
                        <Link target="_blank" className="linkPasBlanc" to={"/producteur/" + lot.producteur.p_url_servie}>
                            <img src={process.env.PUBLIC_URL + "/" + lot.article.a_img2} alt=" "></img>
                        </Link>
                        <div className="pl-2">
                            {lot.article.a_libelle + "  "}
                            {lot.article.a_bio === "1" && // ajt logo bio si article bio
                                <img alt="Bio" height="20px" src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}
                                />// : "centerBlock mb-2"
                            }
                            <br/>
                            {typeCommandeProducteurDrive === "drive" && lot.producteur.label1}
                        </div>
                    </div>

                    <div className="m-2">
                        Quantité : {parseInt(lot.commande.v_quantite)}
                    </div>
                    <div className="m-2" style={{ width: "100px" }}>
                        {lot.commande.v_totalttc !== "None" && "Prix : " + (parseFloat(lot.commande.v_totalttc.slice(0, lot.commande.v_totalttc.length - 2)).toFixed(2) + "€").replace(".", ",") /*toFixed(2) --> pour toujours avoir 2 chiffres apres la virgule */}
                    </div>
                </div>
            </div>)

        return <div className="mt-3"><span style={{ fontWeight: "bold" }}>Articles</span> : {lArticles}</div>
    }

    informationsPrixRetrait = (typeCommandeProducteurDrive) => {
        let dateRetraitSplit = this.state.lCommandes[0].commande.v_dateretrait.split("-") //On travaille avec la 1ere commande du lot --> le point/date de retrait de de commande d'un lot est la meme pour tous les articles
        let lieuRetrait = this.state.lCommandes[0].pointretrait.pr_libelle
        let prixTotal = 0

        this.state.lCommandes.forEach(commande => prixTotal += parseFloat(commande.commande.v_totalttc))

        // if (this.state.lCommandes.length > 1) { //Si il y a + d'une commande dans le lot
        //     prixTotal = this.state.lCommandes.reduce((a, b) => parseFloat(a.commande.v_totalttc) + parseFloat(b.commande.v_totalttc)) //on utilise reduce() pour parcourir les commandes, et additioner leur prix pour obtenir le prix total du lot
        // } else if (this.state.lCommandes.length === 1) { //sinon, s'il n'y a qu'une commande dans le lot
        //     prixTotal = this.state.lCommandes[0].commande.v_totalttc //on recupere son prix
        // }

        let labelProducteur = this.state.lCommandes[0].producteur.label1

        return (
            <div className="mt-3">
                <h6 className="font-weight-bold">
                    Prix total : {(parseFloat(prixTotal).toFixed(2) + " €  TTC").replace(".", ",")}
                </h6>
                <h6 className="font-weight-bold mt-4 mb-0">
                    Retrait de la commande
                </h6>
                <span>
                    le : {dateRetraitSplit[2].split(" ")[0] + "/" + dateRetraitSplit[1] + "/" + dateRetraitSplit[0] + " - " + dateRetraitSplit[2].split(" ")[1].slice(0, 5)}<br />
                    lieu : {typeCommandeProducteurDrive === "producteur" && labelProducteur}{typeCommandeProducteurDrive === "producteur" && " - "}{lieuRetrait}<br/>
                    {this.props.match.params.typeUtilisateur === "producteur" &&
                        "Consommateur : " + this.state.lCommandes[0].consommateur.c_nom
                    }
                </span>
            </div>
        )
    }

    componentDidMount = () => {
        //requete Axios pour récupérer lCommandes
        let idTiersUtilisateur = this.props.dUserInformations.idTiersUtilisateur

        API.get("commande/producteur/all/utilisateur/all?typeCommande=fromBonCommande&vBonCommande=" + this.props.match.params.vBonCommande).then(response =>
            this.setState({
                lCommandes: response.data.commandes.filter(commande =>  //filtre les commandes pour ne conserver que celles qui ont le bon v_boncommande
                    commande.commande.v_boncommande === this.props.match.params.vBonCommande).sort((a, b) =>
                        a.producteur.idproducteur > b.producteur.idproducteur ? 1 : -1
                    )
            }))
    }

    render() {
        let typeCommandeProducteurDrive;
        if(this.state.lCommandes.length > 0){
            typeCommandeProducteurDrive = this.state.lCommandes[0].commande.v_groupe_idproducteur === null ? "producteur" : "drive"
        }

        return (
            <div className="container">
                {this.props.dUserInformations.bIsConnected ?
                    <div className="card p-3">
                        {this.state.lCommandes.length > 0 &&
                            <div>
                                {this.informationsCommande(typeCommandeProducteurDrive)}
                                <hr />
                                {this.informationsArticles(typeCommandeProducteurDrive)}
                                <hr />
                                {this.informationsPrixRetrait(typeCommandeProducteurDrive)}
                            </div>
                        }
                        <div className="mt-5">
                            <Link to="/gestionCommandes"><input type="button" className='btn btn-outline-dark' value="Retour" /></Link>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="card p-3 mt-5 text-center">
                            <h3>Connectez-vous pour accéder à cette page</h3>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(VisualisationCommande)