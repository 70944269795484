import Axios from 'axios';
import React from 'react'
import ReactDOM from 'react-dom'
import API from './api'
import ListeProducteurs from './ListeProducteurs';

class RechercheArticle extends React.Component {

    constructor(props) {
        super(props)
        this.controller = new AbortController()
    }

    calcDistanceCoordonnes(lat1, lon1, lat2, lon2) { //Calcul de la distance en km entre 2 coordonnees
        //Code issu de https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
        var RayonTerre = 6371; // rayon de la Terre en km
        var differenceLatitude = this.convertToRad(lat2 - lat1);  //difference entre les 2 latitudes, en radian 
        var differenceLongitude = this.convertToRad(lon2 - lon1); //difference entre les 2 longitudes, en radian
        var latitude1 = this.convertToRad(lat1);  //latitude du point 1, en radian
        var latitude2 = this.convertToRad(lat2);  //latitude du point 2, en radian

        var calculIntermediaire1 = Math.sin(differenceLatitude / 2) * Math.sin(differenceLatitude / 2) +
            Math.sin(differenceLongitude / 2) * Math.sin(differenceLongitude / 2) * Math.cos(latitude1) * Math.cos(latitude2);
        var calculIntermediaire2 = 2 * Math.atan2(Math.sqrt(calculIntermediaire1), Math.sqrt(1 - calculIntermediaire1));
        var distance = RayonTerre * calculIntermediaire2;
        return distance;
    }

    // Conversion degres vers radians
    convertToRad(Value) {
        return Value * Math.PI / 180;
    }

    rechercheArticle = () => {
        //recupere les saisies du formulaire
        let saisieRechArticle = document.getElementById('articleRecherche').value.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "") //recupere la recherche d'article en supprimant les accents (a l'aide de normalize() et replace())
        let saisieLieu = document.getElementById('lieuRecherche').value.trim().toUpperCase()
        let saisieProducteur = document.getElementById('producteurRecherche').value.trim().toUpperCase()
        let saisiePtRetrait = document.getElementById('PtRetraitRecherche').value.trim().toUpperCase()

        if (saisieRechArticle.length > 2 || saisieLieu.length > 2 || saisieProducteur.length > 2 || saisiePtRetrait.length > 2) { //si au moins un des champs de recherche possède 3 caractères
            let lProducteursOk = this.props.lProducteurs
            
            if(saisieRechArticle.length > 2)
                lProducteursOk = lProducteursOk.filter(producteur => //filtre les articles, en verifiant qu'ils correspondent a la recherche (sans prendre en compte la casse et les accents)
                    producteur.articles.filter(article => (" " + article.a_libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(" " + saisieRechArticle.toLowerCase().trim()) ||
                        (" " + article.a_libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace("'", " ")).toLowerCase().includes(" " + saisieRechArticle.replace("'", " ").toLowerCase().trim())).length > 0
                )
            if (saisiePtRetrait.length > 2) { // filtre par p_retraits
                lProducteursOk = lProducteursOk.filter(producteur =>
                    producteur.p_retraits.filter(retrait => (" " + retrait.pr_libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "")).toLowerCase().includes(" " + saisiePtRetrait.toLowerCase().trim()) ||
                        (" " + retrait.pr_libelle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace("'", " ")).toLowerCase().includes(" " + saisiePtRetrait.replace("'", " ").toLowerCase().trim())).length > 0)

            }
            if(saisieProducteur.length > 2){
                let lProducteurs = []
                for(let producteur of lProducteursOk){
                    let lLabels = ["label1", "label2", "label3"]
                    for (let label of lLabels) { //on regarde dans les 3 labels si il y a une correspondance avec la recherche
                        if ((typeof (producteur[label]) === "string" && producteur[label].toUpperCase().includes(saisieProducteur)) || //si le label est sous la forme d'un string
                            (typeof (producteur[label]) !== "string" && producteur[label].join('').toUpperCase().includes(saisieProducteur))){ //si il est sous la forme d'un tableau
                            lProducteurs.push(producteur)
                            break;
                        }
                    }
                    lProducteursOk = lProducteurs
                }
            }
            if (saisieLieu.length > 2) {
                API.get('/ville/' + saisieLieu, { signal: this.controller.signal })
                    .then((response) => {
                        let nbKmAutourVille = parseInt(document.getElementById('rangeDistanceMaxRecherche').value)
                        let ville = response.data.ville
                        if (ville !== null) {
                            lProducteursOk = lProducteursOk.filter(producteur =>
                                // Math.abs(parseFloat(producteur.p_gps_latitude) - parseFloat(ville.v_gps_latitude)) < (nbKmAutourVille / 111) &&
                                // Math.abs(parseFloat(producteur.p_gps_longitude) - parseFloat(ville.v_gps_longitude)) < (nbKmAutourVille / 76)
                                this.calcDistanceCoordonnes(parseFloat(producteur.p_gps_latitude), parseFloat(producteur.p_gps_longitude),
                                    parseFloat(ville.v_gps_latitude), parseFloat(ville.v_gps_longitude)) < nbKmAutourVille
                            )
                        } else {
                            lProducteursOk = []
                        }
                        this.afficheResultat(lProducteursOk)
                        this.props.methodeGetVilleRecherche(ville)
                    }
                    )
            } else {
                this.afficheResultat(lProducteursOk)
            }
        } else {
            ReactDOM.render(<h6 className="text-center">Veuillez saisir au minimum 3 caractères</h6>, document.getElementById("resultatRecherche"))
        }
    }

    afficheResultat = (lProducteursOk) => {
        let resultatRecherche = lProducteursOk.length > 0 ? <ListeProducteurs methUtilisateurConnecte={this.props.methUtilisateurConnecte} lProduitsDetail={this.props.lProduitsDetail} lProducteurs={lProducteursOk} dUserInformations={this.props.dUserInformations}/> : <h6 className="text-center">Aucun producteur ne correspond à ces critères</h6>
        let divResultatRecheche = document.getElementById("resultatRecherche")
        divResultatRecheche.style.display="block"
        ReactDOM.render(resultatRecherche, divResultatRecheche)

        if (document.getElementById("resultatRechercheProducteursProximites") !== null) {
            document.getElementById("resultatRechercheProducteursProximites").style.display = "none"
        }

        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    dataListArticles = (
        <datalist id="dataListArticles">
            {this.props.lArticles.map(article => <option key={article.idarticle} value={article.a_libelle}></option>)}
        </datalist>
    )

    dataListVilles = () => {
        return (
            <datalist id="dataListVilles">
                {Array.from(new Set(this.props.lVilles.map(ville => ville.v_nom_commune))).map(nomVille => <option key={nomVille} value={nomVille}></option>)} {/*Liste unique des noms de ville, sous forme d'options de liste deroulante*/}
            </datalist>
        )
    }

    dataListProducteurs = () => {
        return (
            <datalist id="dataListProducteurs">
                {this.props.lProducteurs.map(producteur =>
                    <option key={producteur.idproducteur} value={typeof(producteur.label1) === "string" ? producteur.label1 : producteur.label1.join("")}></option>)}
            </datalist>
        )
    }

    dataListPtRetrait = () => {
        return (
            <datalist id="dataListPtRetrait">
                {this.props.lPtRetraits.map(PtRetrait =>
                    <option key={PtRetrait.idpointretrait} value={typeof (PtRetrait.pr_libelle) === "string" ? PtRetrait.pr_libelle : PtRetrait.pr_libelle.join("")}></option>)}
            </datalist>
        )
    } // .toUpperCase().replace('RETRAIT', '') => dans le cas ou on veut enlever retrait devant
    componentWillUnmount = () => {
        this.controller.abort()
    }

    componentDidMount = () => {
        ["producteurRecherche", "lieuRecherche", "articleRecherche"].forEach(inputRecherche => {
            document.getElementById(inputRecherche)
                .addEventListener("input", event => {
                    if (event.inputType === "insertReplacementText" || event.inputType === null) {
                        this.rechercheArticle()
                    }
                })
        })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.dUserInformations === prevProps.dUserInformations && this.props.methodeGetVilleRecherche === prevProps.methodeGetVilleRecherche) {
            this.rechercheArticle()
        }
    }

    render() {
        return (
            <div className=" mt-3" style={{ width: "100%", margin: "auto" }}>
                <div className="card p-md-3 p-1" >
                    <h4 className="text-center">Chercher un article</h4>
                    <form autoComplete='off' className="text-center" onSubmit={e => { e.preventDefault(); this.rechercheArticle(); }}>
                        <div className="col-sm row">
                            <div className="col-md-3 ">
                                <label htmlFor="articleRecherche" className="col-12">Article</label>
                                <div className="col-12">
                                    <input list="dataListArticles" type="text" minLength="3" id="articleRecherche" placeholder="Article..." />
                                    {this.dataListArticles}

                                </div>
                            </div>
                            <div className="col-sm ">
                                <label htmlFor="lieuRecherche" className="col-12">Ville</label>
                                <div className="col-12">
                                    <input list="dataListVilles" type="text" minLength="3" id="lieuRecherche" placeholder="Ville..." />
                                    {this.dataListVilles()}
                                </div>
                            </div>
                            <div className="col-sm ">
                                <label htmlFor="lieuRecherche" className="col-12">Dans un rayon de ... </label>
                                <div className="col-12">
                                    <input onChange={(e) => document.getElementById("valeurDistanceMaxDistance").innerHTML = e.target.value + " km"}
                                        type="range" min="1" max="50" defaultValue='25' id="rangeDistanceMaxRecherche" />
                                </div>
                                <div className="col-12">
                                    <span id="valeurDistanceMaxDistance">25 km</span>
                                </div>
                            </div>
                            <div className="col-sm ">
                                <label htmlFor="lieuRecherche" className="col-12">Produit par ... </label>
                                <div className="col-12">
                                    <input list="dataListProducteurs" type="text" minLength="3" id="producteurRecherche" placeholder="Producteur..." className="mr-sm-2 mt-md-0 mt-3 mb-3" />
                                    {this.dataListProducteurs()}
                                </div>
                            </div>
                            <div className="col-sm ">
                                <label htmlFor="ptRetrait" >Point de retrait</label>
                                <div >
                                    <input list="dataListPtRetrait" type="text" minLength="3" id="PtRetraitRecherche" placeholder="Point de Retrait ..." className="mt-md-0 mt-3 mb-3" />
                                    {this.dataListPtRetrait()}
                                </div>
                            </div>

                            <div className="col-12 row justify-content-around">
                                <div className="col-12 col-md-5 text-md-right">
                                    <button type="submit" className="btn btn-light border border-dark">
                                        Rechercher
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-search ml-2" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="col-12 col-md-5 text-md-left mt-md-0 mt-3">
                                    <button type="reset" onClick={() => document.getElementById("resultatRecherche").style.display="none"} className="btn btn-light border border-dark">
                                        Réinitialiser
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-arrow-counterclockwise ml-2" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z" />
                                        </svg>
                                    </button>
                                </div>
                                {/* <div className="col-12 col-md-5 text-md-left"><input type="reset" value="Réinitialiser" className=" mt-md-0 mt-3" /></div> */}
                            </div>
                        </div>
                    </form>
                    <div id="resultatRecherche" className="mt-3" style={{overflowY: "scroll", overflowX: "hidden" }}>
                    </div>
                </div>
            </div>
        )
    }
}

export default RechercheArticle