import { withRouter } from "react-router"
import React, { useState } from 'react'
import Axios from "axios"
import API from './api'
import { Link } from "react-router-dom"
import { point } from "leaflet"
import ReactDOM from 'react-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip';
import InscriptionClient from './InscriptionClient';

import Popup from './Popup';
import Login from './Login';

class CmdeProducteur extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dProducteur: {},
            dPointRetrait: {},
            aDispoDateDeb: null,
            aDispoDateFin: null,
            isLogged: (typeof props.idUtilisateur !== 'undefined')
        }
        this.lMois = {
            "01": "janvier", "02": "février", "03": "mars", "04": "avril", "05": "mai", "06": "juin",
            "07": "juillet", "08": "août", "09": "septembre", "10": "octobre", "11": "novembre", "12": "décembre"
        }
        this.lJoursSemaine = {
            "01": "lundi", "02": "mardi", "03": "mercredi", "04": "jeudi", "05": "vendredi", "06": "samedi", "07": "dimanche"
        }

        //LB : ajout une methode a l'objet Array, pour que Array.range soit equivalent au range() de python : Array.range(1, 5) => [1, 2, 3, 4]
        Array.range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);

    }

    traiteDemande = (e) => {
        e.preventDefault()
        let articlesSelected = this.state.dProducteur.articles.filter(article => document.getElementById("article" + article.idarticle).checked) //recupere tous les articles selectionnes
            .map(article => //et genere un dictionnaire pour chacun d'eux
                Object.assign(
                    {},
                    {
                        idArticle: article.idarticle,
                        libelleArticle: article.a_libelle
                    },
                    {
                        quantite: document.getElementById("quantiteArticle" + article.idarticle).value,
                        prixLot: article.a_prixvente1 !== "None" ? document.getElementById("prixTotal" + article.idarticle).innerHTML.replace(' €', "").replace(",", ".") : "0",
                        prixUnitaire: article.a_prixvente1 !== "None" ? article.a_prixvente1 : "0"
                    }
                )
            )

        if (articlesSelected.length === 0) { //verifie que l'utilisateur a selectionne au moins 1 article
            document.getElementById("erreurCommande").innerHTML = "Veuillez sélectionner au moins un article."
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"
            return false
        }

        let pointRetraitSelected = document.getElementById("pointRetrait").value

        let valueDateRetrait = document.getElementById("dateRetrait").value
        let valueHeureRetrait = document.getElementById("heureRetrait").value

        if (valueDateRetrait.trim() === "" || valueHeureRetrait.trim() === "") { //verifie que l'utilisateur a selectionne une date et une heure
            document.getElementById("erreurCommande").innerHTML = "Veuillez sélectionner une date et une heure."
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"
            return false
        }

        let bFormulaire = document.getElementById("divIdFormCoordonnees").style.display == "block"
        let nomEntered = ""
        let prenomEntered = ""
        let codePostalEntered = ""
        let villeEntered = ""
        let adresse1Entered = ""
        let adresse2Entered = ""
        let commentaireEntered = ""
        let mailEntered = ""
        let telEntered = ""



        //nomEntered prenomEntered adresseEntered codePostalEntered commentaireEntered
        if (!this.props.dUserInformations.bIsConnected) {
            //recupere les saisies de l'utilisateur
            nomEntered = this.state.nomEntered;
            prenomEntered = this.state.prenomEntered;
            codePostalEntered = this.state.codePostalEntered;
            villeEntered = this.state.villeEntered;
            adresse1Entered = this.state.adresseEntered
            commentaireEntered = this.state.commentaireEntered
            mailEntered = this.state.mailEntered
            telEntered = this.state.telEntered
            // => DEBUG //alert(nomEntered + "\n" + prenomEntered + "\n" + adresse1Entered + "\n" + villeEntered + ", " + codePostalEntered + "\n" + mailEntered + "\n" + telEntered + "\n" + commentaireEntered)
            
            if ([nomEntered, prenomEntered, codePostalEntered, villeEntered, adresse1Entered, telEntered].includes("")) { //verifie que les champs obligatoires ont bien ete remplis
                document.getElementById("erreurCommande").innerHTML = "Veuillez renseigner toutes vos coordonnées."
                document.getElementById("erreurCommande").style.display = "block"
                document.getElementById("validationCommande").style.display = "none"
                return false
            }
        }


        let paramsRequest = { //dictionnaire qui fera office de parametres pour la requete AJAX
            lArticlesSelected: articlesSelected,
            libellePointRetraitSelected: pointRetraitSelected.split("__")[0],
            idPointRetraitSelected: pointRetraitSelected.split("__")[1],
            dateRetrait: valueDateRetrait,
            heureRetrait: valueHeureRetrait,
            labelProducteur: typeof (this.state.dProducteur.label1) === "string" ? this.state.dProducteur.label1 : this.state.dProducteur.label1.join(""),
            nomClient: nomEntered,
            prenomClient: prenomEntered,
            codePostalClient: codePostalEntered,
            villeClient: villeEntered,
            adresse1Client: adresse1Entered,
            adresse2Client: adresse2Entered,
            commentaireClient: commentaireEntered, // PB_2022-06-22 on ajoute les elements necessaire opur envoie mail
            mailClient: mailEntered,
            telClient: telEntered,
            isLogged: this.props.dUserInformations.bIsConnected,
            idClient: this.props.dUserInformations.idUtilisateur,
        }
        //alert(nomEntered + "\n" + prenomEntered + "\n" + adresse1Entered + "\n" + villeEntered + ", " + codePostalEntered + "\n" + mailEntered + "\n" + commentaireEntered) // => DEBUG

        API.post("/commande/producteur/" + this.props.match.params.idProducteur + "/utilisateur/" + this.props.match.params.idUtilisateur, { paramsRequest }) //requete AJAX permettant d'envoyer la commande a l'API
            .then((result) => {
                if(result.data === true){
                    document.getElementById("validationCommande").style.display = "block"
                    document.getElementById("erreurCommande").style.display = "none"
                }
                else{
                    document.getElementById("validationCommande").style.display = "none"
                    document.getElementById("erreurCommande").style.display = "block"
                    document.getElementById("erreurCommande").innerHTML = result.data
                }
           
            }
            )
            .catch((err) => {
                console.log("ERREUR COMMANDE")
                console.log(err)
            })
    }

    getArticles = () => {
        let dateActuelle = new Date()
        let lArticles = this.state.dProducteur.articles.sort((a, b) => {  // a et b = 2 articles (b - a les compare pour trier dans ordre decroissant) et 2e critere de tri a - b ordre croissant (pour lister articles specifiq en haut) puis .map parcourt chacun des articles du producteur, en ajoutant une ligne de tableau pour chacun d'eux
            let diffIdProducteur = b.a_idproducteur - a.a_idproducteur
            return diffIdProducteur > 0 ? 1 : (diffIdProducteur < 0 ? - 1 : (a.a_idproduitdetail > b.a_idproduitdetail ? 1 : -1))
            // return diffIdProducteur > 0 ? 1 : (diffIdProducteur < 0 ? - 1 : (a.a_libelle.toLowerCase() > b.a_libelle.toLowerCase() ? 1 : -1)) => // PB_2022-06-22 ancien trie par libelle, désordre, plus logique par type de produit (voir ligne precedente)
        }).map(article => //On parcourt chacun des articles du producteur, en ajoutant une ligne de tableau pour chacun d'eux
            <tr key={article.idarticle}>
                <td scope="row" className="pl-4">
                    <input id={"article" + article.idarticle} className="form-check-input" type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            document.getElementById("quantiteArticle" + article.idarticle).value = "1"
                            if (article.a_prixvente1 !== "None") {
                                document.getElementById("prixTotal" + article.idarticle).innerHTML = (parseFloat(article.a_prixvente1).toFixed(2) + " €").replace(".", ",")
                                this.updatePrixTotal_EtDates()
                            }
                        } else {
                            document.getElementById("quantiteArticle" + article.idarticle).value = "0"
                            document.getElementById("prixTotal" + article.idarticle).innerHTML = ""
                        }
                        this.updatePrixTotal_EtDates()
                    }}
                        disabled={article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin))}
                        style={{ borderColor: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}
                    />
                    <label className="ml-2 mt-1 form-check-label" htmlFor={"article" + article.idarticle}>
                        <img className="mr-2" width='28px' src={process.env.PUBLIC_URL + "/" + article.a_img} alt="" />
                        {article.a_libelle + " "}
                        {article.a_bio === "1" && // PB_2022-06-22  Logo bio a coté des articles bio, plus sur les producteurs
                            <img alt="Bio" height="20px" src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}
                            />// : "centerBlock mb-2"
                        }
                    </label>
                </td>
                <td className="text-center">
                    <input type="number" min="0" defaultValue="0" style={{ width: "80%" }} id={"quantiteArticle" + article.idarticle} onChange={(e) => {
                        if (!["0", ""].includes(e.target.value) && parseInt(e.target.value) > 0) {
                            document.getElementById('article' + article.idarticle).checked = true
                            if (article.a_prixvente1 !== "None") {
                                document.getElementById("prixTotal" + article.idarticle).innerHTML = ((e.target.value * parseFloat(article.a_prixvente1)).toFixed(2) + " €").replace(".", ",")
                            }
                        } else {
                            document.getElementById('article' + article.idarticle).checked = false
                            document.getElementById("prixTotal" + article.idarticle).innerHTML = ""
                        }
                        this.updatePrixTotal_EtDates()
                    }
                    }
                        disabled={article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin))}
                    />
                </td>
                <td className="text-center">
                    {article.a_prixvente1 !== "None" && 
                        <span style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                            {article.a_prixvente1.slice(0, article.a_prixvente1.length - 2) + " €"}
                        </span>
                    }
                </td>
                <td className="text-center">
                    <span id={"prixTotal" + article.idarticle}></span>
                </td>
                <td className="text-center">
                    {article.a_dispodatedeb !== "None" &&
                        <span style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                            {article.a_dispodatedeb.split('-')[2] + "/" + article.a_dispodatedeb.split('-')[1] + "/" + article.a_dispodatedeb.split('-')[0]}
                        </span>
                    }
                </td>
                <td className="text-center">
                    {article.a_dispodatefin !== "None" &&
                        <span style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                            {article.a_dispodatefin.split('-')[2] + "/" + article.a_dispodatefin.split('-')[1] + "/" + article.a_dispodatefin.split('-')[0]}
                        </span>
                    }
                </td>
                <td className="text-left">
                    {article.a_prixvente1 !== "None" ?
                        ((article.a_dispodatedeb !== "None" && dateActuelle < new Date(article.a_dispodatedeb)) ?
                            <span>Réservez dès maintenant !</span>
                            :
                            ((article.a_dispodatefin !== "None" && dateActuelle > new Date(article.a_dispodatefin)) ?
                                <span style={{ color: ((article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                                    Epuisé
                            </span>
                                :
                                ""
                            )
                        )
                        :
                        <span style={{ color: "grey" }}>
                            Non disponible à la commande
                        </span>
                    }
                </td>
            </tr>
        )


        return (
            <div className="px-3">
                <h5 className="mb-4 text-center">Cochez les articles qui vous intéressent</h5>
                <table className="table table-sm table-hover table-responsive-md">
                    <thead className="text-center thead-light">
                        <tr>
                            <th scope="col">
                                Article
                            </th>
                            <th scope="col" style={{ minWidth: '100px' }}>
                                Quantité
                            </th>
                            <th scope="col" className="px-3">
                                Prix unitaire
                            </th>
                            <th scope="col" className="px-3">
                                Prix total
                            </th>
                            <th scope="col" className="px-3">
                                A partir du
                            </th>
                            <th scope="col" className="px-3">
                                Jusqu'au
                            </th>
                            <th scope="col" className="px-3">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {lArticles}
                    </tbody>
                </table>
            </div>
        )
    }

    getPointRetrait = () => {
        let lPointsRetrait = this.state.dProducteur.p_retraits.sort((a, b) => {
            return a.pr_libelle > b.pr_libelle ? 1 : -1
        }).map(retrait => //on parcourt les points retrait du producteur pour generer une liste deroulante
            <option key={retrait.idpointretrait} value={retrait.pr_libelle + "__" + retrait.idpointretrait}>{retrait.pr_libelle}</option>
        )

        return (
            <div className="mt-5 col-12 text-center">
                <h5>Sélectionnez votre point de retrait</h5>
                <select id="pointRetrait" className=" col-6"
                    onInput={(e) => {
                        let pointRetraitSelected = this.state.dProducteur.p_retraits.find(pointRetrait => pointRetrait.idpointretrait == e.target.value.split("__")[1])
                        this.setState({
                            dPointRetrait: pointRetraitSelected
                        })
                        if(pointRetraitSelected.pr_libelle.trim() === "A domicile"){
                        console.log(pointRetraitSelected.pr_libelle)

                            document.getElementById("divIdFormCoordonnees").style.display = "block"
                        } else {
                            document.getElementById("divIdFormCoordonnees").style.display = "none"
                        }
                    }}
                >
                    {lPointsRetrait}
                </select>
            </div>
        )
    }

    getDateRdv = () => {
        let bFiltreDate = false // true : on filtre les dates avec l'algo / false : on affiche un simple input de type date (sans contrainte)

        let heureMin = "09:00" //heure minimum de retrait de la commande
        let heureMax = "18:00" //heure max

        let stringJoursSemaineRetrait = "";
        ["pr_lundi", "pr_mardi", "pr_mercredi", "pr_jeudi", "pr_vendredi", "pr_samedi", "pr_dimanche"].forEach((pr_jour, index) => {
            if(this.state.dPointRetrait[pr_jour] === "1"){
                stringJoursSemaineRetrait += (index + 1).toString()
            }
        })

        if(stringJoursSemaineRetrait.length > 0){
            bFiltreDate = true
        }

        let dateActuelle = new Date()
        let dateMin = dateActuelle;
        let lOptionsDatesOk = []
        if (bFiltreDate) {
            let lNumeroJourSemaineRetrait = Array.from(stringJoursSemaineRetrait) //liste des numeros de jour de la semaine valide. Array.from("24") --> ["2", "4"]
            let lNumeroSemaineDuMois = Array.from(this.state.dPointRetrait.pr_liste_semaine_1234) //liste des numeros de semaines du mois valides. Array.from("13") --> ["1", "3"]
            let nbMoisPeriode = 5 //Nombre de mois sur lesquels on propose des dates
            let bDerniereSemaineDuMois = false //false : on travaille avec numeroSemaineDuMois / true : peu importe le numeroSemaineDuMois, on recupere la derniere semaine du mois (qui varie entre la 4 et 5)
            let numMois = ("0" + (dateActuelle.getMonth() + 1)).slice(-2) //recupere le numero du mois actuel
            let numAnnee = dateActuelle.getFullYear() //numero de l'annee actuelle
            let lDatesValides = []
            Array.range(parseInt(numMois), parseInt(numMois) + nbMoisPeriode + 1).forEach(() => { //On parcourt une range de numeros, en partant du numero de mois actuel (range de 6 a 16 par exemple)
                if (parseInt(numMois) > 12) { //si le numero du mois depasse 12
                    numMois = ("0" + (parseInt(numMois) - 12)).slice(-2)  //On enleve 12 mois, pour revenir a janvier
                    numAnnee = (parseInt(numAnnee) + 1).toString() //et on incremente l'annee de 1
                }
                let lDatesDuMois = []
                Array.range(1, 31).forEach(numJour => { //on parcourt une range de 1 a 31, qui correspond a tous les jours du mois
                    let date = new Date(numAnnee + "-" + numMois + "-" + ("0" + numJour).slice(-2)) //on genere une date avec l'annee/mois/jour de la boucle
                    if (lNumeroJourSemaineRetrait.includes(date.getDay().toString())) { //Si le numero de la semaine du jour correspond a celui souhaite (2 pour mardi, par exemple)
                        if (dateActuelle - date < 0 && //Si il ne s'agit pas d'une date inferieure a celle actuelle
                            ((this.state.aDispoDateDeb === null || new Date(this.state.aDispoDateDeb) < date) && (this.state.aDispoDateFin === null || new Date(this.state.aDispoDateFin) > date))) { //Et qu'elle respecte les dates min et max des articles selectionnes
                            if (bDerniereSemaineDuMois) { //Si on veut uniquement les dernieres dates du mois
                                lDatesDuMois.push(date) //On ajoute la date a la liste des dates correctes du mois
                            } else if (lNumeroSemaineDuMois.includes((Math.floor(date.getDate() / 7) + 1).toString())) { //Sinon, si on travaille avec le numero de la semaine, et qu'il s'agit de la bonne semaine
                                lDatesValides.push(date) //Alors on ajoute directement la date dans la liste des dates valides
                            }
                        }
                    }
                })

                for (let nbDatesParSemaine = lNumeroJourSemaineRetrait.length; nbDatesParSemaine > 0; nbDatesParSemaine -= 1) { //Pour tous les jours de la semaine voulus
                    if (bDerniereSemaineDuMois && dateActuelle - lDatesDuMois[lDatesDuMois.length - nbDatesParSemaine] < 0) { //Si on veut la derniere semaine du mois, et que la date correspondante n'est pas plus vieille que la date actuelle
                        lDatesValides.push(lDatesDuMois[lDatesDuMois.length - nbDatesParSemaine]) //alors on ajoute la derniere date recuperee a lDatesValides
                    }
                }

                numMois = ("0" + (parseInt(numMois) + 1)).slice(-2) //On incremente de 1 le mois sur lequel on travaille, afin de recommencer l'operation pour le mois suivant
            })

            lDatesValides.forEach(date => { //On genere une option de liste deroulante pour chacune des dates recuperees
                let dateSplit = date.toISOString().slice(0, 10).split("-") // date.toISOSString.slice(0,10) --> format 2013-03-10
                let libelleJour = this.lJoursSemaine[("0" + date.getDay()).slice(-2)]
                let libelleMois = this.lMois[("0" + (date.getMonth() + 1)).slice(-2)]
                lOptionsDatesOk.push(<option key={date} value={date.toISOString().slice(0, 10)}>{libelleJour + " " + dateSplit[2] + " " + libelleMois + " " + dateSplit[0]}</option>)
            })
        }

        if(this.state.aDispoDateDeb !== null && new Date(this.state.aDispoDateDeb) > dateActuelle){
            dateMin = new Date(this.state.aDispoDateDeb)
        }
        let dateMax = this.state.aDispoDateFin !== null ? new Date(this.state.aDispoDateFin) : null
        //console.log(dateMin, dateMax)
        return (
            <div className="text-center mt-5 col-12">
                <h5>Sélectionnez une date et une heure</h5>
                {bFiltreDate ?
                    <select
                        id="dateRetrait"
                        type="select"
                        className="mr-2"
                    >
                        {lOptionsDatesOk}
                    </select>
                    :
                    <input id="dateRetrait" type="date" min={dateMin.toISOString().slice(0, 10)} max={dateMax !== null ? dateMax.toISOString().slice(0, 10) : ""}/>
                }
                <input
                    className="ml-2"
                    id="heureRetrait"
                    type="time"
                    min={heureMin}
                    max={heureMax}
                    required
                />
            </div>
        )
    }

    formCoordonnees = () => {
        let fontSizeText = "12px"
        return (
            <>
                <div className="mt-5 ">
                    <div className="row px-2">
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Nom (requis)" type="text" id="nom" />
                        </div>
                        <div className="form-group col-6">
                            <input style={{ fontSize: fontSizeText }} className="form-control" placeholder="Prénom (requis)" type="text" id="prenom" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="25" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Ville (requis)" type="text" id="ville" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="5" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Code Postal (requis)" type="text" id="codePostal" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Adresse 1 (requis)" type="text" id="adresse1" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Adresse 2" type="text" id="adresse2" />
                        </div>
                        <div className="form-group col-12">
                            <textarea style={{ fontSize: fontSizeText }} placeholder="Commentaire..." maxLength='50' className="form-control" id="commentaire" rows="2"></textarea>
                        </div>
                    </div>
                </div>
                <div className="col-2"></div>
            </>
        )
    }

    GetAutresPointsRetrait = () => {
        let lMagasinsTries = []
        let lMagasinsDuDepartement = []
        let lMagasinsDesAutresDepartements = []
        for (let magasin of this.state.dProducteur.magasins) { //on parcourt tous les magasins du producteurs
            if (magasin.ville_rel !== null && magasin.ville_rel.Code_postal.slice(0, 2) === this.state.dProducteur.p_postal.slice(0, 2)) { //premier tri : ceux du meme departement que le producteur, puis les autres
                lMagasinsDuDepartement.push(magasin);
            } else {
                lMagasinsDesAutresDepartements.push(magasin);
            }
        }
        lMagasinsTries = lMagasinsDuDepartement.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1)
            .concat(lMagasinsDesAutresDepartements.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1)) //2eme tri : par ville

        let lMagasins = lMagasinsTries.map(magasin => //genere une div pour chacun des magasins
            <div key={magasin.idmagasin}>
                • {magasin.ville_rel.v_nom_commune} : {magasin.mg_libelle}
            </div>
        )

        let lMarchesTries = []
        let lMarchesDuDepartement = []
        let lMarchesDesAutresDepartements = []
        for (let marche of this.state.dProducteur.marches) {
            if (marche.ville_rel !== null && marche.ville_rel.Code_postal.slice(0, 2) === this.state.dProducteur.p_postal.slice(0, 2)) {
                lMarchesDuDepartement.push(marche);
            } else {
                lMarchesDesAutresDepartements.push(marche);
            }
        }
        lMarchesTries = lMarchesDuDepartement.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1)
            .concat(lMarchesDesAutresDepartements.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1))

        let lMarches = []
        for (let marche of lMarchesTries) {
            let lJours = []
            let lMois = []
            for (let [key, value] of Object.entries(marche.mr_jour)) {
                value === "1" && lJours.push(key)
            }
            for (let [key, value] of Object.entries(marche.mr_heure)) {
                value === "1" && lJours.push(key === "aprem" ? " après midi" : " " + key)
            }
            for (let mois of marche.mr_liste_mois.split(",").map(mois => mois.trim()).sort(function (a, b) {
                return parseInt(a) - parseInt(b);
            })) {
                lMois.push(this.lMois[mois])
            }
            // AN_DOC gestion lib marche pour ne pas repeter ville si incluse dans le nom du marche
            let libelleMarche = marche.mr_libelle.toLowerCase().includes(marche.ville_rel.v_nom_commune.toLowerCase().trim()) ?
                marche.mr_libelle.trim()
                :
                marche.ville_rel.v_nom_commune.trim() + ", " + marche.mr_libelle.trim()
            lMarches.push(
                <div key={this.state.dProducteur.marches.indexOf(marche)}>
                    • {libelleMarche}{lJours.length > 0 && <span>, le {lJours.join(" ")}</span>}{(lMois.length > 0 && lMois[0] !== undefined) && <span>, en {lMois.join("/")}</span>}
                </div>
            );
        }

        return (
            <div className="col-12 mb-3">
                {(lMagasins.length > 0 || lMarches.length > 0) &&
                    <>
                        <hr />
                        <h4 className="text-center m">Vous pouvez également retrouver ce producteur sans rendez-vous :</h4>
                        <div className="row justify-content-around mt-5">
                            {lMarches.length > 0 &&
                                <div className="lead col-md-5 col-11">
                                    <h5 className="font-weight-bold">Sur les marchés :</h5>
                                    {lMarches}
                                </div>
                            }
                            {lMagasins.length > 0 &&
                                <div className="lead col-md-5 col-11 mt-md-0 mt-3">
                                    <h5 className="font-weight-bold">Dans les magasins :</h5>
                                    {lMagasins}
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        )
    }

    updatePrixTotal_EtDates = () => {
        let prixTotalCommande = 0.0

        let lArticlesChecked = []

        this.state.dProducteur.articles.forEach(article => { //on parcourt tous les articles du producteur
            let prixTotalArticle = document.getElementById("prixTotal" + article.idarticle).innerHTML.replace(" €", "").replace(",", ".") //on recupere le prix total du lot de l'article
            if (article.a_prixvente1 !== "None" && prixTotalArticle !== "") { //Si l'article a un prix, et qu'il y a un prix total de lot (donc si la quantité est >= 1)
                prixTotalCommande += parseFloat(prixTotalArticle) //on incremente le prix total de la commande
                //console.log(parseFloat(prixTotalArticle))
            }

            if(document.getElementById("article" + article.idarticle).checked){
                lArticlesChecked.push(article)
            }
        }
        )

        if (lArticlesChecked.length > 0) {
            let dateDebut = lArticlesChecked.sort((a, b) => { return new Date(a.a_dispodatedeb) < new Date(b.a_dispodatedeb) ? 1 : -1 })[0].a_dispodatedeb
            let dateFin = lArticlesChecked.sort((a, b) => { return new Date(a.a_dispodatefin) > new Date(b.a_dispodatefin) ? 1 : -1 })[0].a_dispodatefin
            this.setState({
                aDispoDateDeb: dateDebut !== "None" ? dateDebut : null,
                aDispoDateFin: dateFin !== "None" ? dateFin : null
            })
        } else {
            this.setState({
                aDispoDateDeb: null,
                aDispoDateFin: null
            })
        }
        //Puis on affiche le prix total
        document.getElementById("prixTotalCommande").innerHTML = '<span class="lead rounded border p-3">Prix total : ' + prixTotalCommande.toFixed(2).toString().replace(".", ",") + ' €</span>'
    }

    componentDidMount = () => {
        localStorage.setItem("lastUrlVisited", window.location.pathname); // PB_2022-06-22 Permet de revenir sur cette page si on s'inscrit 
        this.controller = new AbortController()

        API.get('/producteur/' + this.props.match.params.idProducteur, { params: { type_json: "producteur", filterBy: "idproducteur" }, signal: this.controller.signal })
            .then(response => {
                if (response.data.producteur !== null) {

                    for (let art of response.data.producteur.articles) { // PB_2022-06-22 Traitement qui passe les infos de l'article inter à l'article qui sera affiché
                        for (let artInter of response.data.producteur.articlesInter) {
                            console.log(artInter)
                            if (art.idarticle === artInter.pai_idarticle) {
                                if (artInter.pai_descriptif !== "None") art.a_descriptif = artInter.pai_descriptif;
                                if (artInter.pai_image_custom !== "None") art.a_img = artInter.pai_image_custom;
                                if (artInter.pai_dispodatedeb !== "None") art.a_dispodatedeb = artInter.pai_dispodatedeb;
                                if (artInter.pai_dispodatefin !== "None") art.a_dispodatefin = artInter.pai_dispodatefin;
                                if (artInter.pai_bio !== "None") art.a_bio = artInter.pai_bio;
                                if (artInter.pai_prixvente !== "None") art.a_prixvente1 = artInter.pai_prixvente;
                                break; // PB_2022-06-22 evite de passer plusieurs fois sur le meme article inter 
                            }
                        }
                    }
                    this.setState({
                        dProducteur: response.data.producteur,
                        dPointRetrait: response.data.producteur.p_retraits.length > 0 ? response.data.producteur.p_retraits[0] : {}
                    })
                }
            })
    }

    componentWillUnmount = () => {
        this.controller.abort()
    }

    formConnexion = e => { // PB_2022-06-22 connexion vie PopUp
        e.preventDefault()  //desactive le comportement par defaut du Link pour rester sur la meme page
        ReactDOM.render(<Login dUserInformations={this.props.dUserInformations} bPopup={true} methUtilisateurConnecte={this.props.methUtilisateurConnecte} />, document.querySelector("#contenuPopup"))
        document.querySelector("#idPopup").style.display = "block"
    }

    render() {

        return (
            <div className="container-fluid">
                {/* {(this.state.dProducteur !== null && (this.props.idUtilisateur).toString() === this.props.match.params.idUtilisateur) ? => COMMANDE AVEC CONNEXION
                    ((Object.entries(this.state.dProducteur).length > 0 && this.state.dProducteur.p_cmde_ok === "1") ?
                        <div className="card">
                            <h3 className="text-center py-4">Vous allez passer commande à : {this.state.dProducteur.label1}</h3>
                            <form onSubmit={(e) => this.traiteDemande(e)}>
                                <div className="row justify-content-around">
                                    <div className="col-12">
                                    {this.getArticles()}
                                    </div>
                                    <div className="col-12 row justify-content-center">
                                        <div className="col-12">
                                        {this.getPointRetrait()}
                                        </div>
                                        <div className="col-12">
                                        {this.getDateRdv()}
                                        </div>
                                        <div className="col-md-8 col-12 text-center m-3">
                                            {this.state.dPointRetrait.pr_detail_livraison}
                                        </div>
                                    </div>
                                    <div className="col-12" id="divIdFormCoordonnees" 
                                        style={{display: (this.state.dProducteur.p_retraits[0].pr_libelle.trim() === "A domicile" ? "block" : "none")}}>
                                        {this.formCoordonnees()}
                                    </div>
                                    <div className="col-12 mt-5 text-center">
                                        <div type="text" id="prixTotalCommande" readOnly className="mb-4"></div>

                                        <input type="submit" className="mb-2" value="Passer commande et payer lors du retrait" />
                                        <br />
                                        <input disabled title="Option non disponible pour ce producteur" type="button" value="Passer commande et payer en ligne" />
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        <div style={{ display: "none", border: 'solid', maxWidth: "400px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurCommande"></div>
                                        <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationCommande">
                                            Votre commande a été transmise au producteur.<br />
                                            Merci pour la confiance que vous accordez à Agrivore.
                                        </div>
                                    </div>
                                    {this.GetAutresPointsRetrait()}
                                </div>
                            </form>
                            <div className="p-2">
                                <Link to={"/producteur/" + this.state.dProducteur.p_url_servie}><input type="button" value="Retour" /></Link>
                            </div>
                        </div>
                        :
                        <div className="card">
                            <h3 className="text-center">Récupération des données...</h3>
                        </div>
                    )
                    :
                    <div className="card p-3 mt-5 bg-warning text-center">
                        <h3>Vous n'avez pas accès à cette page</h3>
                    </div>
                } */}

                {
                    ((Object.entries(this.state.dProducteur).length > 0 && this.state.dProducteur.p_cmde_ok === "1") ?
                        <div className="card">
                            <h3 className="text-center py-4">Vous allez passer commande à : {this.state.dProducteur.label1}</h3>
                            <form onSubmit={(e) => this.traiteDemande(e)}>
                                <div className="row justify-content-around">
                                    <div className="col-12">
                                        {this.getArticles()}
                                    </div>
                                    <div className="col-12 row justify-content-center">
                                        <div className="col-12">
                                            {this.getPointRetrait()}
                                        </div>
                                        <div className="col-12">
                                            {this.getDateRdv()}
                                        </div>
                                        <div className="col-md-8 col-12 text-center m-3">
                                            {this.state.dPointRetrait.pr_detail_livraison}
                                        </div>
                                    </div>
                                    <div className="col-12" id="divIdFormCoordonnees"
                                        style={{ display: (this.state.dProducteur.p_retraits[0].pr_libelle.trim() === "A domicile" ? "block" : "none") }}>
                                        {this.formCoordonnees()}
                                    </div>
                                    <div className="col-12 mt-5 text-center">
                                        <div type="text" id="prixTotalCommande" readOnly className="mb-4"></div>

                                        {this.props.dUserInformations.bIsConnected ? // PB_2022-06-22 Si connecté, commande classique, sinon affiche les différents choix (sans con, se connecter, ...)


                                            <div>
                                                <input type="submit" className="btn btn-outline-success  mb-2" value="Passer commande et payer lors du retrait" />
                                                <br />
                                                <input disabled title="Option non disponible pour ce producteur" className="btn btn-outline-success  mb-2" type="button" value="Passer commande et payer en ligne" />
                                                <br />

                                            </div>

                                            :
                                            <div className="px-5">
                                                {!this.state.cmdSansCon ? // PB_2022-06-22 si pas commande sans con => on affiche les btn de choix sinon le formulaire cmde sans con 
                                                    <div>

                                                        <OverlayTrigger
                                                            delay={{ hide: 450, show: 300 }}
                                                            overlay={(props) => (
                                                                <Tooltip {...props}>
                                                                    Vous pouvez facilement effectuer des achats sur Agrivore sans être inscit, mais vous passerez à côté de certains avantages comme la visualisation des commandes
                                                                </Tooltip>
                                                            )}
                                                            placement="top">
                                                            <button className="btn btn-outline-dark m-1" onClick={event => this.setState({ cmdSansCon: true })}>
                                                                <div>
                                                                    Commander sans compte

                                                                    <img className="pl-1" alt="Bio" height="20px" src={process.env.PUBLIC_URL + "/svg/infoBox.svg"} />

                                                                </div>
                                                            </button>

                                                        </OverlayTrigger>
                                                        <button className="btn btn-outline-dark m-1 " onClick={event => { this.formConnexion(event); this.setState({ cmdSansCon: false }) }}> Se connecter ou creer un compte</button>
                                                        {/* <Link target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark ml-1" to="/inscriptionUtilisateur">Creer un compte</Link> */}

                                                    </div>
                                                    :
                                                    <div> {/* formulaire de cmde san connexion */}
                                                        <div className="form-row justify-content-around">
                                                            <div className="form-group col-4">
                                                                <label htmlFor="nomEntered">  Nom* :</label>
                                                                <input required className="form-control" type="text" onChange={event => this.setState({ nomEntered: event.target.value })} ></input>
                                                            </div><br />
                                                            <div className="form-group col-4" >
                                                                <label htmlFor="prenomEntered">Prenom* :</label>
                                                                <input required className="form-control" type="text" onChange={event => this.setState({ prenomEntered: event.target.value })} ></input>
                                                            </div><br />
                                                            <div className="form-group col-4" >
                                                                <label htmlFor="telEntered">N° Tel* :</label>
                                                                <input required className="form-control" type="text" onChange={event => this.setState({ telEntered: event.target.value })} ></input>
                                                            </div><br />
                                                        </div>



                                                        <div className="form-row justify-content-around">
                                                            <div className="form-group col-6">
                                                                <label htmlFor="adresseEntered">Adresse* : </label>
                                                                <input required className="form-control" type="text" onChange={event => this.setState({ adresseEntered: event.target.value })} ></input>
                                                            </div> <br />

                                                            <div className="form-group col-4">
                                                                <label htmlFor="adresseEntered">Ville* : </label>
                                                                <input required className="form-control" type="text" onChange={event => this.setState({ villeEntered: event.target.value })} ></input>
                                                            </div> <br />

                                                            <div className="form-group col-2" >
                                                                <label htmlFor="codePostalEntered" > Code postal* : </label>
                                                                <input required className="form-control" min="1000" max="99999" type="number" onChange={event => this.setState({ codePostalEntered: event.target.value })} ></input>
                                                            </div><br />
                                                        </div>

                                                        <div className="form-row justify-content-around">
                                                            <div className="form-group col-6" >
                                                                <label htmlFor="mailEntered" > Mail* : </label>
                                                                <input required className="form-control" type="email" onChange={event => this.setState({ mailEntered: event.target.value })} ></input>
                                                            </div><br />

                                                            <div className="form-group col-6">
                                                                <label htmlFor="commentaireEntered">Commentaire : </label>
                                                                <input className="form-control" type="text" onChange={event => this.setState({ commentaireEntered: event.target.value })} ></input>
                                                            </div>
                                                        </div>

                                                        {/* <button onClick={e => this.formInscription(e)}> test </button> */}
                                                        <input type="submit" className="btn btn-outline-success m-1" value="Passer commande" />
                                                        <button className="btn btn-outline-dark m-1 " onClick={event => { this.formConnexion(event); this.setState({ cmdSansCon: false }) }}> Se connecter ou creer un compte</button>

                                                    </div>


                                                }
                                            </div>
                                        }


                                    </div>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        <div style={{ display: "none", border: 'solid', maxWidth: "600px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurCommande"></div>
                                        {!this.state.cmdSansCon || this.props.dUserInformations.bIsConnected ?
                                         <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationCommande">
                                            Votre commande a été transmise au producteur.<br />
                                            Merci pour la confiance que vous accordez à Agrivore.
                                        </div>
                                        :
                                        <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationCommande">
                                            Un lien pour valider votre commande vous a été envoyé par mail.<br />
                                            Si vous ne validez pas votre commande, elle ne sera pas transmise au producteur.<br />
                                            Merci pour la confiance que vous accordez à Agrivore.
                                        </div>    
                                        }
                                       
                                    </div>
                                    {this.GetAutresPointsRetrait()}
                                </div>
                            </form>
                            <div className="p-2">
                                <Link to={"/producteur/" + this.state.dProducteur.p_url_servie}><input type="button" className="btn btn-secondary" value="Retour" /></Link>
                            </div>
                        </div>
                        :
                        <div className="card">
                            <h3 className="text-center">Récupération des données...</h3>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default withRouter(CmdeProducteur)