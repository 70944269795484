import { Link } from 'react-router-dom';
import agrivoreIcon from './images/agrivore_icon.png'
import enteteGauche from './images/entete_gauche_index.png'
import enteteDroite from './images/entete_droite.png'
import entete from './images/entete.png'


function Entete(){
    return (          
            <div className='d-flex' style={{width:"100%"}}>
                <div  style={{width:"100%"}} className="row container-fluid justify-content-center" >                
                    {/* <div className="col-2 col-md-1">
                        <Link to="/index">
                            <img alt="" src={agrivoreIcon} style={{ width:'100%', align: 'right'}}></img>
                        </Link>
                    </div>
                    <div className="col-4 col-md-3"  style={{ paddingLeft:'0'}}>
                        <Link to="/index">
                            <img alt="" src={enteteGauche} style={{ width:'100%', }}></img>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4" style={{ paddingLeft:'0'}}>
                        <Link to="/index">
                            <img alt="" src={enteteDroite} style={{ width:'100%'}}></img>
                        </Link>
                    </div> */}
                    
                        {/* <Link className="center-block text-center container-fuild" to="/index">
                            <img style={{width:"60%"}} alt="" src={entete}></img>
                        </Link> */}

                  
                </div>
            </div>
    )
}

export default Entete;