import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import img_not_found from './images/agrivore_icon_not_found.png'

class PageNotFound extends React.Component{
    render(){
        return (
            <div className="container">
                <div className="card rounded p-4 text-center">
                    <h2 className="font-weight-bold">Oups, cette page n'existe pas...</h2>
                    <img className="centerBlock" src={img_not_found} width="200px" alt=" "></img>
                    <Link to="/" className="linkPasBlanc mt-3"><button className="btn btn-outline-secondary">Retour à l'accueil</button></Link>
                </div>
            </div>
        )
    }
}

export default withRouter(PageNotFound)