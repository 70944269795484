import React from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import Axios from 'axios';
import API from './api'

class InscriptionClient extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lTypesActeurs: []
        }
    }
    essaiInscription = (retourBackend) => {
        if (typeof (retourBackend) === "string") {
            document.querySelector('#erreurInscription').innerHTML = retourBackend
            document.querySelector('#erreurInscription').style.display = "block"
            return false
        } else {
            //localStorage.setItem("connectedUser", JSON.stringify(retourBackend))
            document.querySelector('#validationConnexion').style.display = "block"
            document.querySelector('#formulaireInscription').reset()
        }
    }

    erreurForm = message => {
        document.querySelector('#erreurInscription').innerHTML = message
        document.querySelector('#erreurInscription').style.display = "block"
        document.querySelector('#validationConnexion').style.display = "none"
    }

    subForm = () => {
        document.querySelector('#erreurInscription').style.display = "none"  //par default, on desactive l'affichage de la div d'erreur

        //On recupere toutes les saisies de l'utilisateur
        let saisieID = document.querySelector('#inputIdentifiant').value.trim()
        let saisieMDP = document.querySelector('#inputMDP').value.trim()
        let saisieMDPConfirm = document.querySelector('#inputMDPConfirm').value.trim()
        let saisieNom = document.querySelector('#inputNom').value.trim()
        let saisiePrenom = document.querySelector('#inputPrenom').value.trim()
        let saisieAdresse1 = document.querySelector('#inputAdresse1').value.trim()
        let saisieAdresse2 = document.querySelector('#inputAdresse2').value.trim()
        let saisieCodePostal = document.querySelector('#inputCodePostal').value.trim()
        let saisieVille = document.querySelector('#inputVille').value.trim()
        let saisieMail = document.querySelector('#inputMail').value.trim()
        let saisieTelephone = document.querySelector('#inputTelephone').value.trim()
        let saisiePortable = document.querySelector('#inputPortable').value.trim()
        let saisieTypeActeur = document.forms.formInscription.querySelector("input[name=radioTypeActeur]:checked").value
        let saisieGenre = document.forms.formInscription.querySelector("input[name=radioGenre]:checked").value
        let lSaisiesNotNull = [saisieID, saisieMDP, saisieMDPConfirm, saisieNom, saisiePrenom, saisieAdresse1, saisieCodePostal, saisieVille, saisieMail] //liste des saisies doivent posseder une valeur

        if (lSaisiesNotNull.includes("")) { //si une des saisies de la liste precedente ne possede pas de valeur
            this.erreurForm("Veuillez renseigner tous les champs obligatoires (*)") //on appelle la methode d'erreur en lui renseignant le texte a afficher
            return false //et on arrete le traitement du formulaire
        }

        if (saisieTelephone === "" && saisiePortable === "") {
            this.erreurForm("Veuillez, au minimum, renseigner un numéro de téléphone ou de portable")
            return false
        }

        if (!saisieMDP.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)) { //regex pour controler le mot de passe
            this.erreurForm("Le mot de passe doit contenir :</br>8 caractères minimum, au moins une majuscule, une minuscule et un chiffre")
            return false
        }

        if (saisieMDP !== saisieMDPConfirm) {
            this.erreurForm("La confirmation du mot de passe a échoué.")
            return false
        }

        let paramsRequest = {
            c_nom: saisieNom,
            c_adresse: saisieAdresse1,
            c_adresse2: saisieAdresse2,
            c_postal: saisieCodePostal,
            c_ville: saisieVille,
            c_telephone: saisieTelephone,
            c_portable: saisiePortable,
            c_mel: saisieMail,
            name: saisieID,
            password: saisieMDP,
            c_typeacteur: saisieTypeActeur,
            c_genre: saisieGenre
        }

        API.post("/utilisateur/registerClient", { paramsRequest })
            .then(response => this.essaiInscription(response.data))

        // let xhrTriElement = new XMLHttpRequest();
        // xhrTriElement.onload = (e) => {
        //     this.essaiInscription(JSON.parse(e.target.response))
        // };
        // xhrTriElement.onerror = function () {
        //     alert("Erreur inscription BDD")
        // };
        // xhrTriElement.open("POST", localStorage.getItem("urlServer") + "/api/utilisateur/register");
        // xhrTriElement.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        // xhrTriElement.send(`identifiant=${saisieID}&mdp=${saisieMDP}&mail=${saisieMail}`);

    }

    getTypesActeur = () => {
        let lTypesActeurs = this.state.lTypesActeurs.map(typeActeur => //on parcourt la liste des types acteurs, et on genere un input de type radio pour chacun d'eux
            <div key={typeActeur.idtypeacteur} className="form-check form-check-inline mb-4 mt-0">
                <input required className="form-check-input" type="radio" name="radioTypeActeur" id={typeActeur.ta_libelle} value={typeActeur.idtypeacteur} />
                <label className="form-check-label pt-1" htmlFor={typeActeur.ta_libelle}>{typeActeur.ta_libelle}</label>
            </div>
        )

        return lTypesActeurs
    }

    componentDidMount = () => {
        document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='Agrivore' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Inscrition sur Agrivore" />\n
                <meta name="twitter:description"    content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="twitter:image"      content="./images/agrivore_icon.png">\n


                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Inscription sur Agrivore" />\n
                <meta property="og:description"     content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="og:image"           content="./images/agrivore_icon.png">\n
                `)

        API.get("/typesActeur")
            .then(response => this.setState({ lTypesActeurs: response.data.typesActeur })) //recupere la liste des types acteurs
    }

    render() {
        return (
            <>
                {!this.props.dUserInformations.bIsConnected ?
                    <div className="container">
                        <div className="card p-3">
                            <h3>Inscription Client</h3>
                            <form onSubmit={(e) => { e.preventDefault(); this.subForm() }} autoComplete="off" id="formulaireInscription" name="formInscription">
                                <div className="form-group">
                                    <label htmlFor="inputIdentifiant">Identifiant *</label>
                                    <input maxLength="16" required type="text" className="form-control" id="inputIdentifiant" />
                                </div>

                                <div className="form-row justify-content-around">
                                    <div className="form-group col-6">
                                        <label htmlFor="inputMDP">Mot de passe *</label>
                                        <input required type="password" className="form-control" id="inputMDP" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="inputMDPConfirm">Confirmation du mot de passe *</label>
                                        <input required type="password" className="form-control" id="inputMDPConfirm" />
                                    </div>

                                </div>
                                <div className="form-row justify-content-around">
                                    <div className="form-group col-6">
                                        <label htmlFor="inputNom">Nom *</label>
                                        <input maxLength="30" required type="text" className="form-control" id="inputNom" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="inputPrenom">Prénom</label>
                                        <input required type="text" className="form-control" id="inputPrenom" />
                                    </div>
                                </div>
                                <div>
                                    <span>Genre</span>
                                </div>
                                <div className="form-row">
                                        <div className="form-check form-check-inline mb-4 mt-0">
                                            <input required className="form-check-input" type="radio" name="radioGenre" id="genreFemme" value={"F"} />
                                            <label className="form-check-label pt-1" htmlFor="genreFemme">Femme</label>
                                        </div>
                                        <div className="form-check form-check-inline mb-4 mt-0">
                                            <input required className="form-check-input" type="radio" name="radioGenre" id="genreHomme" value={"M"} />
                                            <label className="form-check-label pt-1" htmlFor="genreHomme">Homme</label>
                                        </div>
                                        <div className="form-check form-check-inline mb-4 mt-0">
                                            <input required className="form-check-input" type="radio" name="radioGenre" id="genreAutre" value={"A"} />
                                            <label className="form-check-label pt-1" htmlFor="genreAutre">Autre</label>
                                        </div>
                                        
                                </div>
                                <div className="form-row justify-content-around">
                                    <div className="form-group col-6">
                                        <label htmlFor="inputAdresse1">Adresse 1*</label>
                                        <input maxLength="30" required type="text" className="form-control" id="inputAdresse1" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="inputAdresse2">Adresse 2</label>
                                        <input maxLength="30" type="text" className="form-control" id="inputAdresse2" />
                                    </div>
                                </div>
                                <div className="form-row justify-content-around">
                                    <div className="form-group col-6">
                                        <label htmlFor="inputCodePostal">Code Postal *</label>
                                        <input maxLength="5" required type="text" className="form-control" id="inputCodePostal" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="inputVille">Ville *</label>
                                        <input maxLength="30" required type="text" className="form-control" id="inputVille" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="inputMail">Adresse email *</label>
                                    <input required type="email" className="form-control" id="inputMail" />
                                </div>


                                <div className="form-row justify-content-around">
                                    <div className="form-group col-6">
                                        <label htmlFor="inputTelephone">Téléphone</label>
                                        <input maxLength="20" type="text" className="form-control" id="inputTelephone" />
                                    </div>
                                    <div className="form-group col-6">
                                        <label htmlFor="inputPortable">Portable</label>
                                        <input maxLength="20" type="text" className="form-control" id="inputPortable" />
                                    </div>
                                </div>
                                <div>
                                    <span>Vous êtes...</span><br />
                                    {this.getTypesActeur()}
                                </div>
                                <input type="submit" className='btn btn-secondary' value="Inscription" />
                            </form>
                            <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurInscription"></div>
                            <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationConnexion">
                                Votre compte a bien été créé. <br />
                                Veuillez l'activer à l'aide du lien reçu par mail. N'oubliez pas de consulter vos spams si besoin
                            </div>
                            <span className="mt-3">Vous avez déjà un compte ?<Link className="linkPasBlanc font-weight-bold" to="/login.html"> Connectez-vous ici !</Link></span>
                        </div>
                    </div>
                    :
                    <div className="container">
                        <div className="card p-3 mt-5 bg-warning text-center">
                            <h3>Vous êtes déjà connecté</h3>
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default withRouter(InscriptionClient)