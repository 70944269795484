
import React from 'react'
import MapAgrivLeaflet from './MapAgrivLeaflet'
import CarteSvg from '../CarteSvg'
// import { ReactComponent as MySvg } from '../chevre.svg'; //import {MySvg} from '../chevre.svg'; // si sous forme de fichier puis <MySvg /> plus bas
import { Link } from 'react-router-dom';
import Axios from 'axios';
import RechercheArticle from '../RechercheArticle';
import API from '../api'
import ProducteursProximites from '../ProducteursProximites';

class Agrivore extends React.Component {

  constructor(props) { /* class : props ici puis this.props */
    super(props);

    this.state = {
      lProducteurs: [],
      lProduits: [],
      lProduitsDetail: [],
      lArticles: [],
      lVilles: [],
      typeCarteLeafletSvg: "leaflet", // carte leaflet par defaut et passe a la carte svg si clic sur btnFrance de CarteSvg.js ou MapAgrivLeaflet.js
      positionUtilisateur: undefined,
      centerLeaflet: undefined,
      villeRecherche: ""
      // lDrives: []
    }

    localStorage.setItem("lastUrlVisited", window.location.pathname) // stocke url en cours en tant que derniere url consultee pour retour apres connexion si bes (<> sessionStorage ne gere que l onglet courant)
  }

  getOneButton = (dept) => {
    return (<div key={dept} >
      <Link className="card titlecard btncardlink" to={"departement/" + dept}>
        <div className="row justify-content-around">
          <div className="col-4">{dept}
          </div>
          <div className="col-8">
            {this.props.dDepartements[dept]}
          </div>
        </div>
      </Link>
    </div>)
  }

  getDeptButtons() {
    let lDeptButtons = []
    let lDepNumDep = []
    // const lDepartementNum = new Set(this.props.lProducteurs.map((producteur) => producteur.departement)) // set = select unique, map fait action sur chaq element d une liste et renvoie l'element
    let lDepartementNum = new Set(this.state.lProducteurs.map((producteur) => producteur.departement)) // set = select unique, map fait action sur chaq element d une liste et renvoie l'element
    lDepartementNum = Array.from(lDepartementNum).sort(); // order by
    // for (let i in this.props.dDepartements){
    // for (let i in lDepartementNum){
    //   let dept = lDepartementNum[i];

    let lDeptTriColonne = {
      colonne1: [],
      colonne2: [],
      colonne3: [],
      colonne4: []
    }
    for (let dept of lDepartementNum) {
      if (lDepartementNum.indexOf(dept) / lDepartementNum.length < 0.25) {
        lDeptTriColonne.colonne1.push(dept)
      } else if (lDepartementNum.indexOf(dept) / lDepartementNum.length < 0.50) {
        lDeptTriColonne.colonne2.push(dept)
      } else if (lDepartementNum.indexOf(dept) / lDepartementNum.length < 0.75) {
        lDeptTriColonne.colonne3.push(dept)
      } else {
        lDeptTriColonne.colonne4.push(dept)
      }
      lDepNumDep.push(dept)
    }

    lDeptButtons = Object.entries(lDeptTriColonne).map(depColonne =>
      <div key={depColonne[0]} className="col">
        {depColonne[1].map(dept => this.getOneButton(dept))}
      </div>
    )

    return { "lDeptButtons": lDeptButtons, "lDepNumDep": lDepNumDep };
  }

  // getDeptButtons() {
  //   let lDeptButtons = []
  //   // const lDepartementNum = new Set(this.props.lProducteurs.map((producteur) => producteur.departement)) // set = select unique, map fait action sur chaq element d une liste et renvoie l'element
  //   let lDepartementNum = new Set(this.state.lProducteurs.map((producteur) => producteur.departement)) // set = select unique, map fait action sur chaq element d une liste et renvoie l'element
  //   lDepartementNum = Array.from(lDepartementNum).sort(); // order by
  //   // for (let i in this.props.dDepartements){
  //   // for (let i in lDepartementNum){
  //   //   let dept = lDepartementNum[i];
  //   let lDepNumDep = []
  //   for (let dept of lDepartementNum) {
  //     lDepNumDep.push(dept)
  //     lDeptButtons.push(
  //       // <div key={i} className="col-md-2 col-sm-4 col-6" align="center">                  
  //       <div key={dept} className="col-md-3 col-sm-4 col-6">
  //         <Link className="card titlecard btncardlink" to={"departement/" + dept}>
  //           <div className="row justify-content-around">
  //             <div className="col-4">{dept}
  //             </div>
  //             <div className="col-8">
  //               {this.props.dDepartements[dept]}
  //             </div>
  //           </div>
  //         </Link>
  //       </div>
  //     )
  //   }
  //   return { "lDeptButtons": lDeptButtons, "lDepNumDep": lDepNumDep };
  // }


  componentDidMount() {
    // recup l'url courante qu'on parse avec URLSearchParams ("vanilla js" car js natif) : location = url entiere, .search = parametres seuls
    // this.producteur_filters = new URLSearchParams(this.props.location.search); /* this.props.location appelee par react routeur dispo comme ça car class et non function */
    // console.log(this.producteur_filters);

    this.controller = new AbortController()
    API.get('/producteurs', { params: { type_json: "agrivore" }, signal: this.controller.signal })
      .then((result) => this.setState({ lProducteurs: result.data }))
      .catch(() => alert("Erreur récupération producteurs"))

    API.get('/produits', { signal: this.controller.signal })
      .then((result) => this.setState({ lProduits: result.data.produits }))
      .catch(() => alert("Erreur récupération produits"))

    API.get('/produitsDetail', { signal: this.controller.signal })
      .then((result) => this.setState({ lProduitsDetail: result.data.produitsDetail }))
      .catch(() => alert("Erreur récupération articles"))

    API.get('/articles', { signal: this.controller.signal })
      .then((result) => this.setState({ lArticles: result.data.articles }))
      .catch(() => alert("Erreur récupération articles"))

    API.get('/villes', { signal: this.controller.signal })
      .then((result) => this.setState({ lVilles: result.data.lVilles }))
      .catch(() => alert("Erreur récupération villes"))

    API.get('/pointRetraits', { signal: this.controller.signal })
      .then((result) => this.setState({ lPtRetrait: result.data.pointsRetrait }))
      .catch(() => alert("Erreur récupération points de retraits"))



    // Axios.get(localStorage.getItem("urlServer") + '/api/drives').then((result) => this.setState({lDrives: result.data.drives})) 
    navigator.geolocation.getCurrentPosition(position => this.setState({ positionUtilisateur: position }))
    document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='Agrivore' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Agrivore" />\n
                <meta name="twitter:description"    content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="twitter:image"      content="../images/agrivore_icon.png">\n


                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Agrivore" />\n
                <meta property="og:description"     content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="og:image"           content="../images/agrivore_icon.png">\n
                `)
  }

  componentWillUnmount = () => {
    this.controller.abort()

  }

  changeMap = (typeCarteLeafletSvg) => {
    this.setState({
      typeCarteLeafletSvg: typeCarteLeafletSvg
    })
  }
// pour croix noire centre carte
  getCenterMap = (center) => {
    this.setState({
      centerLeaflet: center
    })
  }

  getVilleRecherche = ville => {
    if (ville != null && this.state.villeRecherche.v_nom_commune !== ville.v_nom_commune) {
      this.setState({
        villeRecherche: ville
      })
    }
  }

  render() {
    return (
      <div className="container-fluid">

        {/* <Link className="nav-link" to="/producteurs/produits/chevre.html"><button><MySvg /></button></Link> */}

        {this.state.typeCarteLeafletSvg === "leaflet" ?
          <div className="row">
            <MapAgrivLeaflet villeRecherche={this.state.villeRecherche} getCenterMap={this.getCenterMap} methUtilisateurConnecte={this.props.methUtilisateurConnecte} dUserInformations={this.props.dUserInformations} methodeChangeMap={this.changeMap} lProducteurs={this.state.lProducteurs} lProduits={this.state.lProduits} />
          </div>
          :
          <div className="row justify-content-center">
            <CarteSvg methodeChangeMap={this.changeMap} lProducteurs={this.state.lProducteurs} lDepartements={this.props.dDepartements} lDepartementsOk={this.getDeptButtons()["lDepNumDep"]} />
          </div>
        }
        {this.props.dUserInformations.bIsAdmin &&
          <div className="row">
            {this.getDeptButtons()["lDeptButtons"]}
          </div>
        }
        {(this.state.lProducteurs.length > 0 && this.state.lArticles.length > 0 && this.state.lVilles.length > 0) &&
          <>
            {this.state.positionUtilisateur !== undefined &&
              <ProducteursProximites centerLeaflet={this.state.centerLeaflet} methUtilisateurConnecte={this.props.methUtilisateurConnecte} positionUtilisateur={this.state.positionUtilisateur} dUserInformations={this.props.dUserInformations} lProduitsDetail={this.state.lProduitsDetail} lArticles={this.state.lArticles} lVilles={this.state.lVilles} lProducteurs={this.state.lProducteurs} />
            }
            <RechercheArticle methodeGetVilleRecherche={this.getVilleRecherche} methUtilisateurConnecte={this.props.methUtilisateurConnecte} dUserInformations={this.props.dUserInformations} lProduitsDetail={this.state.lProduitsDetail} lArticles={this.state.lArticles} lVilles={this.state.lVilles} lProducteurs={this.state.lProducteurs} lPtRetraits={this.state.lPtRetrait} />
          </>
        }
      </div>

    )
  }
}
export default Agrivore;

