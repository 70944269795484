import Axios from 'axios'
import React from 'react'
import { withRouter } from 'react-router'
import './Agrivore.css';
import API from './api'

class CmdeDrive extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dDrive: {},
            lProducteursAssocies: [],
            aDispoDateDeb: null,
            aDispoDateFin: null
        }
        this.controller = new AbortController()
        this.lArticles = []

        this.lMois = {
            "01": "janvier", "02": "février", "03": "mars", "04": "avril", "05": "mai", "06": "juin",
            "07": "juillet", "08": "août", "09": "septembre", "10": "octobre", "11": "novembre", "12": "décembre"
        }
        this.lJoursSemaine = {
            "01": "lundi", "02": "mardi", "03": "mercredi", "04": "jeudi", "05": "vendredi", "06": "samedi", "07": "dimanche"
        }

        //LB : ajout une methode a l'objet Array, pour que Array.range soit equivalent au range() de python : Array.range(1, 5) => [1, 2, 3, 4]
        Array.range = (start, end) => Array.from({ length: (end - start) }, (v, k) => k + start);
    }

    traiteCommande = () => {
        let lArticlesChecked = []
        this.state.lProducteursAssocies.map(producteur =>
            producteur.articles.filter(article => document.getElementById("article" + article.idarticle).checked)
                .map(article =>
                    lArticlesChecked.push(
                        Object.assign(
                            {},
                            {
                                libelleArticle: article.a_libelle,
                                idArticle: article.idarticle,
                                producteur: typeof(producteur.label1) === "string" ? producteur.label1 : producteur.label1.join(""),
                                idProducteur: producteur.idproducteur
                            },
                            {
                                quantite: document.getElementById("quantiteArticle" + article.idarticle).value,
                                prixLot: article.a_prixvente1 !== "None" ? document.getElementById("prixTotal" + article.idarticle).innerHTML.replace(' €', "").replace(",", ".") : "0",
                                prixUnitaire: article.a_prixvente1 !== "None" ? article.a_prixvente1 : "0"
                            }
                        )
                    )
                )
        )

        for (let articleChecked of lArticlesChecked) {
            if (articleChecked.quantite.split([" "])[0].trim() === "") {
                document.getElementById("erreurCommande").innerHTML = "Veuillez sélectionner des quantités valides."
                document.getElementById("erreurCommande").style.display = "block"
                document.getElementById("validationCommande").style.display = "none"
                return false
            }
        }

        if (lArticlesChecked.length === 0) {
            document.getElementById("erreurCommande").innerHTML = "Veuillez sélectionner au moins un article."
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"
            return false
        }


        if (document.getElementById("dateRetrait").value.trim() === "" || document.getElementById("heureRetrait").value.trim() === "") {
            document.getElementById("erreurCommande").innerHTML = "Veuillez sélectionner une date et une heure."
            document.getElementById("erreurCommande").style.display = "block"
            document.getElementById("validationCommande").style.display = "none"
            return false
        }

        // //recupere les saisies de l'utilisateur
        // let nomEntered = document.getElementById('nom').value.trim()
        // let prenomEntered = document.getElementById('prenom').value.trim()
        // let codePostalEntered = document.getElementById('codePostal').value.trim()
        // let villeEntered = document.getElementById('ville').value.trim()
        // let adresse1Entered = document.getElementById('adresse1').value.trim()
        // let adresse2Entered = document.getElementById('adresse2').value.trim()
        // let commentaireEntered = document.getElementById('commentaire').value.trim()

        // if ([nomEntered, prenomEntered, codePostalEntered, villeEntered, adresse1Entered].includes("")) { //verifie que les champs obligatoires ont bien ete remplis
        //     document.getElementById("erreurCommande").innerHTML = "Veuillez renseigner toutes vos coordonnées."
        //     document.getElementById("erreurCommande").style.display = "block"
        //     document.getElementById("validationCommande").style.display = "none"
        //     return false
        // }

        let valueDateRetrait = document.getElementById("dateRetrait").value
        let valueHeureRetrait = document.getElementById("heureRetrait").value

        let paramsRequest = {
            lArticlesSelected: lArticlesChecked,
            idDrive: this.props.match.params.idDrive,
            libellePointRetraitSelected: this.state.dDrive.pr_libelle,
            dateRetrait: valueDateRetrait,
            heureRetrait: valueHeureRetrait,
            // nomClient: nomEntered,
            // prenomClient: prenomEntered,
            // codePostalClient: codePostalEntered,
            // villeClient: villeEntered,
            // adresse1Client: adresse1Entered,
            // adresse2Client: adresse2Entered,
            // commentaireClient: commentaireEntered
        }

        API.post("/commande/drive/" + this.props.match.params.idDrive + "/utilisateur/" + this.props.match.params.idUtilisateur, { paramsRequest })
            .then(() => {
                document.getElementById("validationCommande").style.display = "block"
                document.getElementById("erreurCommande").style.display = "none"
            }
            )
    }

    informationsDrive = () => {
        return (<div>
            <h5 className="text-center">Quelques informations sur ce point de retrait :</h5>
            <div className="row align-items-center">
                <img src={process.env.PUBLIC_URL + "/img_drive/" + this.state.dDrive.pr_img} alt=" " title={this.state.dDrive.pr_libelle} />
                <h6 className=" centerBlock">
                    {this.state.dDrive.pr_libelle}
                    <br />Situé à {this.state.dDrive.pr_ville.v_nom_commune.trim()}, {this.state.dDrive.pr_ville.Code_postal}
                    <br />{this.state.dDrive.pr_commentaire}</h6>
            </div>
        </div>)
    }

    getArticles = () => {
        let dateActuelle = new Date()

        let lArticles = []
        this.state.lProducteursAssocies.map(producteur => producteur.articles.sort((a, b) => {  // a et b = 2 articles (b - a les compare pour trier dans ordre decroissant) et 2e critere de tri a - b ordre croissant (pour lister articles specifiq en haut) puis .map parcourt chacun des articles du producteur, en ajoutant une ligne de tableau pour chacun d'eux
        let diffIdProducteur = b.a_idproducteur - a.a_idproducteur
        return diffIdProducteur > 0 ? 1 : (diffIdProducteur < 0 ? - 1 : (a.a_libelle.toLowerCase() > b.a_libelle.toLowerCase() ? 1 : -1))
    }).map(article =>
            !lArticles.map(article => article.idarticle).includes(article.idarticle) ?
                lArticles.push(article)
                :
                null))

        this.lArticles = Array.from(new Set(lArticles)).sort((a, b) => a.a_idproduitdetail > b.a_idproduitdetail ? 1 : -1)

        lArticles = this.state.lProducteursAssocies.map(producteur =>
            <React.Fragment key={producteur.idproducteur}>
                <h6 style={{ textDecoration: "underline" }} className="text-center mt-5">{producteur.label1}</h6>
                <table className="table table-sm table-hover table-responsive-md">
                    <thead className="text-center thead-light">
                        <tr>
                            <th scope="col">
                                Article
                            </th>
                            <th scope="col" style={{ minWidth: '100px' }}>
                                Quantité
                            </th>
                            <th scope="col" className="px-3">
                                Prix unitaire
                            </th>
                            <th scope="col" className="px-3">
                                Prix total
                            </th>
                            <th scope="col" className="px-3">
                                A partir du
                            </th>
                            <th scope="col" className="px-3">
                                Jusqu'au
                            </th>
                            <th scope="col" className="px-3">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {producteur.articles.map(article =>
                            <tr key={article.idarticle}>
                                <td scope="row" className="pl-4">
                                    <input id={"article" + article.idarticle} className="form-check-input" type="checkbox" onChange={(e) => {
                                        if (e.target.checked) {
                                            document.getElementById("quantiteArticle" + article.idarticle).value = "1"
                                            if (article.a_prixvente1 !== "None") {
                                                document.getElementById("prixTotal" + article.idarticle).innerHTML = (parseFloat(article.a_prixvente1).toFixed(2) + " €").replace(".", ",")
                                                this.updatePrixTotal_EtDates()
                                            }
                                        } else {
                                            document.getElementById("quantiteArticle" + article.idarticle).value = "0"
                                            document.getElementById("prixTotal" + article.idarticle).innerHTML = ""
                                        }
                                        this.updatePrixTotal_EtDates()
                                    }}
                                        disabled={article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin))}
                                        style={{ borderColor: ((article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin))) ? "grey" : "black") }}
                                    />
                                    <label className="ml-2 mt-1 form-check-label" htmlFor={"article" + article.idarticle}>
                                        <img className="mr-2" width='28px' src={process.env.PUBLIC_URL + "/" + article.a_img} alt="" />
                                        {article.a_libelle}
                                    </label>
                                </td>
                                <td className="text-center">
                                    <input type="number" min="0" defaultValue="0" style={{ width: "80%" }} id={"quantiteArticle" + article.idarticle} onChange={(e) => {
                                        if (!["0", ""].includes(e.target.value) && parseInt(e.target.value) > 0) {
                                            document.getElementById('article' + article.idarticle).checked = true
                                            if (article.a_prixvente1 !== "None") {
                                                document.getElementById("prixTotal" + article.idarticle).innerHTML = ((e.target.value * parseFloat(article.a_prixvente1)).toFixed(2) + " €").replace(".", ",")
                                            }
                                        } else {
                                            document.getElementById('article' + article.idarticle).checked = false
                                            document.getElementById("prixTotal" + article.idarticle).innerHTML = ""
                                        }
                                        this.updatePrixTotal_EtDates()
                                    }
                                    }
                                        disabled={article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin))}
                                    />
                                </td>
                                <td className="text-center" style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                                    {article.a_prixvente1 !== "None" && article.a_prixvente1.slice(0, article.a_prixvente1.length - 2) + " €"}
                                </td>
                                <td className="text-center">
                                    <span id={"prixTotal" + article.idarticle}></span>
                                </td>
                                <td className="text-center">
                                    {article.a_dispodatedeb !== "None" &&
                                        <span style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                                            {article.a_dispodatedeb.split('-')[2] + "/" + article.a_dispodatedeb.split('-')[1] + "/" + article.a_dispodatedeb.split('-')[0]}
                                        </span>
                                    }
                                </td>
                                <td className="text-center">
                                    {article.a_dispodatefin !== "None" &&
                                        <span style={{ color: (article.a_prixvente1 === "None" || (article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                                            {article.a_dispodatefin.split('-')[2] + "/" + article.a_dispodatefin.split('-')[1] + "/" + article.a_dispodatefin.split('-')[0]}
                                        </span>
                                    }
                                </td>
                                <td className="text-left">
                                    {article.a_prixvente1 !== "None" ?
                                        ((article.a_dispodatedeb !== "None" && dateActuelle < new Date(article.a_dispodatedeb)) ?
                                            <span>Réservez dès maintenant !</span>
                                            :
                                            ((article.a_dispodatefin !== "None" && dateActuelle > new Date(article.a_dispodatefin)) ?
                                                <span style={{ color: ((article.a_dispodatefin !== null && dateActuelle > new Date(article.a_dispodatefin)) ? "grey" : "black") }}>
                                                    Epuisé
                                                </span>
                                                :
                                                ""
                                            )
                                        )
                                        :
                                        <span style={{ color: "grey" }}>
                                            Non disponible à la commande
                                        </span>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>)

        return (
            <div>
                <h5 className="text-center">Cochez les articles qui vont intéressent</h5>
                {lArticles}

                <div id="prixTotalCommande" readOnly className="text-center  mt-4"></div>

            </div>
        )
    }

    updatePrixTotal_EtDates = () => {
        let prixTotalCommande = 0.0
        let lArticlesChecked = []

        this.state.lProducteursAssocies.forEach(producteur => {
            producteur.articles.forEach(article => { //on parcourt tous les articles du producteur
                let prixTotalArticle = document.getElementById("prixTotal" + article.idarticle).innerHTML.replace(" €", "").replace(",", ".") //on recupere le prix total du lot de l'article
                if (article.a_prixvente1 !== "None" && prixTotalArticle !== "") { //Si l'article a un prix, et qu'il y a un prix total de lot (donc si la quantité est >= 1)
                    prixTotalCommande += parseFloat(prixTotalArticle) //on incremente le prix total de la commande
                }
                
                if(document.getElementById("article" + article.idarticle).checked){
                    lArticlesChecked.push(article)
                }
            })
        })

        if (lArticlesChecked.length > 0) {
            let dateDebut = lArticlesChecked.sort((a, b) => { return new Date(a.a_dispodatedeb) < new Date(b.a_dispodatedeb) ? 1 : -1 })[0].a_dispodatedeb
            let dateFin = lArticlesChecked.sort((a, b) => { return new Date(a.a_dispodatefin) > new Date(b.a_dispodatefin) ? 1 : -1 })[0].a_dispodatefin
            this.setState({
                aDispoDateDeb: dateDebut !== "None" ? dateDebut : null,
                aDispoDateFin: dateFin !== "None" ? dateFin : null
            })
        } else {
            this.setState({
                aDispoDateDeb: null,
                aDispoDateFin: null
            })
        }
        //Puis on affiche le prix total
        document.getElementById("prixTotalCommande").innerHTML = '<span class="lead rounded p-3 border">Prix total : ' + prixTotalCommande.toFixed(2).toString().replace(".", ",") + ' €</span>'
    }

    getDateRDV = () => {
        let bFiltreDate = false // true : on filtre les dates avec l'algo / false : on affiche un simple input de type date (sans contrainte)

        let heureMin = "09:00" //heure minimum de retrait de la commande
        let heureMax = "18:00" //heure max

        let stringJoursSemaineRetrait = "";
        ["pr_lundi", "pr_mardi", "pr_mercredi", "pr_jeudi", "pr_vendredi", "pr_samedi", "pr_dimanche"].forEach((pr_jour, index) => {
            if (this.state.dDrive[pr_jour] === "1") {
                stringJoursSemaineRetrait += (index + 1).toString()
            }
        })

        if (stringJoursSemaineRetrait.length > 0) {
            bFiltreDate = true
        }

        let dateActuelle = new Date()
        let dateMin = dateActuelle;
        let lOptionsDatesOk = []

        if (bFiltreDate) {
            let lNumeroJourSemaineRetrait = Array.from(stringJoursSemaineRetrait) //liste des numeros de jour de la semaine valide. Array.from("24") --> ["2", "4"]
            let lNumeroSemaineDuMois = Array.from(this.state.dDrive.pr_liste_semaine_1234) //liste des numeros de semaines du mois valides. Array.from("13") --> ["1", "3"]
            let nbMoisPeriode = 5 //Nombre de mois sur lesquels on propose des dates
            let bDerniereSemaineDuMois = false //false : on travaille avec numeroSemaineDuMois / true : peu importe le numeroSemaineDuMois, on recupere la derniere semaine du mois (qui varie entre la 4 et 5)
            let dateActuelle = new Date()
            let numMois = ("0" + (dateActuelle.getMonth() + 1)).slice(-2) //recupere le numero du mois actuel
            let numAnnee = dateActuelle.getFullYear() //numero de l'annee actuelle
            let lDatesValides = []
            Array.range(parseInt(numMois), parseInt(numMois) + nbMoisPeriode + 1).forEach(() => { //On parcourt une range de numeros, en partant du numero de mois actuel (range de 6 a 16 par exemple)
                if (parseInt(numMois) > 12) { //si le numero du mois depasse 12
                    numMois = ("0" + (parseInt(numMois) - 12)).slice(-2)  //On enleve 12 mois, pour revenir a janvier
                    numAnnee = (parseInt(numAnnee) + 1).toString() //et on incremente l'annee de 1
                }
                let lDatesDuMois = []
                Array.range(1, 31).forEach(numJour => { //on parcourt une range de 1 a 31, qui correspond a tous les jours du mois
                    let date = new Date(numAnnee + "-" + numMois + "-" + ("0" + numJour).slice(-2)) //on genere une date avec l'annee/mois/jour de la boucle
                    if (lNumeroJourSemaineRetrait.includes(date.getDay().toString())) { //Si le numero de la semaine du jour correspond a celui souhaite (2 pour mardi, par exemple)
                        if (dateActuelle - date < 0 && //Si il ne s'agit pas d'une date inferieure a celle actuelle
                            ((this.state.aDispoDateDeb === null || new Date(this.state.aDispoDateDeb) < date) && (this.state.aDispoDateFin === null || new Date(this.state.aDispoDateFin) > date))) { //Et qu'elle respecte les dates min et max des articles selectionnes) {
                            if (bDerniereSemaineDuMois) { //Si on veut uniquement les dernieres dates du mois
                                lDatesDuMois.push(date) //On ajoute la date a la liste des dates correctes du mois
                            } else if (lNumeroSemaineDuMois.includes((Math.floor(date.getDate() / 7) + 1).toString())) { //Sinon, si on travaille avec le numero de la semaine, et qu'il s'agit de la bonne semaine
                                lDatesValides.push(date) //Alors on ajoute directement la date dans la liste des dates valides
                            }
                        }
                    }
                })

                for (let nbDatesParSemaine = lNumeroJourSemaineRetrait.length; nbDatesParSemaine > 0; nbDatesParSemaine -= 1) { //Pour tous les jours de la semaine voulus
                    if (bDerniereSemaineDuMois && dateActuelle - lDatesDuMois[lDatesDuMois.length - nbDatesParSemaine] < 0) { //Si on veut la derniere semaine du mois, et que la date correspondante n'est pas plus vieille que la date actuelle
                        lDatesValides.push(lDatesDuMois[lDatesDuMois.length - nbDatesParSemaine]) //alors on ajoute la derniere date recuperee a lDatesValides
                    }
                }

                numMois = ("0" + (parseInt(numMois) + 1)).slice(-2) //On incremente de 1 le mois sur lequel on travaille, afin de recommencer l'operation pour le mois suivant
            })

            lDatesValides.forEach(date => { //On genere une option de liste deroulante pour chacune des dates recuperees
                let dateSplit = date.toISOString().slice(0, 10).split("-") // date.toISOSString.slice(0,10) --> format 2013-03-10
                let libelleJour = this.lJoursSemaine[("0" + date.getDay()).slice(-2)]
                let libelleMois = this.lMois[("0" + (date.getMonth() + 1)).slice(-2)]
                lOptionsDatesOk.push(<option key={date} value={date.toISOString().slice(0, 10)}>{libelleJour + " " + dateSplit[2] + " " + libelleMois + " " + dateSplit[0]}</option>)

            })
        }

        if (this.state.aDispoDateDeb !== null && new Date(this.state.aDispoDateDeb) > dateActuelle) {
            dateMin = new Date(this.state.aDispoDateDeb)
        }
        let dateMax = this.state.aDispoDateFin !== null ? new Date(this.state.aDispoDateFin) : null
        return (
            <div className="text-center mt-5">
                <h5>Sélectionnez une date et une heure</h5>
                {bFiltreDate ?
                    <select
                        id="dateRetrait"
                        type="select"
                        className="mr-2"
                    >
                        {lOptionsDatesOk}
                    </select>
                    :
                    <input id="dateRetrait" type="date" min={dateMin.toISOString().slice(0, 10)} max={dateMax !== null ? dateMax.toISOString().slice(0, 10) : ""}/>
                }
                <input
                    id="heureRetrait"
                    type="time"
                    min={heureMin}
                    max={heureMax}
                    required
                />
            </div>
        )
    }

    formCoordonnees = () => {
        let fontSizeText = "12px"
        return (
            <>
                <div className="mt-5 col-md-5 col-12 centerBlock" style={{display:"none"}}>
                    <div className="row px-2">
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Nom (requis)" type="text" id="nom" />
                        </div>
                        <div className="form-group col-6">
                            <input style={{ fontSize: fontSizeText }} className="form-control" placeholder="Prénom (requis)" type="text" id="prenom" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="25" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Ville (requis)" type="text" id="ville" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="5" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Code Postal (requis)" type="text" id="codePostal" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Adresse 1 (requis)" type="text" id="adresse1" />
                        </div>
                        <div className="form-group col-6">
                            <input maxLength="30" style={{ fontSize: fontSizeText }} className="form-control" placeholder="Adresse 2" type="text" id="adresse2" />
                        </div>
                        <div className="form-group col-12">
                            <textarea style={{ fontSize: fontSizeText }} placeholder="Commentaire..." maxLength='50' className="form-control" id="commentaire" rows="2"></textarea>
                        </div>
                    </div>
                </div>
                <div className="col-2"></div>
            </>
        )
    }

    componentDidMount = () => {
        API.get("/pointRetrait/" + this.props.match.params.idDrive)
            .then(response => this.setState({ dDrive: response.data }))

            API.get("/producteurs")
            .then(response => {
                this.setState({
                    lProducteursAssocies: response.data.producteurs.filter(producteur =>
                        producteur.p_retraits.filter(p_retrait => (p_retrait.idpointretrait).toString() === this.props.match.params.idDrive).length > 0 &&
                        producteur.p_cmde_ok === "1")
                })
                window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }

    componentWillUnmount = () => {
        this.controller.abort()
    }

    render() {
        //console.log(this.state)
        return (
            <div className="container-fluid">
                {Object.entries(this.state.dDrive).length > 0 &&
                    <div className="card p-5">
                        <h1 className="text-center">Commande sur le point de retrait {this.state.dDrive.pr_libelle}</h1>
                        <hr />
                        <div className="mt-3">
                            {this.informationsDrive()}
                        </div>
                        <div className="mt-3">
                            <hr />
                            {this.getArticles()}
                        </div>
                        <div className="mt-3">
                            <hr />
                            {this.getDateRDV()}
                        </div>
                        <div className="mt-3">
                            {this.formCoordonnees()}
                        </div>
                        <div className="px-5 my-5 centerBlock">
                            <input onClick={() => this.traiteCommande()} type="button" className="mb-2 centerBlock " value="Passer commande et payer lors du retrait" /> <br/>
                            <input disabled title="Option non disponible pour ce drive" type="button" className="centerBlock" value="Passer commande et payer en ligne" />
                        </div>
                        <div className="col-12 d-flex justify-content-center mb-3">
                            <div style={{ display: "none", border: 'solid', maxWidth: "400px" }} className="rounded mt-3 alert alert-danger text-center" id="erreurCommande"></div>
                            <div style={{ display: "none", border: 'solid', maxWidth: "700px" }} className="rounded mt-3 alert alert-success text-center" id="validationCommande">
                                Votre commande a été transmise au producteur.<br />
                                Merci pour la confiance que vous accordez à Agrivore.
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(CmdeDrive)