import { TileLayer, MapContainer, Marker, Popup, FeatureGroup, LayersControl, useMapEvents, useMap } from 'react-leaflet';
import React, { useState, useEffect } from 'react'; // useEffect pour composant sous forme de function
import '../leaflet_an.css';
import L from 'leaflet';
import 'leaflet';
import Carousel from 'react-bootstrap/Carousel'

import markerRetinaIcon from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

import 'react-leaflet-markercluster';
import '../stylesClusters.min.css';

import agrivoreIcon from '../images/agrivore_icon.png'
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Login from '../Login';

import AgrivoreTxt from '../images/Agrivore_NavLogo.png';




delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetinaIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow
});
L.Marker.prototype.icon = L.Icon.Default;

// AN
var LeafIcon = L.Icon.extend({
  options: {
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [12, 41],
    shadowAnchor: [11, 43],
    popupAnchor: [1, -34]
  }
});

var dMarkerIcons_ancien = {
  blackIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-black.png" }), "descriptifIcon": "black pas carte" },
  blueIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-blue.png" }), "descriptifIcon": "Poisson" },
  bluelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "ico/marker-bluelight.png" }), "descriptifIcon": "Boissons" },
  brownIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-brown.png" }), "descriptifIcon": "Boulangerie" },
  browndarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-browndark.png" }), "descriptifIcon": "Escargot" },
  brownlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-brownlight.png" }), "descriptifIcon": "Volaille & Basse-cour" },
  cyanIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-cyan.png" }), "descriptifIcon": "cyan pas carte" },
  cyanlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-cyanlight.png" }), "descriptifIcon": "cyanlight pas carte" },
  grayIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-gray.png" }), "descriptifIcon": "Laine" },
  graydarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-graydark.png" }), "descriptifIcon": "graydark pas carte" },
  graylightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-graylight.png" }), "descriptifIcon": "graylight pas carte" },
  greenIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-green.png" }), "descriptifIcon": "Légumes" },
  greendarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-greendark.png" }), "descriptifIcon": "Plantes aromatiques" },
  greenlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-greenlight.png" }), "descriptifIcon": "Fruits" },
  orangeIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orange.png" }), "descriptifIcon": "orange pas carte" },
  orangedarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangedark.png" }), "descriptifIcon": "orangedark pas carte" },
  orangelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangelight.png" }), "descriptifIcon": "Produits de la ruche" },
  orangelight2Icon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangelight2.png" }), "descriptifIcon": "orangelight pas carte" },
  pinkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-pink.png" }), "descriptifIcon": "Plants fleur" },
  pinklightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-pinklight.png" }), "descriptifIcon": "Entremets" },
  purpleIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-purple.png" }), "descriptifIcon": "Cosmétique" },
  purplelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-purplelight.png" }), "descriptifIcon": "purplelight pas carte" },
  redIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-red.png" }), "descriptifIcon": "Viande" },
  reddarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-reddark.png" }), "descriptifIcon": "Gibier" },
  redlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-redlight.png" }), "descriptifIcon": "redlight pas carte" },
  whiteIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-white.png" }), "descriptifIcon": "Produits laitiers" },
  white2Icon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-white2.png" }), "descriptifIcon": "white2 pas carte" },
  yellowIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-yellow.png" }), "descriptifIcon": "Céréales" },
  yellowlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-yellowlight.png" }), "descriptifIcon": "Huile" },
  "driveIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-drive.svg" }), "descriptifIcon": "Points de retrait / Drives" },
  "marcheIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-marche.svg" }), "descriptifIcon": "Marchés" },
  "magasinIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-magasin.svg" }), "descriptifIcon": "Magasins" }
}

var dMarkerIcons = { //dictionnaire que l'on utilise pour generer la legende. Il est peuple a l'aide de la methode getDictMarkerIcons
  // blackIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-black.png" }) },
  // blueIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-blue.png" })  },
  // bluelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "ico/marker-bluelight.png" })  },
  // brownIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-brown.png" }) },
  // browndarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-browndark.png" }) },
  // brownlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-brownlight.png" }) },
  // cyanIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-cyan.png" }) },
  // cyanlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-cyanlight.png" }) },
  // grayIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-gray.png" }) },
  // graydarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-graydark.png" }) },
  // graylightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-graylight.png" }) },
  // greenIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-green.png" }) },
  // greendarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-greendark.png" }) },
  // greenlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-greenlight.png" }) },
  // orangeIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orange.png" }) },
  // orangedarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangedark.png" }) },
  // orangelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangelight.png" }) },
  // orangelight2Icon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-orangelight2.png" }) },
  // pinkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-pink.png" }) },
  // pinklightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-pinklight.png" }) },
  // purpleIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-purple.png" }) },
  // purplelightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-purplelight.png" }) },
  // redIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-red.png" }) },
  // reddarkIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-reddark.png" }) },
  // redlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-redlight.png" }) },
  // whiteIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-white.png" }) },
  // white2Icon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-white2.png" }) },
  // yellowIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-yellow.png" }) },
  // yellowlightIcon: { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-yellowlight.png" }) },
  "driveIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-drive.svg", iconSize: [53, 35] }) },
  "marcheIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-marche.svg", iconSize: [53, 35] }) },
  "magasinIcon": { "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-magasin.svg", iconSize: [35, 60] }) }
}

var dictMarkerIcons_initialLength = Object.entries(dMarkerIcons).length //Taille initiale du dictionnaire, afin de determiner quand il a ete peuple


/* LB :
Composant qui ne renvoie rien, mais qui récupère la position de l'utilisateur puis 
zoom sur cette dernière
*/
function LocationMarker(props) {
  const [position, setPosition] = useState(null)
  const [bIsLoad, setIsLoad] = useState(false)
  const [villeRecherche, setVilleRecherche] = useState("")
  const map = useMapEvents({  //declare les events (idem que useMap avec possibilites elargies des events)
    locationfound(e) {  //quand on trouve une localisation
      let lat;
      let long;
      setPosition(e.latlng);
      (e.latlng.lat > 42.8 && e.latlng.lat < 46) && (e.latlng.lng > -1.1 && e.latlng.lng < 5.6) ? [lat, long] = [e.latlng.lat, e.latlng.lng] : [lat, long] = [44.3, 2.5]; //si localisation trop loin, recentre automatiquement
      map.flyTo([lat, long], 10) //on zoom sur la position ([lattitude, longitude], zoom)

      L.marker([lat, long], {icon: new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/you_are_here.svg", iconSize: [50, 50]})}
      ).bindPopup("<b>Vous êtes ici !</b>").addTo(map);
      setIsLoad(true)
      props.methodeMap() //on affiche le contenu de la carte
    },
    zoomstart() {
      props.updateMap()
    },
    move() {
      props.updateMap()
    },
    moveend(){
      if (bIsLoad) {
        props.methodeGetCenterMap(map.getCenter())
        // console.log("a")
      }
    }
    
  });
  map.on('load', () => { map.locate() });  //quand map chargee, on cherche position pour appeler l'event locationfound
  if (!props.bMapCharge) {
    map.setView([44.3, 2.5], 10) //on charge la map pour appeler l'event load ([lattitude, longitude], zoom) --> aveyron par defaut
  }

  useEffect(() => {    // fonction appelee a chaque update du composant
    if(![props.villeRecherche.v_gps_latitude, props.villeRecherche.v_gps_longitude].includes(undefined)){
      if(villeRecherche !== props.villeRecherche.v_nom_commune){
        map.flyTo([props.villeRecherche.v_gps_latitude, props.villeRecherche.v_gps_longitude])
        setVilleRecherche(props.villeRecherche.v_nom_commune)
      }
    // props.updateMap()
    }
  }); 

  

  return null
}

function MapDezoom(props){
  // const map = useMap() // useMap permet acces aux attributs et meth de la carte leaflet via appels composant a l interieur de <MapContainer>
  const map = useMapEvents({  //declare les events (idem que useMap avec possibilites elargies des events)
    zoomstart() {
      setTypeMiniMap("france")
    }})

  const [typeMiniMapFranceMarker, setTypeMiniMap] = useState("france")
  const [zoom, setZoom] = useState(map.getZoom());
  let center = map.getCenter()
  const [position, setPosition] = useState([center.lat, center.lng])

  let dezoom = () => { //methode appelee suite a un clic sur la mini map en haut a gauche
    let zoomActuel = map.getZoom() //on recupere le zoom actuel de l'utilisateur
    if(zoomActuel === 6){ //si le zoom est de 6 (c'est a dire le zoom minimum)
      map.setView(position, zoom) //alors on ramene l'utilisateur a sa position precedente
      setTypeMiniMap("france")
    } else { //Sinon, si la carte n'est pas dezoomee au maximum
      setZoom(map.getZoom()) //alors on enregistre le zoom actuel de l'utilisateur
      let center = map.getCenter()
      setPosition([center.lat, center.lng]) //et sa position (afin de pouvoir le ramener a cette vue si il reclique sur la mini map)
      map.setView([46.2, 2.2], 6) //et on dezoom la carte pour lui afficher la totalite de la France
      setTypeMiniMap("marker")
    }
  }
  let buttonDezoom = <button className="rounded" style={{ marginLeft: "10px" }} onClick={dezoom}><img width="25px" height='30px'
      src={process.env.PUBLIC_URL + (typeMiniMapFranceMarker === "france" ? "/ico/france_map.svg" : "/ico/marker-zoom_ancienne_position.png")} alt="" />
    </button>
  return buttonDezoom
}

function ListLayers(props) {
  let choixZoom_10 = 10   //gestion de l'affichage des markers selon zoom, attention toujours mettre <= 10

  let map = useMap() //permet d'utiliser les methodes/attributs de la map, a l'aide de map.X
  let centerMap = map.getCenter()
  let extremitesMap = map.getBounds() //coordonnes nord-est et sud-ouest des extremites de la portion de carte visible a l'ecran
  let zoomMap = map.getZoom() //valeur du zoom de la map
  let layers = {};

  let lMarchesAffiche = []  // LB : Gestion de l'affichage des marchés
  let lDrivesAffiches = [] //LB : et des points de retrait
  let lMagAffiches = [] //LB : et des magasins

  let layerGroups = [];
  let lPositionsMarcheTraitees = []
  let lPositionsMagasinTraitees = []
  let lPositionsDrivesTraitees = []

  let compteurKey = 0

  let dMois = {"01": "janvier", "02": "février", "03": "mars", "04": "avril", "05": "mai", "06": "juin",
                     "07": "juillet", "08": "août", "09": "septembre", "10": "octobre", "11": "novembre", "12": "décembre"} 

  let lProducteurs = props.lProducteurs.filter(producteur => producteur.p_siren !== "" && producteur.p_siren !== null ) //Par defaut, on a tous les producteurs qui ont un p_siren
  
  let distanceMaxPopupBordure = { //Lorsque l'on clique sur un Ag1 (magasin, ...), on ouvre sa popup uniquement s'il est assez loin des bordures
    10:[0.3, 0.1], //Format : zoom:[distanceMinAvecBorduresHautDroiteGauche, distanceMinAvecBordureBas]
    11:[0.19, 0.008], //Par exemple : si le zoom est de 11, le marker de l'element doit etre a : 0.19° des bordures du haut, droite et gauche ; 0.008° de la bordure du bas
    12:[0.07, 0.004],
    13:[0.03, 0.004],
    14:[0.02, 0.002]
  }

  let formConnexion = e => {
    e.preventDefault()  //desactive le comportement par defaut du Link pour rester sur la meme page
    ReactDOM.render(<Login dUserInformations={props.dUserInformations} bPopup={true} methUtilisateurConnecte={props.methUtilisateurConnecte}/>,  document.querySelector("#contenuPopup"))
    // document.querySelector("#contenuPopup").innerHTML = <Login/>
    document.querySelector("#idPopup").style.display = "block"
  }

  let getSvgProd = (producteur) => { //Recupere les svg du producteur afin de les integrer dans les popup des Ag1
    let lProduitsProduct = []
        let lSvgRecup = []
        for(let produits of producteur.produits_details){ 
            if(!lSvgRecup.includes(produits.prd_image)){ //Evite les doublons 
                lSvgRecup.push(produits.prd_image)
                lProduitsProduct.push(
                    <img alt="" style={{marginRight: 5 +"px"}} key={lProduitsProduct.length} height="15px" width="15px" title={produits.prd_libelle} src={process.env.PUBLIC_URL + "/svg/" + produits.prd_image}/>
                );
            }
        }
        return <span>{lProduitsProduct}</span>
  }


  if(props.bCreaClusters){ //Si les clusters ont deja ete crees, on applique les filtres
    lProducteurs = props.lProducteurs.filter(producteur => (producteur.p_gps_latitude < extremitesMap._northEast.lat && producteur.p_gps_longitude < extremitesMap._northEast.lng  // LB : affiche uniquement les producteurs visibles sur la carte
      && producteur.p_gps_latitude > extremitesMap._southWest.lat && producteur.p_gps_longitude > extremitesMap._southWest.lng)) //producteurs visibles sur la carte

    if (props.dConditions["bBio"]) { //LB : filtre bio
      lProducteurs = lProducteurs.filter(producteur => producteur.p_bio === "1" || producteur.articles.filter(article => article.a_bio ==="1").length > 0)
    }
    if (props.dConditions["bMarches"]) {  //LB : filtre présence sur marche 
      lProducteurs = lProducteurs.filter(producteur => producteur.marches.length > 0)
    }
    if (props.dConditions["bRetraitsSurPlace"]) { //LB : filtre retrait sur place, on verifie qu'il a au moins un point de retrait avec pr_surplace_ok = 1
      lProducteurs = lProducteurs.filter(producteur => producteur.p_retraits.filter(p_retrait => p_retrait.pr_surplace_ok === "1").length > 0)
    }
    if (props.dConditions["bLivraison"]) { //LB : filtre livraison
      lProducteurs = lProducteurs.filter(producteur => producteur.p_livraison_ok === "1")
    }
    if (props.dConditions["bcmdeDispo"]) { //PB : filtre si commande possible
      lProducteurs = lProducteurs.filter(producteur => producteur.p_cmde_ok === "1")
    }
    if (props.dConditions["bcueilletteDispo"]) { //PB : filtre si cueillette possible
      lProducteurs = lProducteurs.filter(producteur => producteur.p_cueillette_ok === "1")
    }
    if (props.selectedMag["bIsSelected"]) {  //LB : si on a selectionné un magasin, on ne garde que ceux qui ont une relation avec celui-ci
      lProducteurs = lProducteurs.filter(producteur => producteur.magasins.filter(magasin => magasin.idmagasin === props.selectedMag["labelSelectedMagasin"]).length > 0)
    }
    if (props.selectedMarche["bIsSelected"]) {  //LB : si on a selectionné un marché
      lProducteurs = lProducteurs.filter(producteur => producteur.marches.filter(marche => marche.idmarche === props.selectedMarche["labelSelectedMarche"]).length > 0)
    }
    if (props.selectedDrive["bIsSelected"]) {  //LB : si on a selectionné un drive
      lProducteurs = lProducteurs.filter(producteur => producteur.p_retraits.filter(retrait => retrait.idpointretrait === props.selectedDrive["labelSelectedDrive"]).length > 0)
    }
  }
  
  // pour chaque PRODUCTEUR
  for (let producteur of lProducteurs) {    
    if (producteur.p_gps_latitude) {
      for(let markerName of producteur.markers){  //LB : choix à faire : tous les produits ou que le principal (plus joli)
     // let markerName = producteur.markers[0]; //LB : si on arrive ici, c'est que le producteur respecte toutes les conditions
      if (!layers.hasOwnProperty(markerName)) {
        layers[markerName] = []
      }
      layers[markerName].push(
        [
          <Marker
            key={producteur.idproducteur}
            position={[producteur.p_gps_latitude, producteur.p_gps_longitude]}
            icon={dMarkerIcons[markerName]["icon"]}
          >
            <Popup>
              <a target="_blank" rel="noreferrer" href={"/producteur/" + producteur.p_url_servie}>
                <b>{producteur.label1}</b><br />
                <b>{producteur.short_address}</b><br />
                <div>
                  {producteur.produits_details.map(produitDetail => (
                   <img alt="" height="20px" key={produitDetail.idproduitdetail} src={process.env.PUBLIC_URL + "/svg/"+produitDetail.prd_image} /> 
                   ))
                  }
                  {/* {producteur.produits_details.forEach(element => {
                    // <img alt="" height="20px" key={element.idproduitdetail} src={process.env.PUBLIC_URL + "/svg/"+element.prd_image} /> 
                    <span>test</span>
                  })} */}
                </div>
                <span>En savoir plus...</span>
              </a>
            </Popup>
          </Marker>
        ], dMarkerIcons[markerName]["descriptifIcon"]
      );
      }
    }
    // MAGASINS
    if (!props.selectedDrive["bIsSelected"] && !props.selectedMarche["bIsSelected"] && props.bAfficherMag && zoomMap >= choixZoom_10) {  //Si aucun drive/marché n'est selectionné, on affiche les magasins
      for (let mag of producteur.magasins) {

        compteurKey += 1
        if (!lMagAffiches.includes(mag.idmagasin)) { //LB : Si le magasins n'a pas déja été traité
          let lat = mag.mg_gps_latitude ? mag.mg_gps_latitude : mag.ville_rel.v_gps_latitude  //LB : on lui attribue une position, ou alors celle de sa ville si besoin
          let long = mag.mg_gps_longitude ? mag.mg_gps_longitude : mag.ville_rel.v_gps_longitude

          lat = parseFloat(lat)
          long = parseFloat(long)

          if (lPositionsMagasinTraitees.includes(long)) { //S'il y a 2 magasins a la meme position
            long -= 0.015 //On ecarte tres legerement le 2ieme
            lat += 0.003
          } 
          
          lPositionsMagasinTraitees.push(long)

          if (lat < extremitesMap._northEast.lat && long < extremitesMap._northEast.lng //Si le magasin est visible sur la carte
            && lat > extremitesMap._southWest.lat && long > extremitesMap._southWest.lng) {

              if ((props.selectedMag["bIsSelected"] && mag.idmagasin === props.selectedMag["labelSelectedMagasin"]) || !props.selectedMag["bIsSelected"]) {  //LB : Si un magasins est selectionné, on affiche uniquement celui-ci
                const refMarkerMag = ref => { //Il s'agit d'une fonction qui est appelee a chaque interaction avec le magasin
                  if (ref && props.selectedMag["bIsSelected"] && mag.idmagasin === props.selectedMag["labelSelectedMagasin"] && !(ref._latlng.lat > extremitesMap._northEast.lat - distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lat < extremitesMap._southWest.lat + distanceMaxPopupBordure[zoomMap][1]
                    || ref._latlng.lng > extremitesMap._northEast.lng - distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lng < extremitesMap._southWest.lng + distanceMaxPopupBordure[zoomMap][0])) { //Si le magasin n'est pas trop proche des bordures
                    setTimeout(() => ref.openPopup(), 700) //Ouvre la popup du marker du magasin
                  }

                  if (ref && (ref._latlng.lat > extremitesMap._northEast.lat - distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lat < extremitesMap._southWest.lat + distanceMaxPopupBordure[zoomMap][1]
                    || ref._latlng.lng > extremitesMap._northEast.lng - distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lng < extremitesMap._southWest.lng + distanceMaxPopupBordure[zoomMap][0])) { //Si on s'approche trop de la bordure
                    ref.closePopup() //On ferme la popup
                  }
              }
              
              let lProducteursAssocies = props.lProducteurs.filter(producteur => producteur.magasins.filter(magasin => magasin.idmagasin === mag.idmagasin).length > 0) //recupere tous les producteurs associes au magasin
              .map(producteur => //et genere une div pour chacun d'eux. Il s'agit du contenu de la popup
                <div key={producteur.idproducteur}>
                  <Link target="_blank" style={{ color: "#0E0E0E" }} to={"/producteur/" + producteur.p_url_servie}>
                    <div className="d-flex justify-content-between">
                      <span className="mr-2" > ● {producteur.label1}</span>
                      {getSvgProd(producteur)}
                    </div>
                  </Link>
                </div>)

              layerGroups.push(  //LB : cree un marker pour chaque magasins respectant les conditions
                [
                  <Marker
                    ref={refMarkerMag}
                    key={compteurKey}
                    position={[lat, long]}
                    icon={dMarkerIcons["magasinIcon"]["icon"]}
                    eventHandlers={{
                      click: (e) => {
                        props.lMethodes["selectMag"](mag.idmagasin)
                      }
                    }}
                    keepInView={false}
                  >
                    <Popup
                     eventHandlers={{
                       click: (e) => {
                        //console.log(e.target)
                       }
                     }}
                    >
                      <b>{mag.mg_libelle} - {mag.ville_rel.v_nom_commune}</b><br/>
                      <hr />
                      {lProducteursAssocies}
                      <br/>
                    </Popup>
                  </Marker>
                ], ""
              );
              lMagAffiches.push(mag.idmagasin)
            }
          }
        }
      }
    }

    // MARCHES : système identique aux magasins
    if (!props.selectedDrive["bIsSelected"] && !props.selectedMag["bIsSelected"] && props.bAfficherMarches && zoomMap >= choixZoom_10) {  //Si aucun drive/magasin n'est selectionné, on affiche les marchés
      for (let marche of producteur.marches) {
        compteurKey += 1
        if (!lMarchesAffiche.includes(marche.idmarche)) { //LB : Si le marche n'a pas déja été traité
          let lat = marche.mr_gps_latitude ? marche.mr_gps_latitude : marche.ville_rel.v_gps_latitude  //LB : on lui attribue une position, ou alors celle de sa ville si besoin
          let long = marche.mr_gps_longitude ? marche.mr_gps_longitude : marche.ville_rel.v_gps_longitude

          lat = parseFloat(lat)
          long = parseFloat(long)
          if (lPositionsMarcheTraitees.includes(lat)) {
            lat += 0.015
            long += 0.003
          } 
          
          lPositionsMarcheTraitees.push(lat)

          if (lat < extremitesMap._northEast.lat && long < extremitesMap._northEast.lng
            && lat > extremitesMap._southWest.lat && long > extremitesMap._southWest.lng) {

            if ((props.selectedMarche["bIsSelected"] && marche.idmarche === props.selectedMarche["labelSelectedMarche"]) || !props.selectedMarche["bIsSelected"]) {  //On affiche uniquement celui-ci
              const refMarkerMarche = ref => {
                if (ref && props.selectedMarche["bIsSelected"] && marche.idmarche === props.selectedMarche["labelSelectedMarche"] && !(ref._latlng.lat > extremitesMap._northEast.lat-distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lat < extremitesMap._southWest.lat+distanceMaxPopupBordure[zoomMap][1]
                  || ref._latlng.lng > extremitesMap._northEast.lng-distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lng < extremitesMap._southWest.lng+distanceMaxPopupBordure[zoomMap][0])) {
                  ref.open = true
                  setTimeout(() => ref.openPopup(), 700)
                }
  
                if(ref && (ref._latlng.lat > extremitesMap._northEast.lat-distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lat < extremitesMap._southWest.lat+distanceMaxPopupBordure[zoomMap][1]
                  || ref._latlng.lng > extremitesMap._northEast.lng-distanceMaxPopupBordure[zoomMap][0]|| ref._latlng.lng < extremitesMap._southWest.lng+distanceMaxPopupBordure[zoomMap][0])){
                  ref.closePopup()
                }
              }

              let lProducteursAssocies = props.lProducteurs.filter(producteur => producteur.marches.filter(march => march.idmarche === marche.idmarche).length > 0)
              .map(producteur => 
                <div key={producteur.idproducteur}>
                  <Link target="_blank" style={{ color: "#0E0E0E" }} to={"/producteur/" + producteur.p_url_servie}>
                    <div className="d-flex justify-content-between">
                      <span className="mr-2" > ● {producteur.label1}</span>
                      {getSvgProd(producteur)}
                    </div>
                  </Link>
                </div>)

              let lJours = []
              let lHoraireMarche = []
              let lMois = []
              for (let [key, value] of Object.entries(marche.mr_jour)) {
                value === "1" && lJours.push(key)
              }
              for (let [key, value] of Object.entries(marche.mr_heure)) {
                value === "1" && lHoraireMarche.push(key === "aprem" ? " après midi" : " " + key)
              }
              for (let mois of marche.mr_liste_mois.split(",").map(mois => mois.trim()).sort(function (a, b) {
                return parseInt(a) - parseInt(b);
              })) {
                lMois.push(dMois[mois])
              }

              let libelleMarche = marche.mr_libelle.toLowerCase().includes(marche.ville_rel.v_nom_commune.toLowerCase().trim()) ?
              marche.mr_libelle.trim()
              :
              marche.mr_libelle.trim() + ", " + marche.ville_rel.v_nom_commune.trim()

              layerGroups.push(  //LB : cree un marker pour chaque marche respectant les conditions
                [
                  <Marker
                    ref={refMarkerMarche}
                    key={compteurKey}
                    position={[lat, long]}
                    icon={dMarkerIcons["marcheIcon"]["icon"]}
                    eventHandlers={{
                      click: (e) => {
                        props.lMethodes["selectMarche"](marche.idmarche)
                      }
                    }}
                  >
                    <Popup>
                      <div className="text-center font-weight-bold">{libelleMarche}{lJours.length > 0 && <span>, le {lJours.join(", ")} {lHoraireMarche.join(" ")}</span>}{(lMois.length > 0 && lMois[0] !== undefined) && <span>, en {lMois.join("/")}</span>}</div>
                      <hr/>
                      {lProducteursAssocies}
                      <br/>
                    </Popup>
                  </Marker>
                ], ""
              );
              lMarchesAffiche.push(marche.idmarche)

            }
          }
        }
      }
    }

    // DRIVES : système identique aux magasins
    if (!props.selectedMarche["bIsSelected"] && !props.selectedMag["bIsSelected"] && props.bAfficheDrives && zoomMap >= choixZoom_10) { //LB : affichage des drives, meme principe que pour les marches
      for (let drive of producteur.p_retraits) {
        if (drive.pr_drive_ok === "1" && drive.pr_ville !== null) {
          compteurKey += 1
          if (!lDrivesAffiches.includes(drive.idpointretrait)) {
            let lat = drive.pr_gps_latitude ? drive.pr_gps_latitude : drive.pr_ville.v_gps_latitude
            let long = drive.pr_gps_longitude ? drive.pr_gps_longitude : drive.pr_ville.v_gps_longitude

            lat = parseFloat(lat)
            long = parseFloat(long)
            if (lPositionsDrivesTraitees.includes(lat)) {
              lat += 0.015
              long += 0.003
            } 
            
            lPositionsDrivesTraitees.push(lat)

            if (lat < extremitesMap._northEast.lat && long < extremitesMap._northEast.lng
              && lat > extremitesMap._southWest.lat && long > extremitesMap._southWest.lng) {
              if ((props.selectedDrive["bIsSelected"] && props.selectedDrive["labelSelectedDrive"] === drive.idpointretrait) || !props.selectedDrive["bIsSelected"]) {
                const refMarkerDrive = ref => {
                  if (ref && props.selectedDrive["bIsSelected"] && !(ref._latlng.lat > extremitesMap._northEast.lat-distanceMaxPopupBordure[zoomMap][0] || ref._latlng.lat < extremitesMap._southWest.lat+distanceMaxPopupBordure[zoomMap][1]
                    || ref._latlng.lng > extremitesMap._northEast.lng-distanceMaxPopupBordure[zoomMap][0]|| ref._latlng.lng < extremitesMap._southWest.lng+distanceMaxPopupBordure[zoomMap][0])) {
                    ref.open = true
                    setTimeout(() => ref.openPopup(), 300)
                  }

                  if(ref && (ref._latlng.lat > extremitesMap._northEast.lat-0.30 || ref._latlng.lat < extremitesMap._southWest.lat+0.1
                    || ref._latlng.lng > extremitesMap._northEast.lng-0.3 || ref._latlng.lng < extremitesMap._southWest.lng+0.3)){
                    ref.closePopup()
                  }
                }

                let lProducteursAssocies = props.lProducteurs.filter(producteur => producteur.p_retraits.filter(p_retrait => p_retrait.idpointretrait === drive.idpointretrait).length > 0)
                
                let lDivProducteursAssocies = lProducteursAssocies.map(producteur => 
                  <div key={producteur.idproducteur}>
                    <Link target="_blank" style={{ color: "#0E0E0E" }} to={"/producteur/" + producteur.p_url_servie}>
                      <div className="d-flex justify-content-between">
                        <span className="mr-2" > ● {producteur.label1}</span>
                        {getSvgProd(producteur)}
                        </div>
                    </Link>
                  </div>)

                let bCommandeDisponible = lProducteursAssocies.filter(producteur => producteur.p_cmde_ok === "1").length > 0

                layerGroups.push(
                  [
                    <Marker
                      ref={refMarkerDrive}
                      key={compteurKey}
                      position={[lat, long]}
                      icon={dMarkerIcons["driveIcon"]["icon"]}
                      eventHandlers={{
                        click: (e) => {
                          props.lMethodes["selectDrive"](drive.idpointretrait)
                        }
                      }}
                    >
                      <Popup>
                        <div className="text-center font-weight-bold">{drive.pr_libelle} - {drive.pr_ville.v_nom_commune}</div>
                        <hr />
                        
                        {lDivProducteursAssocies}
                        {props.dUserInformations.bIsConnected ?
                          (bCommandeDisponible ?
                            <Link to={"/commandeDrive/" + drive.idpointretrait + "/" + props.dUserInformations.idUtilisateur} className="centerBlock mt-2" style={{ background: "" }} target="_blank" rel="noreferrer">
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" className="bi bi-basket mr-2" viewBox="0 0 16 16">
                                  <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                                </svg>
                              Commander
                              </button>
                            </Link>
                            :
                            <p>Commandes bientôt disponibles</p>
                          )
                          :
                          <Link to={"/login.html"} className="mt-2 centerBlock" onClick={(e) => formConnexion(e)}>Connectez vous pour commander</Link>
                        }
                        <br />
                      </Popup>
                    </Marker>
                  ], ""
                );
                lDrivesAffiches.push(drive.idpointretrait)
              }
            }
          }
        }
      }
    }
  }
    

  

  // if (!props.selectedMarche["bIsSelected"] && !props.selectedMag["bIsSelected"] && props.bAfficheDrives && zoomMap >= choixZoom_10) { //LB : affichage des drives, meme principe que pour les marches

  //   for (let drive of props.lDrives) {
  //     if (drive.ppri_drive_ok === "1") {
  //       if (!lDrivesAffiches.includes(drive.ppri_retrait.idpointretrait)) {
  //         let lat = drive.ppri_retrait.pr_gps_latitude ? drive.ppri_retrait.pr_gps_latitude : drive.ppri_retrait.pr_ville.v_gps_latitude
  //         let long = drive.ppri_retrait.pr_gps_longitude ? drive.ppri_retrait.pr_gps_longitude : drive.ppri_retrait.pr_ville.v_gps_longitude

  //         if (!(lat < extremitesMap._northEast.lat && long < extremitesMap._northEast.lng
  //           && lat > extremitesMap._southWest.lat && long > extremitesMap._southWest.lng)) {
  //           continue
  //         }

  //         if (props.selectedDrive["bIsSelected"]) {
  //           if (props.selectedDrive["labelSelectedDrive"] !== drive.ppri_retrait.idpointretrait) {
  //             continue
  //           }
  //         }

  //         const refMarkerDrive = ref => {
  //           if (ref && props.selectedDrive["bIsSelected"]) {
  //             setTimeout(() => ref.openPopup(), 100)
  //           }
  //         }

  //         layerGroups.push(
  //           [
  //             <Marker
  //               ref={refMarkerDrive}
  //               key={props.lDrives.indexOf(drive)}
  //               position={[lat, long]}
  //               icon={dMarkerIcons["driveIcon"]["icon"]}
  //               eventHandlers={{
  //                 click: (e) => {
  //                   props.lMethodes["selectDrive"](drive.ppri_retrait.idpointretrait)
  //                 }
  //               }}
  //             >
  //               <Popup>
  //                 <b>{drive.ppri_retrait.pr_libelle}</b>
  //               </Popup>
  //             </Marker>
  //           ], ""
  //         );
  //         lDrivesAffiches.push(drive.ppri_retrait.idpointretrait)

  //       }
  //     }
  //   }
  // }

  // CLUSTERS RONDS nombre de producteurs reunis au dezoom
  if (!props.bCreaClusters) { //LB : si le cluster n'a pas encore été créé, on le fait
    var markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: false, // indifferent car on passe a l affichage des markers eux-memes avant max zoom (cf disableClusteringAtZoom)
      showCoverageOnHover: true, // marque contour zone markers sous-jacents au survol
      zoomToBoundsOnClick: true,  //Pour zoomer quand on clique sur un rond
      disableClusteringAtZoom: choixZoom_10  //Pour désactiver l'affichage des clusters quand le zoom >= 10 (pour n'afficher que les markers)
    });

    markers.on('clusterclick', function (cluster) {  //Quand on clique sur un cluster de moins de 10 markers, on accentue le zoom si besoin (zoome en cascade jusq choixZoom_10)
      if (cluster.layer._childCount < 10 && zoomMap < choixZoom_10) { // layer barré deprecated : on garde car marche a peu pres sur Normandie par ex
        setTimeout(() => { map.setZoom(choixZoom_10); props.lMethodes["updateMap"]() }, 700)
      }
    });
  }

  // Populate cluster avec 1 unite par producteur (supprime les doublons de markers)
  let lPositionsTraitees = []
  for (let markerName in layers) {
    const lmarkerObjects = [];
    for (let layer of layers[markerName]) {
      if (typeof (layer) == "object") {
        lmarkerObjects.push(layer)
        if (!props.bCreaClusters) {
          for (let marker of lmarkerObjects) { //LB : créé et ajoute les markers dans le cluster. Il s'agit de doublons, qui sont invisibles (size --> 1, opacity --> 0)
          if(!lPositionsTraitees.includes(marker[0].props.position[0])){
              var marker_noirs_invisibles_a_ajouter = new L.Marker(marker[0].props.position, { icon: new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-black.png", iconSize: [1, 1] }), opacity: 0 });
              lPositionsTraitees.push(marker[0].props.position[0])
            }
          }
          markers.addLayer(marker_noirs_invisibles_a_ajouter)
        }
      }
    }

    if (zoomMap >= choixZoom_10) { //LB : Quand on arrete d'afficher les clusters, on affiche tous les markers colores des producteurs
      //let nbMarkers = props.bCreaClusters ? ' (' + layers[markerName].length + ')': "null"
      layerGroups.push(
        <LayersControl.Overlay checked={props.bCheck} name={window.screen.availWidth > 650 ? // name = ligne de legende associee a un produit avec marker couleur
          "   <img alt='' style='width:15px' src=" + process.env.PUBLIC_URL + "/ico/marker-" + markerName.split("I")[0] + ".png />      " + layers[markerName][1] 
          :
          "<img title='" + layers[markerName][1] + "' alt='' style='width:15px' src=" + process.env.PUBLIC_URL + "/ico/marker-" + markerName.split("I")[0] + ".png />"
        }
        key={markerName}>
          <FeatureGroup>{lmarkerObjects}</FeatureGroup> {/* liste des markers couleur de la layer */}
        </LayersControl.Overlay>
      );
    }
  }

  if (!props.bCreaClusters) //LB Si le cluster n'a pas été ajouté à la map, on le fait
  {
    if(props.lProducteurs.length > 0){
      map.addLayer(markers)
      props.lMethodes["getClusters"]()  //Puis on lance la méthode parent, qui met à jour son state pour indiquer que le cluster a été créé et ajouté à la map
    }
  }

  if (zoomMap >= choixZoom_10) {
    layerGroups.push(  //LB : marker croix centre map si zoom < zoomMap10
      [
        <Marker
          key={compteurKey}
          position={[centerMap.lat, centerMap.lng]}
          icon={new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/croix_position.svg", iconSize: [25, 25], shadowSize: [0, 0] })}
          keepInView={false}
        >
        </Marker>
      ], ""
    );
  }

  map.eachLayer(function(layer){
    //layer.on('mouseover', function(ev){ // quand on survole un marker // AN_20220907 suppr survol car fait planter la carte au bout d un moment
    //  ev.target.openPopup();
    //})
    layer.on('click', function(ev){ // quand on click sur un marker
      ev.target.openPopup();
    })
  })
  return layerGroups;
}

class MapAgrivLeaflet extends React.Component {
  constructor(props) {
    super(props)
    this.state = { //LB AN_DOC déclaration dans constructeur, puis this.setState modif ce dict pour les clés necess et React recharger les parties concernées
      bSelect: true,  //coche ou non les checkboxs
      lTextBoutton: ["Tout déselectionner", "Tout sélectionner"], //texte du bouton de selection/deselection
      bMapCharge: false,  //lance ou non la geolocalisation
      dConditions: { "bBio": false, "bMarches": false, "bRetraitsSurPlace": false, "bLivraison": false, "bcmdeDispo": false, "bcueilletteDispo":false }, //conditions d'affiche [bio, marches, retraits]
      bAfficherMarkers: false, //utile pour le chargement des markers // false a l init en attendant geolocalisation de l internaute
      bCreationCluster: false, //utile pour le chargement des clusters
      bAfficheDrives: false,
      bAfficheMarches: false,
      bAfficheMag: false,
      selectedDrive: { "bIsSelected": false, "labelSelectedDrive": "" },  //gere l'affichage des points de retrait et producteurs lorsque l'on en selectionne un drive
      selectedMarche: { "bIsSelected": false, "labelSelectedMarche": "" },  //Idem pour marches
      selectedMag: { "bIsSelected": false, "labelSelectedMagasin": "" } //Idem pour magasins
    }
    this.bDictMarkerIcons_populated = false
  }

  cocheDecocheMarkersCouleurLegende = () => {  //LB : onClick du bouton de deselection, change les checkbox et le texte du bouton
    this.setState({
      bSelect: !this.state.bSelect,
      bMapCharge: true //Permet de ne pas refaire la geolocalisation 
    });
    this.state.lTextBoutton.reverse(); // car affiche dans lib btn this.state.lTextBoutton[0]
  }

  selectDrives = () => {  //LB : gere l'affichage des drives
    this.setState({
      bAfficheDrives: !this.state.bAfficheDrives,
      bMapCharge: true //Permet de ne pas refaire la geolocalisation 
    });
  }

  selectMarches = () => {  //LB : gere l'affichage des drives
    this.setState({ 
      bAfficheMarches: !this.state.bAfficheMarches,
      bMapCharge: true //Permet de ne pas refaire la geolocalisation 
    });
  }

  selectMag = () => {  //LB : gere l'affichage des drives
    this.setState({
      bAfficheMag: !this.state.bAfficheMag,
      bMapCharge: true //Permet de ne pas refaire la geolocalisation 
    });
  }

  mapCharged = () => { 
    this.setState({ bAfficherMarkers: true, bMapCharge: true })
  }

  controleMap = () => { //LB : gestion d'erreur, pour éviter que l'utilisateur ne se retrouve sans rien sur la carte
    let selectedDrive = this.state.selectedDrive
    let selectedMarche = this.state.selectedMarche
    let selectedMag = this.state.selectedMag
    if (!document.getElementById("selectMarches").checked) {
      selectedMarche = { "bIsSelected": false, "labelSelectedMarche": "" }
    }
    if (!document.getElementById("selectMag").checked) {
      selectedMag = { "bIsSelected": false, "labelSelectedMarche": "" }
    }
    if (!document.getElementById("selectDrives").checked) {
      selectedDrive = { "bIsSelected": false, "labelSelectedMarche": "" }
    }
    this.setState({
      selectedDrive: selectedDrive,  
      selectedMarche: selectedMarche,
      selectedMag: selectedMag 
    })

  }

  getButton = () => { //LB : timer de 5s pour attendre la geolocalisation, puis affiche boutons et change le state pour afficher markers
    if (!this.state.bAfficherMarkers) {
      setTimeout(() => {
        if (!this.state.bAfficherMarkers) {
          this.setState({ bAfficherMarkers: true, bMapCharge: true })
        }
      }
        , 5000)
    }

    let classname = window.screen.availWidth > 1085 ? "form-check col-12" : "col-md-6 col-sm-6 col-12"
    return (//(!(this.state.selectedDrive["bIsSelected"] || this.state.selectedMag["bIsSelected"] || this.state.selectedMarche["bIsSelected"])) ?
      <>
        <div id="legendeBoutons" className=" mapCheckbox container-fluid card">
          <div className="row">
            <span style={{ position: "relative", right: "20px", bottom: "10px" }}>Afficher aussi&#160;:</span>
            <br />
          </div>
          <div className="row" onClick={() => this.controleMap()}>
            <div className={classname}>
              <input className="form-check-input" onClick={() => this.selectMarches()} type="checkbox" id="selectMarches" name="selectMarches" defaultChecked={this.state.bAfficheMarches} />
              <label className="form-check-label" htmlFor="selectMarches"><img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-marche.svg"} />  Marchés</label>
            </div>
            <div className={classname}>
              <input className="form-check-input" onClick={() => this.selectMag()} type="checkbox" id="selectMag" name="selectMag" defaultChecked={this.state.bAfficheMag} />
              <label className="form-check-label" htmlFor="selectMag"><img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-magasin.svg"} />  Magasins</label>
            </div>
            <div className={classname}>
              <input className="form-check-input" onClick={() => this.selectDrives()} type="checkbox" id="selectDrives" name="selectDrives" defaultChecked={this.state.bAfficheDrives} />
              <label className="form-check-label" htmlFor="selectDrives"><img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-drive.svg"} />  Retraits</label>
            </div>
          </div>
        </div>
      </>)
      // :
      // null
  }

  getConditions = () => { //LB : recupere le statut des checkbox de conditions, et ajuste le state
    let bBio = document.querySelector('#bio').checked
    let bMarches = document.querySelector('#marches').checked
    let bRetraitPlace = document.querySelector('#retrait').checked
    let bLivraison = document.querySelector('#livraison').checked
    let bcmdeDispo = document.querySelector('#cmdeDispo').checked
    let bcueilletteDispo = document.querySelector('#cueilletteDispo').checked
    this.setState({
      dConditions: { "bBio": bBio, "bMarches": bMarches, "bRetraitsSurPlace": bRetraitPlace, "bLivraison": bLivraison, "bcmdeDispo": bcmdeDispo, "bcueilletteDispo":bcueilletteDispo },
      bMapCharge: true
    })
  }

  updateMap = () => { //LB : Permet d'actualiser la page, avec setState AN_DOC recharge les markers des producteurs affichés et aussi croix noire centre carte
    this.setState({ 
      bMapCharge: true,
    })
  }

  getClusters = () => { //LB : Permet d'actualiser la page, avec setState
    this.setState({ bCreationCluster: true })
  }

  SelectDrive = (idDrive) => { //LB : pour selectionner uniquement les producteurs associés au point de retrait entré en parametres
    if (this.state.selectedDrive["labelSelectedDrive"] !== idDrive) {
      this.setState({
        selectedDrive: { "bIsSelected": true, "labelSelectedDrive": idDrive }
      })
    } else {
      this.setState({
        selectedDrive: { "bIsSelected": false, "labelSelectedDrive": "" }  //LB : ou pour reafficher tout
      })
    }
  }

  SelectMarche = (idMarche) => { //LB : pour selectionner uniquement les producteurs associés au marché entré en parametres
    if (this.state.selectedMarche["labelSelectedMarche"] !== idMarche) {
      this.setState({
        selectedMarche: { "bIsSelected": true, "labelSelectedMarche": idMarche }
      })
    } else {
      this.setState({
        selectedMarche: { "bIsSelected": false, "labelSelectedMarche": "" } //LB : ou pour reafficher tout
      })
    }
  }

  SelectMag = (idMag) => { //LB : pour selectionner uniquement les producteurs associés au marché entré en parametres
    if (this.state.selectedMag["labelSelectedMagasin"] !== idMag) {
      this.setState({
        selectedMag: { "bIsSelected": true, "labelSelectedMagasin": idMag }
      })
    } else {
      this.setState({
        selectedMag: { "bIsSelected": false, "labelSelectedMagasin": "" } //LB : ou pour reafficher tout
      })
    }
  }

  getCarousel = <div className="d-flex justify-content-center container">
    <div id="divTextMap" style={{ backgroundColor: "#c4e3bc", width: '610px', zIndex: 1001, marginTop: '10%' }} className='card text-white text-left rounded '>
      <button onClick={() => { document.querySelector('#divTextMap').style.display = "none" }} type="button" style={{ zIndex: 1500, position: 'absolute', top: '10px', right: '10px' }} className="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <Carousel slide={false} fade={false} interval={5000} controls={true}>
        <Carousel.Item>
          <div style={{ minHeight: '350px' }} className="card-body mb-3 mx-5">
            <h2 className="card-title font-Chewy font-weight-bold text-center">Agrivore évolue !</h2>
            <hr />
            <p className="lead text-center">
              Nouvelles fonctionnalités : <br /><br />
              Agrivore se renouvelle pour mieux répondre à vos critères de recherche ! <br />
              <img alt='' width='90px' height='97px' src={agrivoreIcon} />
            </p>
            
          </div>
          <br />
        </Carousel.Item>
        <Carousel.Item>
          <div style={{ minHeight: '350px' }} className="card-body mb-3 mx-4">
            <h2 className="card-title font-weight-bold text-center">De nouvelles icônes</h2>
            <hr />
            <p className="lead text-center">
              Visualisez :
              </p>
            <ul className="text-left ml-sm-5">
              <p className="lead text-left">
                <li>
                  <img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-marche.svg"} /> Marchés
                </li>
                <li>
                  <img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-magasin.svg"} /> Magasins
                </li>
                <li>
                  <img alt='' width='30px' src={process.env.PUBLIC_URL + "/ico/marker-drive.svg"} /> Points de retrait
                </li>
              </p>
            </ul>
            <p className="lead text-left">
              Cliquez sur l'un d'eux pour afficher les producteurs associés !
            </p>
          </div>
          <br />
        </Carousel.Item>
        <Carousel.Item>
          <div style={{ minHeight: '350px' }} className="card-body mb-3 ">
            <h2 className="card-title font-weight-bold text-center">Filtrez vos résultats</h2>
            <hr />
            <p className="lead text-center">
              Utilisez les filtres pour gagner du temps !<br />
              <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="45px" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
              </svg>
            </p>
            <div>
              <p className="lead">
                  Bio <img alt="" width='30px' src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"} />
                  <br/>
                  Présence sur les marchés 
                  <br/>
                  Retrait chez le producteur
                  <br/>
                  Et commande en ligne...
              </p>
            </div>
          </div>
          <br />
        </Carousel.Item>
        <Carousel.Item>
          <div style={{ minHeight: '350px' }} className="card-body mb-3 mx-4">
            <h2 className="card-title font-weight-bold text-center">Partagez sans modération</h2>
            <hr />
            <p className="lead text-center">
              Nous avons besoin de vous tous pour faire connaître Agrivore ! <br/> <br/>
              N'hésitez pas à partager notre site à vos producteurs locaux.
            </p>
            <div className="share-button sharer text-center"> {/*TODO VERIFIER QUE LES URLS DE PARTAGE FONCTIONNENT*/}
              <div className="social top center networks-5 row justify-content-center">
                <a target="_blank" rel="noreferrer" className="fbtn share facebook d-flex align-items-center justify-content-center"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://agrivore.fr/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>

                <a target="_blank" rel="noreferrer" className="fbtn share twitter d-flex align-items-center justify-content-center"
                  href="https://twitter.com/intent/tweet?text=Agrivore&amp;url=https://agrivore.fr/&amp;via=creativedevs">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                  </svg>
                </a>

                {/* <a className="fbtn share pinterest" href="http://pinterest.com/pin/create/button/?url=http://agrivore.fr/&amp;description='Découvrez Agrivore'&amp;media=image"><i className="fa fa-pinterest"></i></a> */}

                <a target="_blank" rel="noreferrer" className="fbtn share linkedin d-flex align-items-center justify-content-center"
                  href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://agrivore.fr/&amp;title=Agrivore&amp;source=http://agrivore.fr/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                  </svg>
                </a>
                {/* <a className="fbtn share vk"
                  href="https://vk.com/share.php?url=http://agrivore.fr/&amp;title=Agrivore&amp;description='Découvrez Agrivore'">
                  <i className="fa fa-vk"></i>
                </a> */}
              </div>
            </div>
            <br />

          </div>
          <br />
        </Carousel.Item>
      </Carousel>
    </div>
  </div>

  getDictMarkerIcons = () => {
    if (this.props.lProduits.length > 0 && !this.bDictMarkerIcons_populated) {
      this.props.lProduits.map(produit => //LB Pour chaque produit --> on insere un marker dans dMarkerIcons
        dMarkerIcons[produit.pr_marker] = {
          "icon": new LeafIcon({ iconUrl: process.env.PUBLIC_URL + "/ico/marker-" + produit.pr_marker.split("I")[0] + ".png" }),
          "descriptifIcon": produit.pr_libelle
        }
      )

      //   Object.entries(dMarkerIcons).map(([key, value]) => this.props.lProduits.find(article => article.pr_marker === key) ? 
      // dMarkerIcons[key]["descriptifIcon"] = this.props.lProduits.find(article => article.pr_marker === key).pr_libelle : "")
      this.bDictMarkerIcons_populated = true
    }
  }

  render() {
    this.getDictMarkerIcons()
    return (
      <>
        <div id="mapid" className=" col-md-12">
          <div className="d-flex mapCheckbox flex-sm-row flex-column mb-3" style={{ textAlign: "center" }} id="criteres">
            {this.props.dUserInformations.bIsAdmin &&
              <div className=" container-fluid mb-2">
                <button className="rounded" name="btnFrance" onClick={() => this.props.methodeChangeMap("svg")}><img width="15px" height='30px' src={process.env.PUBLIC_URL + "/ico/france_map.svg"} alt="" /></button>
              </div>
            } 
              {/*PB 04/07/2022  reorganisation*/}
            <label htmlFor="livraison" className="col-md-2 container-fluid"> 
              <input onClick={this.getConditions} className="align-middle" type="checkbox" id="livraison" name="livraison" style={{ marginRight: 5 + "px" }}></input>
              <span className="align-middle ml-1"><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/livraison.png"} />  <strong>Livraison</strong></span>
            </label>
            <label htmlFor="bio"  className="col-md-1 container-fluid">
              <input onClick={this.getConditions} className="align-middle"  type="checkbox" id="bio" name="bio" style={{ marginRight: 5 + "px" }}/>
              
              <span className="align-middle ml-1 "><img width='25px'  className='ml-1' src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"} alt=""/> <strong>BIO</strong> </span> 
            </label>


            <label htmlFor="marches" className="col-md-2 container-fluid">
              <input onClick={this.getConditions} className="align-middle" type="checkbox" id="marches" name="marches" style={{ marginRight: 5 + "px" }}/>
              <span className="align-middle ml-1 "><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/marche.png"} /> <strong>Présence sur marchés</strong> </span> 
            </label>

            <label htmlFor="retrait" className="col-md-3 container-fluid">
              <input onClick={this.getConditions} className="align-middle" type="checkbox" id="retrait" name="retrait" style={{ marginRight: 5 + "px" }}/>
              <span className="align-middle ml-1 "><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/voiture.png"} /> <strong>Retrait chez le producteur</strong> </span> 
            </label>

            <label htmlFor="cmdeDispo"  className="col-md-2 container-fluid">
              <input onClick={this.getConditions} className="align-middle" type="checkbox" id="cmdeDispo" name="cmdeDispo" style={{ marginRight: 5 + "px" }}/>
              <span className="align-middle ml-1 "><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/paniers.png"} /> <strong>Commande disponible</strong> </span> 
            </label>

            <label htmlFor="cueilletteDispo" className="col-md-2 container-fluid">
              <input onClick={this.getConditions} className="align-middle" type="checkbox" id="cueilletteDispo" name="cueilletteDispo" style={{ marginRight: 5 + "px" }}/>
              <span className="align-middle ml-1"><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/cueillette.png"} /> <strong>Libre cueillette</strong> </span> 
            </label>
          </div>

          <div id="map" onKeyDown={() => this.updateMap} >
            <MapContainer keyboard={true} minZoom={6} maxZoom={14} scrollWheelZoom={false} style={{ height: '800px' }} maxBounds={[[42,-6], [51.5, 9.5]] /*maxBounds = limites de la map [pointGaucheBas, pointDroiteHaut] -> user ne peut pas sortir de la France*/}>
              
              {/*this.getCarousel*/}
              {(!this.state.bAfficherMarkers || this.props.lProducteurs.length === 0) && <div className="p-5 d-flex justify-content-center"><h1 id="textChargement" style={{ backgroundColor: 'rgb(240,240,240)' }} className=" p-2 rounded">Chargement de la carte...</h1></div>}
                
              {/* LocationMarker Composant enfant defini ci dessus (a inclure dans MapContainer)*/}
              <LocationMarker villeRecherche={this.props.villeRecherche} methodeGetCenterMap={this.props.getCenterMap} methodeMap={this.mapCharged} updateMap={this.updateMap} bMapCharge={this.state.bMapCharge} /> {/*geolocalisation zoom*/}

              <LayersControl position="topright">

                <LayersControl.BaseLayer checked name="Affichage simple">
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer name="Carte routière">
                  <TileLayer
                    attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,
                  <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>,
                  Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw"
                  />
                </LayersControl.BaseLayer>
                {/* ListLayers Composant enfant defini ci dessus (a inclure dans MapContainer) */}
                {(this.state.bAfficherMarkers && Object.entries(dMarkerIcons).length > dictMarkerIcons_initialLength) &&
                  <ListLayers
                    selectedMag={this.state.selectedMag} selectedMarche={this.state.selectedMarche} selectedDrive={this.state.selectedDrive}
                    bAfficherMag={this.state.bAfficheMag} bAfficherMarches={this.state.bAfficheMarches} bAfficheDrives={this.state.bAfficheDrives}
                    bCreaClusters={this.state.bCreationCluster}
                    lMethodes={{ "updateMap": this.updateMap, "getClusters": this.getClusters, "selectDrive": this.SelectDrive, "selectMarche": this.SelectMarche, "selectMag": this.SelectMag }}
                    bCheck={this.state.bSelect} dConditions={this.state.dConditions} lProducteurs={this.props.lProducteurs}
                    dUserInformations={this.props.dUserInformations} methUtilisateurConnecte={this.props.methUtilisateurConnecte}
                  />
                }
                {/* {  AN_DOC code ci-dessus:
                  (condition1 && condition2) && action (= afficher composant ListLayers ici)
                } */}

              </LayersControl>
              <button id="deselection" name="btnDeselection" className="btn btn-light" onClick={() => this.cocheDecocheMarkersCouleurLegende()}>{this.state.lTextBoutton[0]}</button>
              <div style={{position:"absolute", top:"80px", zIndex:1000}}>
                {/* MapDezoom Composant enfant defini ci dessus (a inclure dans MapContainer)  qui renvoie btn MapDezoom btnFrance */}
                <MapDezoom/>
              </div>
            </MapContainer>
            {this.getButton()}


          </div>
        </div>

      </>
    )
  }
}
export default MapAgrivLeaflet;
