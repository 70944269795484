import { Link } from 'react-router-dom';
import React from 'react'
import ReactDOM from 'react-dom';

import Popup from './Popup';
import Login from './Login';

//import entete from './images/entete.png';
import AgrivoreTxt from './images/Agrivore_NavLogo.png';


class Menu extends React.Component {

  changeMenu = () => { //Quand on clique sur les 3 barres, on change les classes des elements pour leur attribuer un style different
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  formConnexion = e => {
    e.preventDefault()  //desactive le comportement par defaut du Link pour rester sur la meme page
    ReactDOM.render(<Login dUserInformations={this.props.dUserInformations} bPopup={true} methUtilisateurConnecte={this.props.methUtilisateurConnecte}/>,  document.querySelector("#contenuPopup"))
    // document.querySelector("#contenuPopup").innerHTML = <Login/>
    document.querySelector("#idPopup").style.display = "block"
  }

  render() {
    window.onresize = () => { this.setState({ largeurEcran: window.screen.availWidth }) };
    return (

      <header className='d-flex flex-sm-row flex-column mb-1'>
        <Popup/>
        { window.screen.availWidth >= 768 &&
          <div className='align-middle col-sm-2 p-1 m-1' >
            <Link to="/index">
            <img className='align-middle ml-4' alt="" src={AgrivoreTxt} height="65px"></img>
           </Link>
          </div>
        }
       
        
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" /> correspond aux 3 barres */}

        <div className="topnav col-sm-10 container-fluid" id="myTopnav">
        { window.screen.availWidth >= 768 ?
          <Link className="nav-link pt-4" to="/index">
            Agrivore 1.5 
          </Link>
        :
          <Link className="nav-link" to="/index">
             <img className='align-middle' alt="" src={AgrivoreTxt} height="30px"></img>
          </Link>
        }
          <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/charte.html">Charte</Link>
          <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/recrutement.html">Nous recrutons</Link>
          <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/contact">Contact</Link>
          <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/explications">FAQ</Link>
          {this.props.dUserInformations.bIsConnected ?
            <>
              {this.props.dUserInformations.bIsConsumer &&
                <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/gestionCommandes">Vos commandes</Link>
              }
              {this.props.dUserInformations.bIsProducteur &&
                <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/producteur-articles">Vos articles</Link>
              }
              <Link className="nav-link pt-4" to={"/infoCLient/"+this.props.dUserInformations.idUtilisateur}>Informations personnelles</Link>

              <Link onClick={() => this.props.methUtilisateurDeconnecte()} className="nav-link pt-4" to="#">{this.props.dUserInformations.nameUtilisateur} - Déconnexion</Link>
              {/*onClick={() => this.props.methUtilisateurDeconnecte()}*/}

              {(this.props.dUserInformations.bIsAdmin && process.env.NODE_ENV === "development") &&
                <Link target="_blank" rel="noopener noreferrer" style={{ backgroundColor: "#415b2f" }} to="/admin_agrivore" className="nav-link pt-4">Administration</Link>
              }
            </>
            :
            <>
              <Link onClick={(e) => this.formConnexion(e)} className="nav-link pt-4" to="/">Connexion</Link>
              <Link target="_blank" rel="noopener noreferrer" className="nav-link pt-4" to="/inscriptionUtilisateur">Inscription Client</Link>
            </>
          }
          <a className="icon" onClick={() => this.changeMenu()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </a>
        </div>
      </header>
    );
  }
}
export default Menu;
