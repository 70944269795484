import { Button, Collapse } from 'react-bootstrap';
import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';

function QuestionReponse(props){
    const [open, setOpen] = useState(false);

    return(
        <>
            <Button
                variant="outline-secondary"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                >
            <h6>{props.title}</h6>
            </Button>
            <Collapse in={open}>
                <div id="example-collapse-text" className="mt-3">
                    {props.description}
                </div>
            </Collapse>
        </>
    )
}

function ExplicationSite(){
    let lExplications = [
        { title: "Qu'est-ce qu'Agrivore ?", description: <div>Notre site souhaite rapprocher les consommateurs des producteurs<br/>Pour cela, parcourez notre carte afin de trouver celui qui vous correspond, passez des commandes, et savourez vos produits...</div>},
        { title: "Comment s'inscrire ?", description: <div><img src={process.env.PUBLIC_URL + "/img_documentation/comment_s_inscrire.png"}/></div>},
        { title: "Comment utiliser la carte ?", description: <div><img src={process.env.PUBLIC_URL + "/img_documentation/utiliser_carte.png"}/></div>},
        { title: "Comment passer commande ?", description: <div><img src={process.env.PUBLIC_URL + "/img_documentation/passer_commander.png"}/></div>},
        { title: "Producteur : comment apparaître sur le site", description: <div>Consultez la page 'Charte', accessible depuis le menu.<br/>Les quelques démarches à effectuer y sont expliquées. Quelques minutes suffisent pour obtenir votre fiche producteur.</div>},
    ]
    return(
        <div className="container-fluid">
            <div className="card">
                <div className="p-3">
                    {lExplications.map(explication => 
                        <div key={explication.title} className="mt-3">
                            <QuestionReponse title={explication.title} description={explication.description}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}


export default withRouter(ExplicationSite)