import axios from "axios"

let urlServeur = process.env.REACT_APP_BACKEND_URL // AN_20210624 modif pour sOvh20 et s20
//if (process.env.NODE_ENV === "development") { //Laisser cette portion de code dans index.js
//    urlServeur = "http://localhost:6543/api" // TODO PROD : remplace localStorage.setItem mais voir ct faire sur server prod
//} else if (process.env.NODE_ENV === "production") {
//    urlServeur = "ipServeur/api" //TODO CHANGER IP SERVEUR
//}

export default axios.create({ // AN_DOC objet axios recup ailleurs via import API from './api' (puis API.get(url) etc pour accès aux meth axios)
    baseURL: urlServeur // AN_DOC baseURL sera insérée avant url relative renseignée pour toutes les req axios
})

