import React, { useState } from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import API from './api';
import { Button, Collapse } from 'react-bootstrap';

function ListeCommandes(props){
    const [open, setOpen] = useState(true);

    return(
        <>
            {/* <Button
                variant="outline-secondary"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >
                <h6>{props.title}</h6>
            </Button> */}
            <Collapse in={open}>
                <div id="example-collapse-text" className="mt-3">
                    {props.description}
                </div>
            </Collapse>
        </>
    )
}

class GestionCommandesClient extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            lCommandesConsommateur: [],
            lCommandesProducteur: [],
            afficheCmdeEnCours: true, // PB_2022-06-22 variables pour gerer l'affichage des differentes cmde (en cours, anciennes, etc)
            afficheCmdProd: false,
            noPage: 1, // PB_2022-06-22 noPage courante 
            nbCmdeParPage: 4,
            noPageMax: 10
        }
        localStorage.setItem("lastUrlVisited", window.location.pathname)
    }


    handleClick = (choix) => { // PB_2022-06-22 fct quand on clique sur un boutton pour choisir les cmdes à afficher 

        switch (choix) {

            case 1:
                this.setState({ // Commandes en cours client 
                    afficheCmdeEnCours: true,
                    afficheCmdProd: false,
                    noPage: 1,
                    noPageMax: Math.ceil(this.state.lDivCommandesAVenirClie.length / this.state.nbCmdeParPage)
                });
                break;
            case 2:
                this.setState({ // Anciennes commandes client
                    afficheCmdeEnCours: false,
                    afficheCmdProd: false,
                    noPage: 1,
                    noPageMax: Math.ceil(this.state.lDivCommandesPasseesClie.length / this.state.nbCmdeParPage)
                });
                break

            case 3: // commandes en cours producteur (à préparer)

                if (typeof this.state.lDivCommandesAVenirProd !== 'undefined')
                    this.setState({
                        afficheCmdeEnCours: true,
                        afficheCmdProd: true,
                        noPage: 1,
                        noPageMax: Math.ceil(this.state.lDivCommandesAVenirProd.length / this.state.nbCmdeParPage)
                    });
                else
                    this.setState({
                        afficheCmdeEnCours: true,
                        afficheCmdProd: true,
                        noPage: 1,
                        noPageMax: 1

                    });
                break
            case 4: // commandes cloturé producteur 
                if (typeof this.state.lDivCommandesPasseesProd !== 'undefined')
                    this.setState({
                        afficheCmdeEnCours: false,
                        afficheCmdProd: true,
                        noPage: 1,
                        noPageMax: Math.ceil(this.state.lDivCommandesPasseesProd.length / this.state.nbCmdeParPage)

                    });
                else
                    this.setState({
                        afficheCmdeEnCours: false,
                        afficheCmdProd: true,
                        noPage: 1,
                        noPageMax: 1

                    });
                break

        }
    }

    handleNavClick = (value) => { // PB_2022-06-22 fonction pour maj page courante 
        this.setState({
            noPage: this.state.noPage + value
        })

    }



    getDivCommande = (commande, typeConsommateurProducteur) => {
        let typeCommandeProducteurDrive = commande[1][0].commande.v_groupe_idproducteur === null ? "producteur" : "drive"

        let prixTotal = 0

        commande[1].forEach(commande => prixTotal += parseFloat(commande.commande.v_totalttc))

        // if (commande[1].length > 1) { //Si il y a plus d'une commande dans le lot
        //     prixTotal = commande[1].reduce((a, b) => parseFloat(a.commande.v_totalttc) + parseFloat(b.commande.v_totalttc)) //on utilise reduce() pour parcourir les commandes, et additioner leur prix pour obtenir le prix total du lot
        // } else if (commande[1].length === 1) {
        //     prixTotal = commande[1][0].commande.v_totalttc
        // }
        let dateCommandeSplit = commande[1][0].commande.v_datecommande.split("-") //commande[1] --> liste des commandes du lot ; commande[1][0] --> premiere commande du lot
        let dateRetraitSplit = commande[1][0].commande.v_dateretrait.split("-") //On travaille avec la 1ere commande du lot --> le point/date de retrait de de commande d'un lot est la meme pour tous les articles
        let lieuRetrait = commande[1][0].pointretrait.pr_libelle
        let labelProducteur = commande[1][0].producteur.label1
        let urlRedirectionCommande = typeConsommateurProducteur === "consommateur" ? "/visualisationCommande/consommateur/" : "/visualisationCommande/producteur/"
        let divCommande = <div key={commande[0]} className="rounded p-3 col-11 my-2" style={{ border: "solid 1px #8E8E8E" }}>
            <div className="d-flex bd-highlight mb-3">
                <h5 className="font-weight-bold mr-auto p-2 bd-highlight">Commande n° : {commande[0]}</h5>
                <div className="p-2 bd-highlight">
                    <h5 className="font-weight-bold mb-0">Date de commande : {dateCommandeSplit[2].split(" ")[0] + "/" + dateCommandeSplit[1] + "/" + dateCommandeSplit[0]}</h5>
                </div>
            </div>

            <div className="row px-3">

                <div className="ml-md-3 mt-md-0 mt-2 col-md-3 col-6">
                    <h6 className="font-weight-bold mb-0">
                        Total
                    </h6>
                    <span>
                        {(parseFloat(prixTotal).toFixed(2) + " €").replace(".", ",")}
                    </span>
                </div>
                <div className="ml-md-3 mt-md-0 mt-2 col-md-3 col-6">
                    <h6 className="font-weight-bold mb-0">
                        Paiement
                    </h6>
                    <span>
                        Sur place
                    </span>
                </div>
            </div>

            <div className="row px-3 mt-4">
                <div className="col-lg-4 col-12 ">
                    <h6 className="font-weight-bold">
                        Retrait de la commande
                    </h6>
                    <span>
                        le : <span className="font-weight-bold">{dateRetraitSplit[2].split(" ")[0] + "/" + dateRetraitSplit[1] + "/" + dateRetraitSplit[0] + " - " + dateRetraitSplit[2].split(" ")[1].slice(0, 5)}</span> <br />
                        lieu : <span className="font-weight-bold"> {typeCommandeProducteurDrive === "producteur" && labelProducteur} {typeCommandeProducteurDrive === "producteur" && " - "}{lieuRetrait} <br />
                            {typeConsommateurProducteur === "producteur" &&
                                "Consommateur : " + commande[1][0].consommateur.c_nom
                            }</span>
                    </span>
                </div>
                <div className="row col-lg-8 col-12 mt-lg-0 mt-3">
                    {commande[1].slice(0, 3).map(lot => //on affiche la photo / nom / quantite / prix total des 3 premiers articles du lot
                        <div key={lot.article.idarticle}>
                            <div className="col-12 mb-1">
                                <img className="centerBlock" style={{ width: "50px" }} src={process.env.PUBLIC_URL + "/" + lot.article.a_img2}></img>
                            </div>
                            <div className="col-12 text-center">
                                <p className="font-weight-bold mb-0">
                                    {lot.article.a_libelle + "  "} 
                                    {lot.article.a_bio === "1" && /* affiche bio à coté de l'article si bio  */
                                        <img alt="Bio" height="20px" src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}
                                        />// : "centerBlock mb-2"
                                    }
                                </p>

                                <span style={{ fontSize: "15px" }}>
                                    Quantité : {lot.commande.v_quantite.split(".")[0]}<br />
                                    Prix : {lot.commande.v_totalttc.replace(".", ",").slice(0, lot.commande.v_totalttc.length - 2)}€
                                </span>
                            </div>
                        </div>)}
                    {commande[1].length > 3 && //Si il y a + de 3 articles, on rajoute une fleche de redirection vers la page dediee a la commande
                        <Link className="linkPasBlanc" to={urlRedirectionCommande + commande[0]}>
                            <svg style={{ position: "absolute", top: "40%", right: '-5px' }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-arrow-right-square" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                            </svg>
                        </Link>
                    }
                </div>
            </div>

            <Link to={urlRedirectionCommande + commande[0]}><button className="btn btn-outline-dark centerBlock mt-3">Visualiser la commande</button></Link>
        </div>

        return divCommande
    }

    getCommandesConsommateur = () => {
        /* On fait ce traitement lors de la reception des liste de commande (api.get) pour eviter de la refaire a chaque MAJ */
        // let lCommandesGroupBy = this.state.lCommandesConsommateur.reduce(function (r, a) {  //LB : permet de regrouper les commandes par boncommande
        //     r[a.commande.v_boncommande] = r[a.commande.v_boncommande] || [];
        //     r[a.commande.v_boncommande].push(a);
        //     return r;
        // }, Object.create(null));

        // //On obtient : lCommandesGroupBy = {bonCommande1: [lCommandes1], bonCommande2: [lCommandes2], etc...}
        // let lDivCommandesAVenir = []
        // let lDivCommandesPassees = []


        // Object.entries(lCommandesGroupBy).reverse().forEach(commande => { //On parcourt tous les regroupements par boncommande. La variable commande prend la forme : [bonCommande, [lCommandes]]
        //     let divCommande = this.getDivCommande(commande, "consommateur")
        //     if (new Date() - new Date(commande[1][0].commande.v_dateretrait) < 0) {
        //         lDivCommandesAVenir.push(divCommande)
        //     } else {
        //         lDivCommandesPassees.push(divCommande)
        //     }
        // }
        // )

        let lDivCommandesAVenir = []
        let lDivCommandesPassees = []
        if (typeof this.state.lDivCommandesAVenirClie !== "undefined") lDivCommandesAVenir = this.state.lDivCommandesAVenirClie.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage) //PB_2022-06-22 getsion du nb de cmdes affiché par pages 
        if (typeof this.state.lDivCommandesPasseesClie !== "undefined") lDivCommandesPassees = this.state.lDivCommandesPasseesClie.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage)


        return (
            <>
                {this.state.afficheCmdeEnCours ?

                    <div>
                        {/* <h4 className="font-weight-bold">Commandes en cours :</h4> */}
                        <div className="row justify-content-center">
                            {lDivCommandesAVenir.length > 0 ? lDivCommandesAVenir : <h6 className="col-12">Aucune commande</h6>}
                        </div>
                    </div>
                    :
                    <div className="mt-3">
                        {/* <h4 className="font-weight-bold">Anciennes commandes (date de retrait dépassée) :</h4> */}
                        <div className="row justify-content-center">
                            {lDivCommandesPassees.length > 0 ? lDivCommandesPassees : <h6 className="col-12">Aucune commande</h6>}
                        </div>
                    </div>
                }
            </>
        )
    }

    getCommandesProducteur = () => {
        /* On fait ce traitement lors de la reception des liste de commande (api.get) pour eviter de la refaire a chaque MAJ */
        // let lCommandesGroupBy = this.state.lCommandesProducteur.reduce(function (r, a) {  //LB : permet de regrouper les commandes par boncommande
        //     r[a.commande.v_boncommande] = r[a.commande.v_boncommande] || [];
        //     r[a.commande.v_boncommande].push(a);
        //     return r;
        // }, Object.create(null));

        // //On obtient : lCommandesGroupBy = {bonCommande1: [lCommandes1], bonCommande2: [lCommandes2], etc...}

        // let lDivCommandesAVenir = []
        // let lDivCommandesPassees = []

        // Object.entries(lCommandesGroupBy).reverse().forEach(commande => { //On parcourt tous les regroupements par boncommande. La variable commande prend la forme : [bonCommande, [lCommandes]]
        //     let divCommande = this.getDivCommande(commande, "producteur")
        //     if (new Date() - new Date(commande[1][0].commande.v_dateretrait) < 0) {
        //         lDivCommandesAVenir.push(divCommande)
        //     } else {
        //         lDivCommandesPassees.push(divCommande)
        //     }
        // }
        // )
        // // if (this.state.afficheCmdeEnCours) this.setState({ noPage: parseInt(lDivCommandesAVenir.length / this.state.nbCmdeParPage) })
        // // else this.setState({ noPage: parseInt(lDivCommandesPassees.length / this.state.nbCmdeParPage) })
        // lDivCommandesAVenir.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage)
        // lDivCommandesPassees.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage)


        let lDivCommandesAVenir = []
        let lDivCommandesPassees = []
        if (typeof this.state.lDivCommandesAVenirClie !== "undefined") lDivCommandesAVenir = this.state.lDivCommandesAVenirProd.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage) //PB_2022-06-22 getsion du nb de cmdes affiché par pages 
        if (typeof this.state.lDivCommandesPasseesClie !== "undefined") lDivCommandesPassees = this.state.lDivCommandesPasseesProd.slice((this.state.noPage - 1) * this.state.nbCmdeParPage, (this.state.noPage) * this.state.nbCmdeParPage)


        return (
            <>
                {this.state.afficheCmdeEnCours ?
                    <div>
                        {/* <h4 className="font-weight-bold">Commandes en cours :</h4> */}
                        <div className="row justify-content-center">
                            {lDivCommandesAVenir.length > 0 ? lDivCommandesAVenir : <h6 className="col-12">Aucune commande</h6>}
                        </div>
                    </div>
                    :
                    <div className="mt-3">
                        {/* <h4 className="font-weight-bold">Anciennes commandes (date de retrait dépassée) :</h4> */}
                        <div className="row justify-content-center">
                            {lDivCommandesPassees.length > 0 ? lDivCommandesPassees : <h6 className="col-12">Aucune commande</h6>}
                        </div>
                    </div>
                }
            </>
        )
    }

    componentDidMount = () => {
        //requete Axios pour récupérer lCommandes
        let idTiersUtilisateur = this.props.dUserInformations.idTiersUtilisateur

        //PB 2022-06-22 on fait le groupBY des cmde ici + trie si cmde en cours ou passé 
        API.get("/commande/producteur/all/utilisateur/" + idTiersUtilisateur + "?typeCommande=consommateur").then(response => { //On traite la les commandes ici pour eviter de les traité a chque modif

            let lCommandesConsommateur = response.data.commandes.sort((a, b) =>
                a.producteur.idproducteur > b.producteur.idproducteur ? 1 : -1)

            let lCommandesGroupBy = lCommandesConsommateur.reduce(function (r, a) {  //LB : permet de regrouper les commandes par boncommande
                r[a.commande.v_boncommande] = r[a.commande.v_boncommande] || [];
                r[a.commande.v_boncommande].push(a);
                return r;
            }, Object.create(null));

            //On obtient : lCommandesGroupBy = {bonCommande1: [lCommandes1], bonCommande2: [lCommandes2], etc...}
            let lDivCommandesAVenir = []
            let lDivCommandesPassees = []


            Object.entries(lCommandesGroupBy).reverse().forEach(commande => { //On parcourt tous les regroupements par boncommande. La variable commande prend la forme : [bonCommande, [lCommandes]]
                let divCommande = this.getDivCommande(commande, "consommateur")
                if (new Date() - new Date(commande[1][0].commande.v_dateretrait) < 0) {
                    lDivCommandesAVenir.push(divCommande)
                } else {
                    lDivCommandesPassees.push(divCommande)
                }
            }
            )
            this.setState({
                lDivCommandesAVenirClie: lDivCommandesAVenir,
                lDivCommandesPasseesClie: lDivCommandesPassees,
                noPageMax: Math.ceil(lDivCommandesAVenir.length / this.state.nbCmdeParPage) // Pour avoir la pagination qui marche du premier coup
            })

        }
            // this.setState({
            //     lCommandesConsommateur: response.data.commandes.sort((a, b) =>
            //         a.producteur.idproducteur > b.producteur.idproducteur ? 1 : -1)
            // })

        )

        if (this.props.dUserInformations.bIsProducteur) {
            let idProducteur = this.props.dUserInformations.idProducteur
            API.get("/commande/producteur/" + idProducteur + "/utilisateur/all?typeCommande=producteur").then(response => {
                let lCommandesProducteur = response.data.commandes.sort((a, b) =>
                    a.producteur.idproducteur > b.producteur.idproducteur ? 1 : -1)
                //PB 2022-06-22 on fait le groupBY des cmde ici + trie si cmde en cours ou passé 

                let lCommandesGroupBy = this.state.lCommandesProducteur.reduce(function (r, a) {  //LB : permet de regrouper les commandes par boncommande
                    r[a.commande.v_boncommande] = r[a.commande.v_boncommande] || [];
                    r[a.commande.v_boncommande].push(a);
                    return r;
                }, Object.create(null));

                //On obtient : lCommandesGroupBy = {bonCommande1: [lCommandes1], bonCommande2: [lCommandes2], etc...}

                let lDivCommandesAVenir = []
                let lDivCommandesPassees = []

                Object.entries(lCommandesGroupBy).reverse().forEach(commande => { //On parcourt tous les regroupements par boncommande. La variable commande prend la forme : [bonCommande, [lCommandes]]
                    let divCommande = this.getDivCommande(commande, "producteur")
                    if (new Date() - new Date(commande[1][0].commande.v_dateretrait) < 0) {
                        lDivCommandesAVenir.push(divCommande)
                    } else {
                        lDivCommandesPassees.push(divCommande)
                    }
                }
                )
                this.setState({
                    lDivCommandesAVenirProd: lDivCommandesAVenir,
                    lDivCommandesPasseesProd: lDivCommandesPassees
                })

            })
            // this.setState({
            //     lCommandesProducteur: response.data.commandes.sort((a, b) =>
            //         a.producteur.idproducteur > b.producteur.idproducteur ? 1 : -1)
            // }))
        }
    }

    render() {
        let styleBouttonSelected = this.props.dUserInformations.bIsProducteur ? 'btn btn-success col-3' : 'btn btn-success col-6';
        let styleBouttonUnselected = this.props.dUserInformations.bIsProducteur ? 'btn btn-outline-success col-3' : 'btn btn-outline-success col-6'
        return (
            <div className="container">
                {this.props.dUserInformations.bIsConnected ?
                    (this.props.dUserInformations.idProducteur === null ? // si c'est un client, que 2 bouttons
                        <div className="card p-3">
                            <h3 className="text-center">Bonjour {this.props.dUserInformations.nameUtilisateur}. Vos commandes :</h3>
                            <div>
                                <button className={this.state.afficheCmdeEnCours ? 'btn btn-success' : 'btn btn-outline-success'} onClick={() => this.handleClick()}>Commandes en cours</button>
                                <button className={this.state.afficheCmdeEnCours ? 'btn btn-outline-success' : 'btn btn-success'} onClick={() => this.handleClick()}>Anciennes commandes </button>

                            </div>
                            {/* {this.getCommandesProducteur()} */}
                            <ListeCommandes title={<h4>Commande passées :</h4>} description={this.getCommandesConsommateur()} />
                        </div>
                        : // si c'est un producteur, 4 bouttons
                        <div className="card p-3">
                            <h3 className="text-center">Bonjour {this.props.dUserInformations.nameUtilisateur}. Vos commandes :</h3>
                            {this.props.dUserInformations.bIsProducteur &&
                                <div className="mb-3">
                                </div>
                            }
                            <div className='container'>
                                {this.props.dUserInformations.bIsProducteur ? // si c'est un client, 4 bouttons

                                    <div className='row justify-content-center'> 
                                        <button className={this.state.afficheCmdeEnCours && !this.state.afficheCmdProd ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(1)}>Commandes en cours</button>
                                        <button className={!this.state.afficheCmdeEnCours && !this.state.afficheCmdProd ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(2)}>Historique des commandes</button>
                                        <button className={this.state.afficheCmdeEnCours && this.state.afficheCmdProd ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(3)}>Commandes reçues</button>
                                        <button className={!this.state.afficheCmdeEnCours && this.state.afficheCmdProd ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(4)}>Commandes réalisées</button>

                                    </div>
                                    :// si c'est un producteur, que 2 bouttons
                                    <div className='row justify-content-center'>
                                        <button className={this.state.afficheCmdeEnCours ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(1)}>Commandes en cours</button>
                                        <button className={!this.state.afficheCmdeEnCours ? styleBouttonSelected : styleBouttonUnselected} onClick={() => this.handleClick(2)}>Historique des commandes</button>
                                    </div>


                                }
                                {this.state.afficheCmdProd && this.props.dUserInformations.bIsProducteur ?
                                    <ListeCommandes title={<h4>Commande reçues :</h4>} description={this.getCommandesProducteur()} />
                                    :
                                    <ListeCommandes title={<h4>Commande passées :</h4>} description={this.getCommandesConsommateur()} />

                                }
                            </div>
                            <nav> {/* Pagination */}
                                <ul className="pagination justify-content-center">
                                    {/* 
                                    {this.state.noPage > 1 ?
                                        <li className="page-item"><button onClick={() => this.handleNavClick(-1)} className="page-link" href="#">&laquo;</button></li>
                                        :
                                        <></>

                                    } */}

                                    {this.state.noPage > 1 ?
                                        <li className="page-item"><button className="page-link" onClick={() => this.handleNavClick(-1)} href="#">{this.state.noPage - 1}</button></li>
                                        :
                                        <></>

                                    }
                                    <li className="page-item active"><button className="page-link" href="#">{this.state.noPage}</button></li>

                                    {this.state.noPage < this.state.noPageMax ?
                                        <li className="page-item"><button className="page-link" onClick={() => this.handleNavClick(1)} href="#">{this.state.noPage + 1}</button></li>
                                        :
                                        <> </>
                                    }
                                    {/* {this.state.noPage < this.state.noPageMax ?
                                        <li className="page-item"><button className="page-link" onClick={() => this.handleNavClick(1)} href="#">&raquo;</button></li> :
                                        <> </>
                                    } */}
                                </ul>
                            </nav>
                        </div>
                    )
                    :
                    <div className="container">
                        <div className="card p-3 mt-5 bg-warning text-center">
                            <h3>Connectez-vous pour accéder à cette page</h3>
                        </div>
                    </div>
                }
            </div>

        )
    }
}

export default withRouter(GestionCommandesClient)