function Popup(){
    return(
        <div className="rounded d-flex justify-content-center" id="divPopup" style={{  
            position: "fixed", top: "20%", left:"50%", transform:"translateX(-50%)", zIndex: "9999"
        }} >
            <div className="row justify-content-center">
                <div style={{ backgroundColor: 'white', padding: '20px', display: "none", boxShadow: "0 0 0 99999px rgba(0, 0, 0, .5)"}}
                    className='rounded border border-secondary col-12' id="idPopup">
                    <button onClick={() => { document.querySelector('#idPopup').style.display = "none" }} type="button" style={{ position: 'absolute', top: '3px', right: '3px' }}
                        className="close" aria-label="Close">
                        &times;
                    </button>
                    <h6 style={{ textAlign: "center" }} id="titrePopup"> </h6>
                    <br />
                    <div id="contenuPopup"> </div>
                </div>
            </div>
        </div>
    )
}

export default Popup;