import React from 'react';
import './Agrivore.css';
import { Link } from 'react-router-dom';

class Drive extends React.Component{
    constructor(props){
        super(props)
        this.lDrives = this.props.lDrives.filter(drive => drive.ppri_drive_ok ==="1" && drive.ppri_retrait.pr_ville != null && drive.ppri_retrait.pr_ville.Code_postal.substring(0,2)===this.props.numDepartement) // LB : on recupere uniquement les drives du departement
    }

    getProdDrives = (idPointRetrait) => {    
        let lProdImg = []
        let bTitre=false
        let titleImg;
        for(let drive of this.lDrives.filter(drive => drive.ppri_retrait.idpointretrait === idPointRetrait))   
        {
            // if(drive.ppri_retrait.idpointretrait==idPointRetrait){  // LB : pour tous les producteurs qui font parti du point de retrait renseigné en parametres
                if(!bTitre){  // LB : on met une seule fois le texte qui presente le point de retrait
                    lProdImg.push(<React.Fragment key="text">
                        <h3 style={{fontSize: '1.5em'}} className='card-text col-12 col-md-12'><strong>{drive.ppri_retrait.pr_libelle}</strong></h3>
                        <p className='card-text col-12 col-md-12 text-left'>{drive.ppri_retrait.pr_commentaire}</p>
                    </React.Fragment>)

                    bTitre=true
                }

                titleImg = drive.ppri_producteur_enseigne ? drive.ppri_producteur_enseigne : drive.p_nom + " " + drive.p_prenom;
  
                lProdImg.push(   // LB : image du producteur, qui redirige vers sa page
                    <Link key={this.lDrives.indexOf(drive)} className="col-md-2 col-6" to={'/producteur/'+drive.ppri_producteur_p_url_servie}><img alt="" style={{width:'100%'}} src={process.env.PUBLIC_URL + "/" + drive.p_img_enseigne} title={titleImg}/></Link>
                )
            // }
        }
        return(
            <div className="row">
                {lProdImg}
            </div>);
    }

    getDrives = () => {
        let lDrives = Array.from(new Set(this.lDrives.map(drive => drive.ppri_retrait.idpointretrait))) //liste unique des id des drives
        let lDrivesAffiche = []
        // for(let drive of this.lDrives) //LB : recupere la liste des points de retrait
        // {
        //     console.log(drive)
        //     if(!lDrivesImg.includes(drive.ppri_retrait.idpointretrait)){   //LB : evite les points de retrait en doublon
        //         lDrivesImg.push(drive.ppri_retrait.pr_img)
        //     }
        // }
        for(let idDrive of lDrives){   //LB : pour chaque drive, on insere l'image et on recupere les producteurs avec getProdDrives :
            let drive = this.lDrives.find(drive => drive.ppri_retrait.idpointretrait === idDrive).ppri_retrait //on retrouve le drive
            lDrivesAffiche.push(   //on genere une div pour chaque drive
                    <div key={lDrives.indexOf(drive.idpointretrait)} className="row justify-content-center">
                        <div className='col-md-2 col-9'>  
                            <div className="card">
                                <div className="card-block-an">          
                                    <img alt="" style={{width:"100%"}} src={process.env.PUBLIC_URL + "/img_drive/" + drive.pr_img} title="Contactez chaque producteur pour passer votre commande"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 col-11">   
                            <div className="card p-2">
                                {this.getProdDrives(drive.idpointretrait)}  
                            </div>
                        </div>
                    </div>
            )
        }
        return lDrivesAffiche
    }

    render(){
        return <>
            <div className="card titlecard">
                Points de retrait / Drives {this.props.nomDepartement}
            </div>

                {this.getDrives()}
            </>;
    }
}

export default Drive;