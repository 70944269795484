import {withRouter} from 'react-router-dom' // class 
import React from 'react'
import { Link } from "react-router-dom"

class Recrutement extends React.Component{

    componentDidMount = () => {
        localStorage.setItem("lastUrlVisited", window.location.pathname) // stocke url en cours en tant que derniere url consultee pour retour apres connexion si bes
        document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='Agrivore' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Agrivore recrute" />\n
                <meta name="twitter:description"    content="Agrivore : découvrez nos offres de recrutement" />\n
                <meta property="twitter:image"      content="./images/agrivore_icon.png">\n


                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Agrivore" />\n
                <meta property="og:description"     content="Agrivore : découvrez nos offres de recrutement" />\n
                <meta property="og:image"           content="./images/agrivore_icon.png">\n
                `)
    }

    /* LB :
    - on cree un tableau qui regroupe toutes les offres, elles memes sous la forme de tableaux 
    [titre, [phrase1,phrase2,...], [condition1,condition2,...]], [idem pour offre 2], [etc...]
    */
    lOffres=[
        {
            titre:
                "Commercial(e) Terrain -- France entière",
            description:
                [
                    //Description
                    `Rattaché(e) à la direction de l'entreprise (Bureaux situés dans le Nord Aveyron et le Lot), 
                    vous cherchez de nouveaux clients en démarchant un ensemble de prospects issus du monde agricole.`,
                    `Vous gérez de manière autonome votre prospection (téléprospection, démarchage terrain, prise de RDV, relances) 
                    votre planning et votre propre portefeuille de clients, de la prospection à la fidélisation.`,
                    `Vous gérez la négociation tarifaire jusqu'à la signature du contrat.`,
                    `Vous assurez la veille concurrentielle sur votre secteur.`,
                    <b>Connaissance du secteur agricole souhaitée.</b>,
                    <><b>Permis B</b> exigé car de nombreux déplacements sont à prévoir sur toute la France.</>
                ],
            conditions:
                [
                    //Conditions
                    "CDI",
                    "Salaire : selon profil (dont variable)",
                    "Voiture de fonction, Mutuelle."
                ]
        },

        {
            titre:
                "Prospection téléphonique -- Temps partiel",
            description:
                [
                    "Vous participez à la téléprospection et renseignez le fichier de prospection.",
                    "Horaires à définir selon profil."
                ],
            conditions:
                [
                    "CDD Temps partiel",
                    "Salaire : selon profil (dont variable)"
                ]
        }
    ]

    /* LB : Cette methode sert a recuperer toutes les phrases de descriptions de l'offre */
    recupDescription(offre){
        let lDescription = offre.description.map(description =>
            <p className="text-left">
                {description}
            </p>
        )
        return lDescription
    }

    /* LB : recupere toutes les conditions de l'offre */
    recupConditions(offre){
        let lConditions = offre.conditions.map(condition =>
            <p className="text-left">
                {condition}
            </p>
        )
        return lConditions
    }

    /* LB :
    - Cette methode sert a recuperer et mettre en forme toutes les offres
    - Pour cela, on a un "squelette", que l'on rempli en parcourant le tableau des offres
    - On recupere, a chaque fois, le titre, les phrases de descriptions (a l'aide de recupDescription)
    et les conditions (a l'aide de recupConditions)
    - On renvoie ensuite un tableau de div, qui correspond a chacune des offres
    */
    recupOffres(){
        let lDivOffres=this.lOffres.map(offre =>
            // AN_DOC .map sert a faire boucle sans traitement + efficace que for(let var of list){} cf. filter map find
            <div className="row card-deck mb-3 mt-2">
                <div className="card">
                    <div className="card-block">
                        <h3 className="card-title">{offre.titre}</h3>
                        <h6 className="card-text" >
                            {this.recupDescription(offre)}
                            <p className="text-left">
                                <b>Conditions :</b>
                            </p>
                            {this.recupConditions(offre)}
                            <p className="text-left">
                                <b>Contact</b> : 05.65.48.55.00 / agrivore @ gmail.com
                            </p>
                        </h6>
                    </div>
                    <div className="card-footer text-center">
                        <div className="text-muted">
                            Rejoignez une équipe dynamique en plein développement !
                        </div>
                    </div>
                </div>
            </div>
        )
        return lDivOffres;
    }

    render(){
        return(
            <div className="container-fluid">
                <div className="container">
                    <div className="row card-deck mb-3">
                        <div className="card">
                            <div className="card-block text-center" style={{backgroundColor: '#d2d2d2'}}>
                                <h3>Nous recrutons : Rejoignez-nous !</h3>
                                <p>Vous cherchez un emploi ou un stage ? </p>
                                <p>Nous sommes heureux de l'intérêt que vous portez à notre plateforme. N'hésitez pas à consulter nos offres.</p>
                            </div>
                        </div>
                    </div>

                    {this.recupOffres()}
                    <div className="p-2">
                        <Link to="/"><input type="button" className="btn btn-secondary" value="Retour" /></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Recrutement);
