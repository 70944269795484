import Axios from 'axios';
import React from 'react' // class
import {withRouter} from 'react-router-dom' // class 
import { Link } from 'react-router-dom';
import Drive from './Drive'
import API from './api'

class Departement extends React.Component{

    constructor(props){
        super(props)

        this.lDepartements = this.props.lDepartements;
        this.lProducteurs = this.props.lProducteurs;

        this.numeroDepartement = props.match.params.dep;
        this.nomDepartement = this.lDepartements[this.numeroDepartement];

        this.state = {
            largeurEcran:window.screen.availWidth, //LB : recupere la taille de l'ecran
            lProducteurs: undefined,
            lDrives: undefined
        }
        window.scrollTo(0, 0) // pendant du Link du btn cliqué pour retour systematique en haut de page quelle que soit la position de l ascenseur sur la page precedente 
        localStorage.setItem("lastUrlVisited", window.location.pathname) // stocke url en cours en tant que derniere url consultee pour retour apres connexion si bes

    }

    /* LB :
    - cette methode est appelee par getProducteurs, afin de recuperer la liste des images des
    produits du producteur entré en parametre
    - on parcourt la liste des produits, et on ajoute les images de chacun des produits dans
    un tableau, que l'on renvoi à la fin
    */
    getImgProduits(producteur, taille, type){
        let srcImage;
        let lProduitsProduct = []
        for(let produits of producteur.produits){
            type === "image" ? srcImage = produits.pr_img : srcImage = "svg/" + produits.pr_ico;
            lProduitsProduct.push(
                <img alt="" style={{marginRight: 5 +"px"}} key={produits.idproduit} height={taille} width={taille} title={produits.pr_libelle} src={process.env.PUBLIC_URL + "/" + srcImage}/>
            );
        }

        let classImage;
        type === "image" ? classImage = "col-md-3 align-self-center" : classImage = "col-md-2 align-self-center";
        return (
            <div className={classImage}>
                {lProduitsProduct}
            </div>
        );
    }

    getSvgIcon(producteur, taille){ //LB : Recupere la liste des SVG du detail des produits  
        let lProduitsProduct = []
        let lSvgRecup = []
        for(let produits of producteur.produits_details){ 
            if(!lSvgRecup.includes(produits.prd_image)){ //Evite les doublons 
                lSvgRecup.push(produits.prd_image)
                lProduitsProduct.push(
                    <img alt="" style={{marginRight: 5 +"px"}} key={lProduitsProduct.length} height={taille} width={taille} title={produits.prd_libelle} src={process.env.PUBLIC_URL + "/svg/" + produits.prd_image}/>
                );
            }
        }
        return (
            <div className="col-md-2 align-self-center">
                {lProduitsProduct}
            </div>
        );
    }

    attribClass = (producteur) => { //LB : On affiche les informations differement selon la taille de l'ecran
        window.onresize = () => {this.setState({largeurEcran: window.screen.availWidth})}; //lorsqu'on redimensionne la page, met a jour le this.state.largeurEcran (permet de mettre a jour le style de la page)
        return (this.state.largeurEcran >= 768 ? (
                            <> {/* equivalent de <React.Fragment> mais sans key = fragment React pour ne pas mettre de tag precis */}
                                <div className="col-md-7 align-self-center h6">
                                    <Link to={"/producteur/" + producteur.p_url_servie}>
                                        <h3 className="card-text"> 
                                            <strong>{producteur.label1}</strong> ● {producteur.label2 !== "" && producteur.label2}{producteur.label2 !== "" && ","} {producteur.short_address}
                                            {producteur.p_bio === "1" && <img alt="" style={{marginLeft: 5 +"px"}} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}/>}
                                    </h3>
                                    </Link>
                                </div>
                                </>
                            )
                            : 
                            (
                            <>
                                <div className="col-md-3 align-self-center">
                                    <Link to={"/producteur/" + producteur.p_url_servie}>
                                        <h3 className="card-text"> 
                                            <strong>{producteur.label1}</strong> 
                                        </h3>
                                    </Link>
                                </div>
                                <div className="col-md-4 align-self-center">
                                    <Link to={"/producteur/" + producteur.p_url_servie}>
                                    <h3 className="card-text"> 
                                    ● {producteur.label2 !== "" && producteur.label2}{producteur.label2 !== "" && ","} {producteur.short_address}
                                            {producteur.p_bio === "1" && <img alt="" style={{marginLeft: 5 +"px"}} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}/>}
                                    </h3>
                                    </Link>
                                </div>
                            </>
        ));
    }

    /* LB : 
    - parcourt la liste des producteurs
    - si le producteur vient du meme departement que celui selectionne, on l'ajoute au tableau
    - pour cela, on met quelques unes de ses informations (label, nom, adresse) dans une div
    - on renvoie ensuite le tableau de producteurs du departement en question
    */
    getProducteurs(){
        let lProducteurDepartement = [];
        for(let producteur of this.state.lProducteurs){
            if(producteur.departement === this.numeroDepartement && producteur.p_siren.length > 0){ //ne conserve que les producteurs du meme departement, avec un p_siren
                lProducteurDepartement.push(
                    <div className="card-block-an" key={producteur.idproducteur}> 
                        <div className="row"> 
                            {this.getSvgIcon(producteur, 25)}
                            {this.attribClass(producteur)}
                            {this.getImgProduits(producteur, 30, "image")}
                        </div>
                        <hr className="barreHorizontale"/> 
                    </div>
                );
            }
        }
        return lProducteurDepartement;
    }

    componentDidMount = () => {
        this.controller = new AbortController() // pour signal: this.controller.signal dans API.get

        API.get("/producteurs", {params:{lProdDepartement: this.numeroDepartement, type_json: "departement"}, signal: this.controller.signal})
        .then(response => this.setState({lProducteurs: response.data})) // signal = correctif au Warning: Can't perform a React state update on an unmounted component. (si quitte avant fin req axios) 
        .catch(() => alert("Erreur lors de la récupération des producteurs"))
        API.get("/drives", {signal: this.controller.signal})
        .then(response => this.setState({lDrives: response.data.drives}))
        .catch(() => alert("Erreur lors de la récupération des drives"))

        document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='Agrivore' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Connexion à Agrivore" />\n
                <meta name="twitter:description"    content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="twitter:image"      content="./images/agrivore_icon.png">\n

                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Agrivore" />\n
                <meta property="og:description"     content="Agrivore : pour une alimentation locale et de qualité" />\n
                <meta property="og:image"           content="./images/agrivore_icon.png">\n
                `)
    }

    componentWillUnmount = () => {
        this.controller.abort() // abort toutes req axios pour eviter Warning: Can't perform a React state update on an unmounted component. (si quitte avant fin req axios)
    }

    render(){
        return (
            <div style={{width:"80%", margin:"auto"}}>
                {(this.state.lProducteurs !== undefined && this.state.lDrives !== undefined) &&
                    <>
                    <Drive lDrives={this.state.lDrives} numDepartement={this.numeroDepartement} nomDepartement={this.nomDepartement}/>
                    <div className="card titlecard">
                        Producteurs {this.nomDepartement}
                    </div>

                    <div className="card">                      
                        {this.getProducteurs()}
                    </div>
                    </>
                }
            </div>
        )
    }
}

export default withRouter(Departement);
