import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios"; // requete ajax
import API from './api' // AN_DOC api.js pour urlBase req axios

// Gestion erreurs
axios.interceptors.response.use(function (response) { //Intercepte le résultat de toutes les requetes axios du site --> gestion par défaut des requetes réussies / ratées
  console.log("requete OK")
  return response;
}, function (error) {
  console.log(error.request.status)

  if(error.request.status.toString()[0] === '5'){
    let textError = document.createElement("textarea");
    textError.innerHTML = error.response.data.slice(error.response.data.search("<title>") + 7, error.response.data.search("// W"));
    alert("Erreur :\n" + textError.value)
  } else if(error.request.status.toString()[0] === '4'){
    alert("Erreur :\nBack-end introuvable")
  } else if(error.request.status.toString() !== '0') {
    let textError = document.createElement("textarea");
    textError.innerHTML = error.response.data.slice(error.response.data.search("<title>") + 7, error.response.data.search("// W"));
    alert("Erreur :\n" + textError.value)
  } else 
  return Promise.reject(error);
});

// TODO DECOMMENTER A VOIR

// AN_20210624
//console.log(process.env)
//
// if(process.env.NODE_ENV === "development"){ //Laisser cette portion de code dans index.js
//  localStorage.setItem("urlServer", "http://192.168.3.20:6543") // AN_20210603 ok s20 de test ip 3.2
//  localStorage.setItem("urlSite", "http://192.168.3.20:3001") // AN_20210603 ok s20 de test ip 3.2
// } else
if (process.env.NODE_ENV === "production") { // AN_20210413 TODO ajouter export NODE_ENV=production dans post-receive + npm build
  // localStorage.setItem("urlServer", window.location.protocol + '//' + window.location.hostname) // AN_GT_20210531 axios besoin url absolue TODO module api de gestion
  // localStorage.setItem("urlSite", window.location.protocol + '//' + window.location.hostname) // TODO CHANGER URL EN PROD // AN_GT_20210531 idem axios besoin url absolue
  console.warn = () => { }; //LB : permet de ne pas afficher les warnings/erreurs aux utilisateurs
  console.error = () => {}
}

//console.log(localStorage.getItem("urlServer"))

// Appel ajax pour récupérer les producteurs
// AN_20201120 API REST url a changer en production // axios = promise asynchrone
let requestDepts = API.get('/departements').catch(() => alert("Erreur récupération departements")); // AN_20201120 cf. rest_api.py
let requestGetChampsForm= API.get('/getChampsBDD'); // LB cf. rest_api.py, recup dictionnaire champs BDD
// request.then(
Promise.all([requestDepts, requestGetChampsForm]).then(
  (result) => {
    //console.log(result[1].data)
      ReactDOM.render(
              <React.StrictMode>
              <App champsForm={result[1].data} departements={result[0].data.dictDepartements}/>
              {/* va chercher App.js */}
              </React.StrictMode>,
              document.getElementById('root'))
            }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
