import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Agrivore from './Agrivore/Agrivore';
import Entete from './Entete';
import PageNotFound from './PageNotFound'


function RouteAdmin(props) {
  return (
    <>
      <Switch>
        <Route path='/' exact>
          <Entete/>
          <Agrivore
            dUserInformations={props.dUserInformations}
            dDepartements={props.dDepartements}
          >
          </Agrivore>
        </Route>
        <Route path='/'>
          <PageNotFound/>
        </Route>
      </Switch>
    </>
  )
}


export default RouteAdmin