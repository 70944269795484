import React from 'react' // class
import {withRouter} from 'react-router-dom' // class 
import './Agrivore.css';
import api from './api';

class ValidationCmde extends React.Component{

    constructor(props){
        super(props)
        this.setState({
            commandes:[]
        })

    }

    choixValidation = (validOk) => {
        api.put("/commande/producteur/all/utilisateur/null?validationCode="+this.state.validationCode+"&validOk="+validOk, { headers: { "Authorization": "***" }})
        .then((res)=>{
            if(res.data){ // si le traitement c'est bien passé 
                if(validOk === 1){//commande transmise
                    alert("Votre commande a été transmise au producteur, le bon de commande est disponnible dans votre boite mail.\n\n Agrivore vous remercie !")
                    window.location='/'
                }
                if(validOk === 0){ // comande annulée
                    alert("Votre commande bien été annulée.")
                    window.location='/'
                }
            }
            else{
                alert(res.data) // affiche le retour du python, un message d'erreur en fonction de la situation
            }
        })
    }

   


    componentDidMount(){ 
        let validationCode = this.props.match.params.validationCode 
        api.get("/commande/producteur/all/utilisateur/" + validationCode + "?typeCommande=validation") //l'api retourne tt les lignes de commande correspondant à ce code de validation
        .then((res) => {
            console.log(res.data.commandes)
            let prixTotal = 0
            res.data.commandes.forEach(commande => prixTotal += parseFloat(commande.commande.v_totalttc)) // calcul du prix total
            this.setState({ // on récup  les élément individuelement(prod, client, etc.)  pour faciliter l'accès
                commandes: res.data.commandes,
                producteur: res.data.commandes[0].producteur,
                ptRetrait: res.data.commandes[0].pointretrait,
                client: res.data.commandes[0].consommateur,
                prixTotal : prixTotal,
                validationCode: validationCode
            })
        })
        .catch((e)=> {
            alert("Erreur lors de la récupération de la commande.\nVeuillez réessayer ultérieurement\n" +e)
            window.location='/'

        })

    }
      

    render(){ 
        
        return( 

              <>
                <div className="container-fluid p-4"> 
                    <div className="card-deck mb-3">
                        <div className="card" style={{ width: "100%" }}>
                            <div className="card-block">
                            <h3 className='text-center'><strong><u>Validation de votre commande</u></strong></h3>

                                {this.state!== null && this.state.commandes.length >0 ?
                                <div>
                                    <div className='d-flex flex-sm-row flex-column justify-content-center'>
                                        <div className="rounded container-fluid p-2 m-1" style={{ border: "solid 1px #8E8E8E" }}>

                                            <div className='justify-content-center'>
                                                <h3 className='text-center'>Vos informations</h3>
                                                <div className='d-flex flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1 '> Nom : </span>
                                                    <span className='pl-1  m-1 '> {this.state.commandes[0].commande.v_nom}</span>
                                                </div>
                                                <div className='d-flex flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1 '> Mail : </span>
                                                    <span className='pl-1  m-1'> {this.state.commandes[0].commande.v_mel}</span>
                                                </div>
                                                <div className='d-flex flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1 '> Adresse : </span>
                                                    <span className='pl-1  m-1'>
                                                    {this.state.commandes[0].commande.v_adresse},  {this.state.commandes[0].commande.v_postal}, {this.state.commandes[0].commande.v_ville}
                                                        
                                                    </span>

                                                </div>
                                                <div className='d-flex  flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold  m-1'> Telephone : </span>
                                                    <span className=' pl-1 m-1'> {this.state.commandes[0].commande.v_telephone}</span>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="rounded  justify-content-center container-fluid p-2 m-1" style={{ border: "solid 1px #8E8E8E" }}>

                                            <div className=' justify-content-center'>
                                            <h3 className='text-center'>Informations du producteur</h3>

                                                <div className='d-flex flex-sm-row flex-column  p-2'>
                                                    <span className='font-weight-bold  m-1 '> Nom : </span>
                                                    <span className='pl-1 m-1 '> {this.state.producteur.label1[0] +" "+this.state.producteur.label1[1]+" "+ this.state.producteur.label1[2]}</span>
                                                </div>
                                                <div className='d-flex flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1 '> Mail : </span>
                                                    <span className='pl-1  m-1'> {this.state.producteur.p_mel}</span>
                                                </div>
                                                <div className='d-flex flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1'> Adresse : </span>
                                                    <span className='pl-1  m-1'>
                                                    {this.state.producteur.p_adresse} {this.state.producteur.p_adresse2},  {this.state.producteur.p_postal}, {this.state.producteur.p_ville}
                                                    
                                                    </span>

                                                </div>
                                                <div className='d-flex  flex-sm-row flex-column p-2'>
                                                    <span className='font-weight-bold m-1'> Telephone : </span>
                                                    <span className=' pl-1 m-1'>
                                                    
                                                        {this.state.producteur.p_tel.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")}
                                                        {(this.state.producteur.p_tel && this.state.producteur.p_portable) && " / "}
                                                        {this.state.producteur.p_portable.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")}
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <h3 className='text-center'><strong><u>  Detail de la commande </u></strong></h3>
                                        <div className='d-flex container-fluid flex-sm-row flex-column '>
                                            <div className='d-flex container-fluid  flex-sm-row flex-column justify-content-center  p-2'>
                                                <span className='font-weight-bold m-1 '> Date de retrait: </span>
                                                <span className='pl-1  m-1 '> {this.state.commandes[0].commande.v_dateretrait.slice(0,10)}</span>
                                            </div>
                                            <div className='d-flex container-fluid  flex-sm-row flex-column justify-content-center  p-2'>
                                                <span className='font-weight-bold m-1 '> Heure : </span>
                                                <span className='pl-1  m-1'> {this.state.commandes[0].commande.v_dateretrait.slice(10,16).replace(':',' h ')}</span>
                                            </div>
                                            <div className='d-flex  container-fluid  flex-sm-row flex-column justify-content-center p-2'>
                                                <span className='font-weight-bold m-1 '> lieu : </span>
                                                <span className='pl-1  m-1'>
                                                    {this.state.ptRetrait.pr_libelle}
                                                </span>
                                            </div>
                                            {this.state.commandes[0].commande.v_commentaire !== "" &&
                                                <div className='d-flex  container-fluid  flex-sm-row flex-column justify-content-center p-2'>
                                                 <span className='font-weight-bold m-1 '> commentaire : </span>
                                                 <span className='pl-1  m-1'>
                                                    {this.state.commandes[0].commande.v_commentaire }
                                                 </span>
                                                 </div>

                                            }

                                        </div>

                                        <div className='d-flex mt-4 container-fluid'>
                                            <div style={{ backgroundColor: "#DCDCDC" }} className='border border-secondary border-2 row container-fluid justify-content-center'>
                                                <h5 className=' col-sm-5 p-1 text-center container-fluid '>Libellé</h5>
                                                <h5 className=' col-sm-2 p-1 text-center container-fluid '>Quantité</h5>
                                                <h5 className=' col-sm-2 p-1 text-center container-fluid '>Prix Unitaire</h5 >
                                                <h5 className='col-sm-2 p-1 text-center container-fluid '>Prix total</h5>

                                            </div>
                                           
                                        </div>

                                        {this.state.commandes.map((cmde) => (
                                        <div className=' d-flex container-fluid'>
                                            <div className=' border border-secondary p-1 border-top-0  row container-fluid justify-content-center'>
                                                <span className=' col-sm-5 text-center container-fluid '>{cmde.article.a_libelle}</span>
                                                <span className='col-sm-2 text-center container-fluid '>{parseFloat(cmde.commande.v_quantite)   } </span>
                                                <span className='col-sm-2 text-center container-fluid '>{ (parseFloat(cmde.commande.v_puht).toFixed(2) + " €").replace(".", ",")}</span >
                                                <span className='col-sm-2 text-center container-fluid '>{(parseFloat(cmde.commande.v_totalttc).toFixed(2) + " €").replace(".", ",")} </span>

                                            </div>
                                           
                                        </div>
                                        ))} 
                                        <h5 className='conatiner-fluid m-5 text-right'> <strong>Total de la commande :</strong> {(parseFloat(this.state.prixTotal).toFixed(2) + " €").replace(".", ",")}</h5>

                                    </div>
                                    <div className='container-fluid d-flex flex-sm-row  justify-content-between'>
                                        <button onClick={()=> this.choixValidation(1)} className='btn btn-success container-fluid m-2 p-2'><strong> Valider la commande</strong></button>
                                        <button onClick={()=> this.choixValidation(0)} className='btn btn-danger container-fluid m-2 p-2'><strong>Annuler la commande</strong></button>
                                    </div>
                                 </div>


                                :
                                <></>

                                }

                        
                     
                       
                    </div>
                    </div>
                    </div>
                </div>
            </>
           

        )
    }
}

export default withRouter(ValidationCmde);
