import Axios from 'axios';
import React from 'react'
// import {useParams} from 'react-router-dom'
import {withRouter, Link} from 'react-router-dom'
import './Agrivore.css';
// import ico_produit_ppal_80 from './ico/escargot_80.png'; /* pour import statique */
// import img_produit from './img_produit/escargots_assiette_dz.jpg';
import Produit from './Produit'
import ReactDOM from 'react-dom'
import API from './api'

class Producteur extends React.Component{

    constructor(props){
        super(props) // comme en python, appelle meth initialize de la classe parente React.Component
        // this.state = {
        //     img_enseigne_ou_produit_ppal_160: null, // declare vars
        //     img_activite: null,
        // }
        // RECUP VIA PARAM d URL
       /*Recup producteur via id
        let idproducteur = this.props.match.params.idproducteur //let {idproducteur} = useParams(); syntaxe function // pour recup dans URL direct
 -       this.idproducteur = parseInt(idproducteur)
 -       this.state.dProducteur = this.props.lProducteurs.find(prod=>(prod.idproducteur===this.idproducteur)) // this = self de python
         */

        //this.p_url_de_base_producteur = this.props.match.params.p_url_de_base 

        //this.state.dProducteur = this.props.lProducteurs.find(prod=>(prod.p_url_de_base===this.p_url_de_base_producteur)) 

        window.scrollTo(0, 0) // pendant du Link du btn cliqué pour retour systematique en haut de page quelle que soit la position de l ascenseur sur la page precedente

        this.state = {
            dProducteur: undefined,
            largeurEcran: window.screen.availWidth,
            bVoirPlus: false,
            lProduitsDetails: []
        }

        this.lMois = {"01": "janvier", "02": "février", "03": "mars", "04": "avril", "05": "mai", "06": "juin",
                     "07": "juillet", "08": "août", "09": "septembre", "10": "octobre", "11": "novembre", "12": "décembre"} 

        localStorage.setItem("lastUrlVisited", window.location.pathname) // stocke url en cours en tant que derniere url consultee pour retour apres connexion si bes
        // ex = /departement/24 dans http://localhost:3001/departement/24
    }


    componentDidMount(){ // AN_DOC quand composant est monté / attaché au DOM après constructor et render cf cycle de vie d'un composant : https://fr.reactjs.org/docs/state-and-lifecycle.html
        // plus besoin de ces imports si rep images sont dans /public au lieu d etre dans /src :
        // import("./" + this.state.dProducteur.p_img_enseigne).then((result) => this.setState({img_enseigne_ou_produit_ppal_160:  result.default}));
        // import("./" + this.state.dProducteur.p_img_activite).then((result) => this.setState({img_activite:  result.default}));

        // Axios.get(localStorage.getItem("urlServer") + '/api/producteurs/' + this.idproducteur).then((result) => this.setState({dApiProducteur: result.data}));  // AN_20201130 a changer en production
        this.controller = new AbortController()

        API.get('/producteur/' + this.props.match.params.p_url_servie, { params: { type_json: "producteur" }, signal: this.controller.signal })
            // .then(
            //     result => 
            //     this.setState({ dProducteur: result.data.producteur}) // AN_DOC modif state donc appelle ensuite componentDidUpdate
            // ).catch(() => alert("Erreur récupération producteur"))
            .then(
                response => 
                {
                if (response.data.producteur !== null) {
                    // PB_2022-06-22 Traitement qui passe les infos de l'article inter à l'article qui sera affiché
                    for (let art of response.data.producteur.articles) {
                        for (let artInter of response.data.producteur.articlesInter) {
                            if (art.idarticle === artInter.pai_idarticle) {
                                if (artInter.pai_descriptif !== "None") art.a_descriptif = artInter.pai_descriptif;
                                if (artInter.pai_image_custom !== "None") art.a_img = artInter.pai_image_custom;
                                if (artInter.pai_dispodatedeb !== "None") art.a_dispodatedeb = artInter.pai_dispodatedeb;
                                if (artInter.pai_dispodatefin !== "None") art.a_dispodatefin = artInter.pai_dispodatefin;
                                if (artInter.pai_bio !== "None") art.a_bio = artInter.pai_bio;
                                if (artInter.pai_prixvente !== "None") art.a_prixvente1 = artInter.pai_prixvente;
                                break;// PB_2022-06-22 evite de passer plusieurs fois sur le meme article inter 
                            }
                        }
                    }
                    // this.setState({
                    //     dProducteur: response.data.producteur,
                    // })
                }
                else {}
                this.setState({
                        dProducteur: response.data.producteur,
                    })
                }
            ).catch(() => alert("Erreur récupération producteur"))

        API.get('/produitsDetail', { signal: this.controller.signal })
            .then(result => this.setState({ lProduitsDetails: result.data.produitsDetail }) // AN_DOC modif state donc appelle ensuite componentDidUpdate
            ).catch(() => alert("Erreur récupération producteur"))

        window.onresize = () => {this.setState({largeurEcran: window.screen.availWidth})};
    }

    componentWillUnmount = () => { // AN_DOC (hook) appel juste avant de quitter la page (en allant vers autres url) donc abort req ajax pas terminées
        this.controller.abort()
    }

    componentDidUpdate = () => {
        if (this.state.dProducteur !== null && this.state.dProducteur !== undefined) {
            document.querySelector("#metaHead").innerHTML = (`
                <meta name="keywords" content='${this.state.dProducteur.p_keywords}' lang="fr"> \n

                <meta name="twitter:card"           content="summary" />\n
                <meta name="twitter:url"            content="${document.location.href}" />\n
                <meta name="twitter:title"          content="Agrivore : ${this.state.dProducteur.p_enseigne}" />\n
                <meta name="twitter:description"    content="${this.state.dProducteur.p_descriptif_activite}" />\n
                <meta property="twitter:image"      content="${process.env.PUBLIC_URL + "/" + this.state.dProducteur.p_img_enseigne}">\n


                <meta property="og:type"            content="website" />\n
                <meta property="og:url"             content="${document.location.href}" />\n
                <meta property="og:title"           content="Agrivore : ${this.state.dProducteur.p_enseigne}" />\n
                <meta property="og:description"     content="${this.state.dProducteur.p_descriptif_activite}" />\n
                <meta property="og:image"           content="${process.env.PUBLIC_URL + "/" + this.state.dProducteur.p_img_enseigne}">\n
                `)
        }
        let descriptifActivite = ""
        let btnVisualisation = ""
        if(this.state.dProducteur !== undefined && this.state.dProducteur !== null && this.state.dProducteur.p_descriptif_activite !== null){

            if(this.state.dProducteur.p_descriptif_activite.length > 250 && this.state.largeurEcran < 992){
                if(this.state.bVoirPlus){
                    descriptifActivite = this.state.dProducteur.p_descriptif_activite
                    btnVisualisation = <button onClick={() => this.changeTailleDescriptif(false)} className="centerBlock border btn mt-2" >Voir moins</button>
                } else {
                    descriptifActivite = this.state.dProducteur.p_descriptif_activite.slice(0, 230) + "..."
                    btnVisualisation = <button onClick={() => this.changeTailleDescriptif(true)} className="centerBlock border btn mt-2" >Voir Plus...</button>
                }
            } else {
                descriptifActivite = this.state.dProducteur.p_descriptif_activite
            }
            if (document.getElementById("descriptifActivite") !== null) {
                document.getElementById("descriptifActivite").innerHTML = descriptifActivite
                ReactDOM.render(btnVisualisation, document.getElementById("buttonVisualisation"))
            }
        }
    }

    getMagasins(){
        let lMagasinsTries = []
        let lMagasinsDuDepartement = []
        let lMagasinsDesAutresDepartements = []
        for(let magasin of this.state.dProducteur.magasins){
            if(magasin.ville_rel !== null && magasin.ville_rel.Code_postal.slice(0,2) === this.state.dProducteur.p_postal.slice(0,2)){
                lMagasinsDuDepartement.push(magasin);
            } else {
                lMagasinsDesAutresDepartements.push(magasin);
            }
        }
        lMagasinsTries = lMagasinsDuDepartement.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1)
            .concat(lMagasinsDesAutresDepartements.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1))

        let lDivMagasins = []
        for (let magasin of lMagasinsTries){
            lDivMagasins.push( // push = append de python
                <div  style={{paddingLeft: '30px'}} key={magasin.idmagasin}>
                    {/* Warning: Each child in a list should have a unique "key" prop. => faut absolument une key dans la div pour DOM virtuel de React pour parcourir plus vite*/}
                    • {magasin.ville_rel.v_nom_commune} : {magasin.mg_libelle}
                </div>
            );
        }

        return lDivMagasins.length > 0 ? lDivMagasins : <p  style={{paddingLeft: '30px'}} className="card-text text-secondary text-left">Aucun magasin pour le moment</p>
    }

    getProduits(){
        // let lProduitsDetails=this.state.dProducteur.produits_details.map(produitDetail =>
        //         <div className="row card" key={produitDetail.idproduitdetail}> 
        //             <div className="col-12 row justify-content-around">
        //                 <div className="col-md-4 col-12">
        //                     {/* <Produit produit={produit} /> */}
        //                     {this.getProduitsDetails(produitDetail)}
        //                 </div>
        //                 {this.state.dProducteur.p_desabonnerOK === "1" &&
        //                     <div className="col-md-8 col-12 row justify-content-start">
        //                         {this.getArticles(produitDetail.idproduitdetail)}
        //                     </div>
        //                 }
        //             </div>
        //                 <>
        //                     <hr style={{ width: "90%", margin: "auto"}} />
        //                     <div className="col-12 row justify-content-around">
        //                     </div>
        //                 </>
        //         </div>
        //     ); 

        let lProduits = []
        lProduits = this.state.dProducteur.produits.filter(produit => produit.idproduit == this.state.dProducteur.p_idproduit_principal).concat(
            this.state.dProducteur.produits.filter(produit => produit.idproduit !== this.state.dProducteur.p_idproduit_principal).sort((a, b) => {
                return a.pr_libelle > b.pr_libelle ? 1 : -1
            })
        ).map(produit =>
            <div key={produit.idproduit} className="card" >
                <Produit produit={produit} />
                {this.getArticlesFromProduit(produit.idproduit)}
            </div>
        )

        return (
            <div className="">
                {lProduits}
            </div>
        )
    }

    getProduitsDetails(produitDetail) { //met en forme un produit detail
        if(produitDetail !== undefined){
            let lProduitsDetails = (
                <div className="col-md-4 col-12" key={produitDetail.idproduitdetail}>
                    <div className="card-deck mb-3">
                        <div className="" style={{ width: "100%" }} title={this.getTitleProduitDetail(produitDetail)}>
                            <div className="row justify-content-center">
                                <div className="col-12" >
                                    <img className="centerBlock" width='50px' height='50px' src={process.env.PUBLIC_URL + "/svg/" + produitDetail.prd_image} alt=" " />
                                </div>
                                <div className="col-12">
                                    <h3 className="card-text centerBlock text-center" style={{ marginTop: "0.5em", fontWeight: "bold" }}>
                                        {produitDetail.prd_libelle}<br />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
            return (lProduitsDetails)
        }
    }

    getTitleProduitDetail = produitDetail => { //genere le contenu de l'infobulle d'un produit detail
        return this.state.dProducteur.articles.filter(article => article.a_idproduitdetail === produitDetail.idproduitdetail) //recupere la liste des articles du producteur lies au produit detail
            .map(article => article.a_libelle).join("\n") //recupere leur libelle
    }

    getArticles() {
        let lArticles = this.state.dProducteur.articles.map(article =>
            <div key={article.idarticle} className="col-md-2 col-sm-3 col-12 " >
                <div className="mb-3">
                    <div className="" style={{ width: "100%" }}>
                        <div className="row">
                            <div className="col-12">
                                <img className="centerBlock" width="50px"
                                    src={process.env.PUBLIC_URL + "/" + article.a_img} alt=" " />

                            </div>
                            <div className="col-12 centerBlock">
                                <h3 className="card-text centerBlock text-center" style={{ marginTop: "0.5em", fontWeight: "bold" }}>
                                    {article.a_libelle}<br />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <div className="row justify-content-around">
                {lArticles}
            </div>
        )
    }

    getArticlesFromProduit(idProduit) {
        let dateActuelle = new Date()
        let lArticles = this.state.dProducteur.articles.filter(article =>
            idProduit == this.state.lProduitsDetails.find(produitDetail => produitDetail.idproduitdetail === article.a_idproduitdetail).prd_produit
        ).sort((a, b) => {
            return a.a_libelle > b.a_libelle ? 1 : -1
        }).map(article =>
            <tr>
                <td width="50px">
                    <img className="" width="50px" src={process.env.PUBLIC_URL + "/" + article.a_img} alt=" " />
                </td>
                <td width="200px" className="align-middle">
                    <span className="card-text text-left align-middle font-weight-bold">
                        {article.a_libelle + "  "}
                    </span>

                    {article.a_bio === "1" && // affiche logo bio si article bio
                        <img alt="Bio" height="20px" src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}
                        />// : "centerBlock mb-2"
                    }
                </td>
                <td width="400px">
                    <h6 className="font-italic card-text text-left">{article.a_descriptif}</h6>
                </td>
                <td width="300px">
                    <h6 className="font-italic card-text text-left">
                        {(article.a_ingredient !== null && article.a_ingredient !== "") && "Ingrédients : " + article.a_ingredient}
                    </h6>
                </td>
                <td>
                    <h6 className="font-italic card-text">
                        {article.a_prixvente1 !== "None" && (article.a_prixvente1.slice(0, article.a_prixvente1.length - 2) + " €").toString().replace(".", ",")}
                        {(article.a_unite != null && article.a_prixvente1 !== "None") && " / " + article.a_unite}
                    </h6>
                </td>
                <td width="250px">
                    <h6 className="font-italic card-text text-right">
                        {(article.a_dispodatedeb !== "None" && article.a_dispodatefin !== "None") ?
                            <>
                                Du {article.a_dispodatedeb.split('-')[2] + "/" + article.a_dispodatedeb.split('-')[1] + "/" + article.a_dispodatedeb.split('-')[0] + " "}
                                au {article.a_dispodatefin.split('-')[2] + "/" + article.a_dispodatefin.split('-')[1] + "/" + article.a_dispodatefin.split('-')[0]}
                            </>
                            :
                            ((article.a_dispodatedeb !== "None" && article.a_dispodatefin === "None") ?
                                <>
                                    A partir du {article.a_dispodatedeb.split('-')[2] + "/" + article.a_dispodatedeb.split('-')[1] + "/" + article.a_dispodatedeb.split('-')[0]}
                                </>
                                :
                                ((article.a_dispodatedeb == "None" && article.a_dispodatefin !== "None") ?
                                    <>
                                        Jusqu'au {article.a_dispodatefin.split('-')[2] + "/" + article.a_dispodatefin.split('-')[1] + "/" + article.a_dispodatefin.split('-')[0]}
                                    </>
                                    :
                                    ''
                                ))
                        }
                    </h6>
                </td>
                <td>
                    <h6 className="font-italic card-text text-right">

                    </h6>
                </td>
            </tr>
        );
        return (
            <table className="table table-responsive-sm">
                {lArticles}
            </table>
        )
    }



    getPointsRetrait = () => {
        let lPointsRetrait = []
        for (let pointRetrait of this.state.dProducteur.p_retraits){
            lPointsRetrait.push(
                <div style={{paddingLeft: '30px'}} key={pointRetrait.idpointretrait}>• {pointRetrait.pr_libelle}
                </div>
            )
        }
        return lPointsRetrait.length > 0 ? lPointsRetrait : <p  style={{paddingLeft: '30px'}} className="text-secondary text-left">Aucun point de retrait pour le moment</p>
    }

    getLivraison = () => {
        return this.state.dProducteur.p_livraison_ok === "1" ? <p className="card-text text-left">
            <img alt=""  className='m-auto mr-1' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/livraison.png"} />  Livraison à domicile dans un rayon de {this.state.dProducteur.p_livraison_km}km</p> : <p className="text-secondary text-left"><img alt=""  className='m-auto mr-1' height="25px" src={process.env.PUBLIC_URL + "/ico/livraison.png"} /> Pas de livraison à domicile pour le moment</p>
        

    }

    getMarches = () => {
        let lMarchesTries = []
        let lMarchesDuDepartement = []
        let lMarchesDesAutresDepartements = []
        for(let marche of this.state.dProducteur.marches){
            if(marche.ville_rel !== null && marche.ville_rel.Code_postal.slice(0,2) === this.state.dProducteur.p_postal.slice(0,2)){
                lMarchesDuDepartement.push(marche);
            } else {
                lMarchesDesAutresDepartements.push(marche);
            }
        }
        lMarchesTries = lMarchesDuDepartement.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1)
            .concat(lMarchesDesAutresDepartements.sort((a, b) => a.ville_rel.v_nom_commune.toLowerCase() > b.ville_rel.v_nom_commune.toLowerCase() ? 1 : -1))

        let lDivMarches = []
        for (let marche of lMarchesTries){
            let lJours = []
            let lHoraireMarche = []
            let lMois = []
            for (let [key, value] of Object.entries(marche.mr_jour)) {
                value === "1" && lJours.push(key)
            }
            for (let [key, value] of Object.entries(marche.mr_heure)) {
                value === "1" && lHoraireMarche.push(key=== "aprem" ? " après midi" : " " + key)
            }
            for(let mois of marche.mr_liste_mois.split(",").map(mois => mois.trim()).sort(function(a, b) {
                return parseInt(a) - parseInt(b);
            })){
                lMois.push(this.lMois[mois])
            }
            // AN_DOC gestion lib marche pour ne pas repeter ville si incluse dans le nom du marche (doublon CmdeProducteur)
            let libelleMarche = marche.mr_libelle.toLowerCase().includes(marche.ville_rel.v_nom_commune.toLowerCase().trim()) ?
                marche.mr_libelle.trim()
                :
                marche.ville_rel.v_nom_commune.trim() + ", " + marche.mr_libelle.trim()
            lDivMarches.push(<div  style={{paddingLeft: '30px'}} key={marche.idmarche}>
                • {libelleMarche}{lJours.length > 0 && <span>, le {lJours.join(", ")} {lHoraireMarche.join(" ")}</span>}{(lMois.length > 0 && lMois[0] !== undefined) && <span>, en {lMois.join("/")}</span>}
            </div>)

        }


        return lDivMarches.length > 0 ? lDivMarches : <p  style={{paddingLeft: '30px'}} className="text-secondary text-left">Pas de présence sur les marchés pour le moment</p>
    }

    changeTailleDescriptif = bNouveauVoirPlus => {
        this.setState({
            bVoirPlus: bNouveauVoirPlus
        })
    }

    render(){
        // let img_producteur_160 = this.state.img_enseigne_ou_produit_ppal_160
        // let img_activite_producteur = this.state.img_activite
        return(
            <>
                {(this.state.dProducteur !== undefined && this.state.lProduitsDetails.length > 0)&&        // && = if (sans else)  AN_DOC
                    <div className="container-fluid p-2">       {/* ajout -fluid pour elargir fiche producteur p-5 padding */}
                        {this.state.dProducteur !== null ?     // ? = if (: = else)  AN_DOC
                            <>
                                <div className="card-deck mb-3">

                                    <div className="card" style={{ width: "100%" }}>
                                        <div className="card-block">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-4 mb-5">
                                                    {/* <img src={process.env.PUBLIC_URL + '/img_producteurs/logo_producteur1.png'} /> */}
                                                    <img
                                                        className={this.state.largeurEcran > 576 ? "": "centerBlock mb-2"}
                                                        src={process.env.PUBLIC_URL + "/" + this.state.dProducteur.p_img_enseigne} alt=" " style={{ maxWidth: '14em'}}
                                                    />
                                                    <br/>
                                                    { (this.state.dProducteur.p_bio === "1" || this.state.dProducteur.articles.filter(article => article.a_bio ==="1").length > 0) &&
                                                        <img
                                                            className={this.state.largeurEcran > 576 ? "mt-3" : "centerBlock mb-2"}
                                                            alt=""  height="50px" src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"}
                                                        />
                                                    }
                                                    {/* process.env.PUBLIC_URL est defini par webpack en arriere-plan  */}
                                                    {/* {img_producteur_160 && <img src={img_producteur_160} alt="Card cap" style={{ width:'14em', }}/>  }  */}
                                                    {/* affichage conditionnel qui met à jour l objet si state change*/}
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-12 col-lg-3">
                                                    <h3  style={{ fontWeight: 'bold', textAlign: "left" }}>
                                                        <div>{this.state.dProducteur.label1}</div>
                                                        {this.state.dProducteur.label2 !== "" && <div>{this.state.dProducteur.label2}</div>}
                                                        {this.state.dProducteur.label3 !== "" && <div>{this.state.dProducteur.label3}</div>}
                                                    </h3>
                                                    <div className="card-text" style={{ textAlign: "left" }}>
                                                        {(this.state.dProducteur.p_adresse !== "" || this.state.dProducteur.p_adresse2 != "") &&
                                                            <div>{this.state.dProducteur.p_adresse} {this.state.dProducteur.p_adresse2}</div>
                                                        }
                                                        {(this.state.dProducteur.p_postal !== "" || this.state.dProducteur.p_ville != "") &&
                                                            <div>{this.state.dProducteur.p_postal} {this.state.dProducteur.p_ville}</div>
                                                        }
                                                        {(this.state.dProducteur.p_tel !== "" || this.state.dProducteur.p_portable !== "") &&
                                                            <div>
                                                                {this.state.dProducteur.p_tel.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")}
                                                                {(this.state.dProducteur.p_tel && this.state.dProducteur.p_portable) && " / "}
                                                                {this.state.dProducteur.p_portable.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5")}
                                                            </div>
                                                        }
                                                        {this.state.dProducteur.p_mel !== "" && <div>{this.state.dProducteur.p_mel}</div>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-5">
                                                    <div className="card-text" style={{ textAlign: "left" }}>
                                                        <br />
                                                        {this.getLivraison()}
                                                        <br />
                                                    </div>
                                                    <div className={Array.isArray(this.getPointsRetrait()) ? "card-text" : "card-text text-secondary"} style={{ textAlign: "left" }}>
                                                        <b><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/voiture.png"} /> Points de retrait :</b><br />
                                                        {this.getPointsRetrait()}
                                                        <br />
                                                    </div>
                                                    <div className="card-text" style={{ textAlign: "left" }}>
                                                        <b className={Array.isArray(this.getMarches()) ? "card-text" : "text-secondary"}> <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/marche.png"} />  Présence sur les marchés :</b><br />
                                                        <div>
                                                            {this.getMarches()}
                                                        </div>
                                                        <br />

                                                    </div>
                                                    <div className={Array.isArray(this.getMagasins()) ? "card-text" : "card-text text-secondary"} style={{ textAlign: "left" }}>
                                                        <b><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/magasin.png"} />  Magasins de proximité :</b><br />
                                                        {this.getMagasins()}
                                                        {/* AN appel méthode */}
                                                        <br/>
                                                    </div>
                                                    {this.state.dProducteur.p_cueillette_ok==="1" &&
                                                        <div className='card-text text-left'>
                                                            <b><img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" src={process.env.PUBLIC_URL + "/ico/cueillette.png"} /> Cueillette disponible</b>
                                                            <p  style={{paddingLeft: '30px'}} className='font-italic font-weight-light text-left'> Notre conseil ! Appelez avant de vous déplacer pour vérifier la disponibilité des fruits et légumes de saison.</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                                <div className="card-deck mb-3 mb-3">
                                    <div className="card" style={{ width: "100%" }}>
                                        <div className="card-block">
                                            <div className="row">
                                                <div className="col-lg-3 col-12">
                                                    <img className={this.state.largeurEcran > 992 ? "": "centerBlock mb-2"} src={process.env.PUBLIC_URL + "/" + this.state.dProducteur.p_img_activite} alt=" " style={{ maxWidth: '14em' }} />
                                                    {/* {img_activite_producteur && <img src={img_activite_producteur} alt="Card cap" style={{ height:'8em', }}/>  }  */}
                                                </div>
                                                <div className="card-text col-lg-9 col-12 mt-md-0 mt-3 pl-4" style={{ textAlign: "left" }} id="descriptifActivite"></div>
                                                <div className="col-12" id="buttonVisualisation"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                {this.getProduits()}
                                {/* {this.state.dProducteur.p_cmde_ok === "1" &&
                                    <>
                                        <hr style={{height: "20px"}}/>
                                        {this.getArticles()}
                                    </>
                                } */}
                                {/* <Produit lProduits={this.state.dProducteur.produits}/> */}
                                {/* <div>{this.getProduitsDetails()}</div> */}

                                <div className="row justify-content-center">
                                    {/* {this.props.dUserInformations.idUtilisateur !== null ? => CMD AVEC CONNEXION => Ancien traitement 
                                        <Link to={"/commande/" + this.state.dProducteur.idproducteur + "/" + this.props.dUserInformations.idUtilisateur}>
                                            <button style={{ fontSize: "calc(1.25em + 1vw)" }} type="button" className="mt-4"
                                                disabled={this.state.dProducteur.p_cmde_ok === "1" ? false : true}
                                                title={this.state.dProducteur.p_cmde_ok === "1" ? "Cliquez pour passer commande" : "Ce producteur ne gère pas de commande pour le moment"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="currentColor" className="bi bi-basket mr-2 mb-1" viewBox="0 0 16 16">
                                                    <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                                                </svg>
                                Passer commande
                                                </button>
                                        </Link>
                                        :
                                        <Link to={"/login.html"}>
                                            <input style={{ fontSize: "calc(0.75em + 1vw)" }} type="button" value="Connectez vous pour passer commande" className="mt-4" />
                                        </Link>
                                    } */}

                                    <Link to={"/commande/" + this.state.dProducteur.idproducteur + "/" + this.props.dUserInformations.idUtilisateur}>
                                        <button style={{ fontSize: "calc(1.25em + 1vw)" }} type="button" className="mt-4 btn btn-success btn-lg"
                                            disabled={this.state.dProducteur.p_cmde_ok === "1" ? false : true}
                                            title={this.state.dProducteur.p_cmde_ok === "1" ? "Cliquez pour passer commande" : "Ce producteur ne gère pas de commande pour le moment"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="currentColor" className="bi bi-basket mr-2 mb-1" viewBox="0 0 16 16">
                                                <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                                            </svg>
                                            Passer commande
                                        </button>
                                    </Link>


                                </div>
                            </>
                            :
                            <>
                                <div className="card p-3 mt-5 text-center">
                                    <h3>Producteur introuvable</h3>
                                </div>
                            </>
                        }
                    </div>
                }
            </>
        )
    }
}

export default withRouter(Producteur);