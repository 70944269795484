import React from 'react';
import './Agrivore.css';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios'
import API from './api'

class RecuperationPassword extends React.Component {

    verifValidation = (retourBackend) => {
        if (typeof (retourBackend) === "boolean") {
            document.querySelector('#validationModificationMdp').style.display = "block"
            document.querySelector('#erreurModificationMdp').style.display = "none"
            let lChampsForm = ["inputIdentifiant", "inputNouveauMDP", "inputMDPConfirmation", "submitForm"]
            lChampsForm.map(champForm => 
                document.getElementById(champForm).setAttribute("disabled", true)
                )
        } else {
            this.erreurFormulaire(retourBackend)
        }
    }

    erreurFormulaire = erreur => {
        document.querySelector('#validationModificationMdp').style.display = "none"
        document.querySelector('#erreurModificationMdp').innerHTML = erreur
        document.querySelector('#erreurModificationMdp').style.display = "block"
    }

    submitFormModificationPassword = () => {
        let passwordUtilisateur = this.props.match.params.passwordUtilisateur
        let saisieIdentifiant = document.getElementById('inputIdentifiant').value
        let saisieNouveauMDP = document.getElementById('inputNouveauMDP').value
        let saisieNouveauMDPConfirmation = document.getElementById('inputMDPConfirmation').value

        if (saisieIdentifiant.trim() === "" || saisieNouveauMDP.trim() === "" || saisieNouveauMDPConfirmation.trim() === "") {
            this.erreurFormulaire("Saisie incorrecte")
            return false
        }

        if (!saisieNouveauMDP.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)) {
            this.erreurFormulaire("Le mot de passe doit contenir :</br>8 caractères minimum, au moins une majuscule, une minuscule et un chiffre")
            return false
        }

        if (saisieNouveauMDP !== saisieNouveauMDPConfirmation) {
            this.erreurFormulaire("La confirmation du mot de passe a échoué.")
            return false
        }

        API.put('/utilisateur/' + passwordUtilisateur, { paramsRequest: { action: "modificationMdp", nouveauMDP: saisieNouveauMDP, nameUtilisateur: saisieIdentifiant } })
            .then(response => this.verifValidation(response.data))
            .catch(error => {
                this.erreurFormulaire("La modification du mdp a échoué")
                alert("La modification du mdp a échoué")
            })
    }

    render() {
        return (
            <>
                <div className="container ">
                    <div className="card p-3">
                        <div className="form-group">
                            <form onSubmit={(e) => { e.preventDefault(); this.submitFormModificationPassword() }} autoComplete="off"> {/* desactive refresh by default du formulaire */}
                                <div className="form-group">
                                    <label className="h4" htmlFor="inputIdentifiant">Identifiant</label>
                                    <input required type="text" className="form-control" id="inputIdentifiant" placeholder="Entrez votre identifiant..." />
                                </div>
                                <div className="form-group">
                                    <label className="h4" htmlFor="inputMDP">Nouveau mot de passe</label>
                                    <input required type="password" className="form-control" id="inputNouveauMDP" placeholder="Entrez votre nouveau mot de passe..." />
                                </div>
                                <div className="form-group">
                                    <label className="h4" htmlFor="inputMDP">Confirmation du mot de passe</label>
                                    <input required type="password" className="form-control" id="inputMDPConfirmation" placeholder="Confirmez votre mot de passe..." />
                                </div>
                                <input id="submitForm" type="submit" value="Changer le mot de passe" />
                            </form>
                        </div>
                        <div style={{ display: "none", border: 'solid', maxWidth: '700px' }} className="rounded mt-3 alert alert-success text-center" id="validationModificationMdp">
                            Votre mot de passe a été modifié avec succès. Vous pouvez maintenant vous connecter<Link to="/login.html"><button>ici</button></Link>
                        </div>
                        <div style={{ display: "none", border: 'solid', maxWidth: '400px' }} className="rounded mt-3 alert alert-danger text-center" id="erreurModificationMdp"></div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(RecuperationPassword)