import { withRouter } from "react-router"
import React from 'react'
import Axios from "axios"
import API from './api'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom"
import ListeProducteurs from "./ListeProducteurs"

class ProducteursProximites extends React.Component {

    constructor(props) {
        super(props)
    }

    afficheResultat = (lProducteursOk, distanceMax) => {

        let resultatRecherche = lProducteursOk.length > 0 ?
            <ListeProducteurs methUtilisateurConnecte={this.props.methUtilisateurConnecte} lProduitsDetail={this.props.lProduitsDetail} lProducteurs={lProducteursOk} dUserInformations={this.props.dUserInformations} />
            :
            <h6 className="text-center">Aucun producteur à moins de {distanceMax} km</h6>

        ReactDOM.render(resultatRecherche, document.getElementById("resultatRechercheProducteursProximites"))

        let divResultatRechercheProducteursProximites = document.getElementById("resultatRechercheProducteursProximites")
        divResultatRechercheProducteursProximites.style.display = "block"
        ReactDOM.render(resultatRecherche, divResultatRechercheProducteursProximites)

        document.getElementById("resultatRecherche").style.display = "none"

        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    calcDistanceCoordonnes(lat1, lon1, lat2, lon2) { //Calcul de la distance en km entre 2 coordonnees
        //Code issu de https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
        var RayonTerre = 6371; // rayon de la Terre en km
        var differenceLatitude = this.convertToRad(lat2 - lat1);  //difference entre les 2 latitudes, en radian 
        var differenceLongitude = this.convertToRad(lon2 - lon1); //difference entre les 2 longitudes, en radian
        var latitude1 = this.convertToRad(lat1);  //latitude du point 1, en radian
        var latitude2 = this.convertToRad(lat2);  //latitude du point 2, en radian

        var calculIntermediaire1 = Math.sin(differenceLatitude / 2) * Math.sin(differenceLatitude / 2) +
            Math.sin(differenceLongitude / 2) * Math.sin(differenceLongitude / 2) * Math.cos(latitude1) * Math.cos(latitude2);
        var calculIntermediaire2 = 2 * Math.atan2(Math.sqrt(calculIntermediaire1), Math.sqrt(1 - calculIntermediaire1));
        var distance = RayonTerre * calculIntermediaire2;
        return distance;
    }

    // Converts numeric degrees to radians
    convertToRad(Value) {
        return Value * Math.PI / 180;
    }

    getProducteursProximites = position => {
        let distanceMax = parseInt(document.getElementById("rangeDistanceMax").value)

        let lProducteursOk = this.props.lProducteurs.filter(producteur =>
            this.calcDistanceCoordonnes(parseFloat(producteur.p_gps_latitude), parseFloat(producteur.p_gps_longitude), this.props.centerLeaflet.lat, this.props.centerLeaflet.lng) < distanceMax
            // Math.abs(parseFloat(producteur.p_gps_latitude) - parseFloat(position.coords.latitude)) < (distanceMax / 111) &&
            // Math.abs(parseFloat(producteur.p_gps_longitude) - parseFloat(position.coords.longitude)) < (distanceMax / 76)
        )

        this.afficheResultat(lProducteursOk, distanceMax)

        document.getElementById("lieuRecherche").value = "" // RAZ champ recherche ville
    }

    render() {
        return (
            <div className="mt-3" style={{ width: "100%", margin: "auto" }}>
                {this.props.positionUtilisateur !== undefined &&
                    <div className="card p-3" style={{ maxHeight: "800px" }}>
                        <h4 className="text-center">Tous les producteurs à moins de...</h4>
                        <div className="text-center">
                            <input onChange={(e) => document.getElementById("valeurDistanceMax").innerHTML = e.target.value + " km"}
                                type="range" min="1" max="50" defaultValue='25' id="rangeDistanceMax" />
                            <span id="valeurDistanceMax" className="align-middle ml-2">25 km</span>
                        </div>
                        <div className="col-12 text-center">

                            <button type="submit" className="btn btn-light border border-dark" onClick={() => this.getProducteursProximites(this.props.positionUtilisateur)}>
                                Rechercher
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-search ml-2" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </button>
                        </div>
                        {/* <input className="centerBlock" type="submit" value="Rechercher" onClick={() => this.getProducteursProximites(this.props.positionUtilisateur)} /> */}
                        <div id="resultatRechercheProducteursProximites" className="mt-3" style={{ overflowY: "scroll", overflowX: "hidden" }}>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default withRouter(ProducteursProximites)