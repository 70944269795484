import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Agrivore.css';
import React from 'react';
import Menu from './Menu'
import Entete from './Entete'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // AN_20201117 import 1 module parmi d'autres dans fic source
import Charte from './Charte';
import Agrivore from './Agrivore/Agrivore.js';
import Producteur from './Producteur.js';
import Departement from './Departement.js';
import Recrutement from './Recrutement';
// import FormAg3Producteur from './AdminBDD/FormAg3Producteur';
// import ConsultationProd from './Corbeille/ConsultationProd.js';
import Login from './Login.js';
// import Inscription from './Inscription.js';
import ActivationCompte from './ActivationCompte.js';
// import ConsultationUtilisateurs from './Corbeille/ConsultationUtilisateurs.js';
// import FormAg1BDD from './AdminBDD/FormAg1BDD';
// import ConsultElementsBDDTableau from './AdminBDD/ConsultElementsBDDTableau';
// import GestionArticles from './AdminBDD/GestionArticles';
import CmdeProducteur from './CmdeProducteur';
import RecuperationPassword from './RecuperationPassword';
//import FormArticles from './AdminBDD/FormArticles';
//import ConsultArticlesBDD from './AdminBDD/ConsultArticlesBDD';
import CmdeDrive from './CmdeDrive';
import InscriptionClient from './InscriptionClient';
// import MenuAdmin from './AdminBDD/MenuAdmin';
import FormulaireContact from './FormulaireContact';
import RouteAdmin from './RouteAdmin'
import GestionCommandesClient from './GestionCommandesClient'
import VisualisationCommandeClient from './VisualisationCommandeClient';
import ExplicationSite from './ExplicationSite';
import ProducteurGestionArticles from './ProducteurGestionArticles';
import InfoClient from './InfoClient';
import ValidationCmde from './ValidationCmde';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null};
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error: error,
  })
  }

  render() {
    if (this.state.hasError) { // Page erreur
      return (
        <div className="container card mt-5 p-2">
          <h1>Une erreur est survenue</h1>
          <h6>Il semblerait que quelque chose se soit mal passé...</h6>
          <details className="mb-2" style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
          </details>
          <a href="/" onClick={() => {this.setState({hasError:false})}}><button>Retour à la page d'accueil</button></a>
        </div>
      );
    } else {
    return this.props.children;
    }
  }
}


class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      connectedUser: JSON.parse(localStorage.getItem("connectedUser"))
    }
    // if(process.env.NODE_ENV === "development"){
    //   localStorage.setItem("urlServer", "http://localhost:6543")
    // } else if(process.env.NODE_ENV === "production"){ // AN_20210413 TODO ajouter export NODE_ENV=production dans post-receive
    //   localStorage.setItem("urlServer", "ip Serveur")
    //   console.warn = () => {}; //LB : permet de ne pas afficher les warnings/erreurs aux utilisateurs
    //   console.error = () => {}
    // }
    // console.log(process.env)

    let divMeta = document.createElement("div")
    let head = document.querySelector("head")
    head.appendChild(divMeta)
    divMeta.setAttribute("id", "metaHead")
  }

  userConnect = (dUser) => {
    // console.log(dUser)
    this.setState({
      connectedUser: dUser
    })
  }

  userDisconnect = () => {
    this.setState({
      connectedUser: {}
    })
    localStorage.removeItem("connectedUser")
  }

  render() {
    const idUtilisateur = (this.state.connectedUser != null && Object.keys(this.state.connectedUser).length > 0) ? this.state.connectedUser.id : null
    const nameUtilisateur = (this.state.connectedUser != null && Object.keys(this.state.connectedUser).length > 0) ? this.state.connectedUser.name : null
    const idTiersUtilisateur = (this.state.connectedUser != null && Object.keys(this.state.connectedUser).length > 0) ? this.state.connectedUser.idtiers : null
    const idProducteur = (this.state.connectedUser != null && Object.keys(this.state.connectedUser).length > 0) ? this.state.connectedUser.x_idproducteur : null
    const bIsConnected = (this.state.connectedUser && Object.keys(this.state.connectedUser).length > 0)
    const bIsAdmin = (this.state.connectedUser && Object.keys(this.state.connectedUser).length > 0 && this.state.connectedUser.lPermissions.includes(1)) 
    const bIsProducteur = this.state.connectedUser && Object.keys(this.state.connectedUser).length > 0 && this.state.connectedUser.lPermissions.includes(3) && idProducteur != null
    const bIsConsumer = this.state.connectedUser && Object.keys(this.state.connectedUser).length > 0 && this.state.connectedUser.lPermissions.includes(4) && idTiersUtilisateur !== ""

    const dUserInformations = {
      bIsConnected: bIsConnected,
      bIsAdmin: bIsAdmin,
      bIsProducteur: bIsProducteur,
      bIsConsumer: bIsConsumer,
      idUtilisateur: idUtilisateur,
      nameUtilisateur: nameUtilisateur,
      idTiersUtilisateur: idTiersUtilisateur,
      idProducteur: idProducteur
    }
    const departements = this.props.departements;
    return (
      // AN quand passe une URL, descend la liste des Routes ci-dessous et prend la 1ere qui matche, donc si aucune, prend la dernière : /
      <ErrorBoundary>
      <Router>
        <Menu
          dUserInformations={dUserInformations}
          methUtilisateurDeconnecte={this.userDisconnect}
          methUtilisateurConnecte={this.userConnect}
        />
        <main>
          <Switch>
            <Route path='/producteur/:p_url_servie'>
              <Producteur // ajout idUtilisateur au dict dUserInformations deja existant via Object.assign({} qui reunit les 2 dictionnaires
                  dUserInformations={dUserInformations}
              />           
            </Route>
            <Route path='/charte.html'>
              <Charte/>
            </Route>
            <Route path='/recrutement.html'>
              <Recrutement/>
            </Route>
            <Route path="/departement/:dep">
              <Departement
                lDepartements={this.props.departements}
              />
            </Route>
            {/* <Route path='/formulaireBDD/producteur/:action/:idproducteur'>
              <FormAg3Producteur
                  dUserInformations={dUserInformations}
                  dChampsBDDProd={this.props.champsForm.dChampsBDD.Ag3Producteur}
              />
            </Route> */}
            {/* <Route path='/consultationProducteurBDD'>
              <ConsultationProd
                dUserInformations={dUserInformations}
                lChampsBDD={this.props.champsForm}
                lProducteurs={this.props.producteurs}
              />
            </Route> */}
            <Route path='/login.html'>
              <Login
                dUserInformations={dUserInformations}
                methUtilisateurConnecte={this.userConnect} 
                bPopup={false}
              />
            </Route>
            {/* <Route path='/inscription.html'>
              <Inscription
                dUserInformations={dUserInformations}
              />
            </Route>  */}
            <Route path='/inscriptionUtilisateur'>
              <InscriptionClient
                dUserInformations={dUserInformations}
              />
            </Route>

            <Route path='/infoClient/:idUser'>
              <InfoClient
                dUserInformations={dUserInformations}
                methUtilisateurDeconnecte={this.userDisconnect}

              />
            </Route>

            {/* <Route path='/consultationUtil.html'>
              <ConsultationUtilisateurs
                dUserInformations={dUserInformations}
              />
            </Route> */}
            {/* <Route path='/formulaireBDD/article/:action/:id'>
              <FormArticles
                  dUserInformations={Object.assign({}, dUserInformations, {idUtilisateur: this.state.connectedUser != null ? this.state.connectedUser.id : null})}
                  dChampsBDD={this.props.champsForm.dChampsBDD}
              />
            </Route>
            <Route path='/consultation/article'>
              <ConsultArticlesBDD
                dUserInformations={dUserInformations}
                dChampsBDDProd={this.props.champsForm.dChampsBDD.Ag3Producteur} 
              /> */}
            {/* </Route> */}
            {/* <Route path='/formulaireBDD/:type/:action/:id'>
              <FormAg1BDD
                  dUserInformations={dUserInformations}
                  dChampsBDD={this.props.champsForm.dChampsBDD}
              />
            </Route>
            <Route path='/consultationAg1/:type'>
              <ConsultElementsBDDTableau
                dUserInformations={dUserInformations}
                dChampsBDDProd={this.props.champsForm.dChampsBDD.Ag3Producteur} 
              />
            </Route> */}
            {/* <Route path='/admin_agrivore'>
              <MenuAdmin
                dUserInformations={dUserInformations}
              />
            </Route> */}
            <Route path='/activationCompte/:idUtilisateur'>
              <ActivationCompte/>
            </Route>
            <Route path='/validationCommande/:validationCode'>
              <ValidationCmde/>
            </Route>
            <Route path='/recuperation_password/:passwordUtilisateur'>
              <RecuperationPassword/>
            </Route>
            {/* <Route path='/gestionArticles/:idProducteur'>
              <GestionArticles
                  dUserInformations={dUserInformations}
              />
            </Route> */}
            <Route path='/commande/:idProducteur/:idUtilisateur'>
              <CmdeProducteur
                idUtilisateur={this.state.connectedUser != null ? this.state.connectedUser.id : null}
                dUserInformations={dUserInformations} // PB_2022-06-22 necessaire pour avoir les commandes sans connexion
                methUtilisateurConnecte={this.userConnect}
              />
            </Route>
            <Route path='/commandeDrive/:idDrive/:idUtilisateur'>
              <CmdeDrive
                idUtilisateur={this.state.connectedUser != null ? this.state.connectedUser.id : null}
              />
            </Route>
            <Route path='/gestionCommandes'>
              <GestionCommandesClient
                dUserInformations={dUserInformations}
              />
            </Route>
            <Route path='/visualisationCommande/:typeUtilisateur/:vBonCommande'>
              <VisualisationCommandeClient
                dUserInformations={dUserInformations}
              />
            </Route>
            <Route path='/producteur-articles'>
              <ProducteurGestionArticles
                dUserInformations={dUserInformations}
              />
            </Route>
            <Route path='/contact'>
              <FormulaireContact 
                dUserInformations={dUserInformations}
              />
            </Route>
            <Route path='/explications'>
              <ExplicationSite />
            </Route>
            <Route path='/index'>
              <Entete />
              <Agrivore
                dUserInformations={dUserInformations}
                dDepartements={departements}
                methUtilisateurConnecte={this.userConnect}
              >
              </Agrivore>
            </Route>
            <RouteAdmin 
              dDepartements={departements} 
              dUserInformations={dUserInformations}
              champsForm={this.props.champsForm}
            />
          </Switch>
        </main>


      </Router>
      </ErrorBoundary>

    );
  }
}

export default App;
