import React from 'react'
import ReactDOM from 'react-dom';
import Login from './Login';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip';

class ListeProducteurs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            largeurEcran: window.screen.availWidth
        }
    }

    formConnexion = e => {
        e.preventDefault()  //desactive le comportement par defaut du Link pour rester sur la meme page
        ReactDOM.render(<Login dUserInformations={this.props.dUserInformations} bPopup={true} methUtilisateurConnecte={this.props.methUtilisateurConnecte} />, document.querySelector("#contenuPopup"))
        // document.querySelector("#contenuPopup").innerHTML = <Login/>
        document.querySelector("#idPopup").style.display = "block"
    }

    getImgProduits(producteur, taille) {
        let lProduitsProduct = producteur.produits.map(produit => 
            <img alt="" style={{ marginRight: 5 + "px" }} key={produit.idproduit} height={taille} width={taille} title={produit.pr_libelle} src={process.env.PUBLIC_URL + "/" + produit.pr_img} />
            )

        return (
            <div className="col-md-3">
                {lProduitsProduct}
            </div>
        );
    }

    getSvgIcon = (producteur, taille) => { //LB : Recupere la liste des SVG du detail des produits  
        let lProduitsProduct = []
        let lSvgRecup = []
        for (let produitDetail of producteur.produits_details) {
            if (!lSvgRecup.includes(produitDetail.prd_image)) { //Evite les doublons 
                lSvgRecup.push(produitDetail.prd_image)
                lProduitsProduct.push(
                    <img alt="" style={{ marginRight: 5 + "px" }} key={lProduitsProduct.length} height={taille} width={taille} title={produitDetail.prd_libelle} src={process.env.PUBLIC_URL + "/svg/" + produitDetail.prd_image} />
                );
            }
        }
        return (
            <div className="col-1 d-flex flex-row ">
                {lProduitsProduct}
            </div>
        );
    }

    attribClass = (producteur) => { //LB : On affiche les informations differement selon la taille de l'ecran
        window.onresize = () => { this.setState({ largeurEcran: window.screen.availWidth }) };
        return (this.state.largeurEcran >= 768 ?
            (
                <React.Fragment key={producteur.idproducteur}>
                   

                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                            <Tooltip {...props}>
                                <div>
                                    <div>
                                        <strong> Bio : </strong>
                                        {producteur.p_bio ==="1" ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }
                                    </div>
                                    <div>
                                        <strong>Marchés : </strong>
                                        {producteur.marches.length >0 ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }
                                    </div>
                                    <div>
                                        <strong> Livraison : </strong>
                                        {producteur.p_livraison_ok ==="1" ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }
                                    </div>
                                    <div>
                                        <strong> Commande : </strong>
                                        {producteur.p_cmde_ok ==="1" ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }   

                                    </div>
                                    <div>
                                        <strong> Retrait  : </strong>
                                        {producteur.p_retraits.filter(p_retrait => p_retrait.pr_surplace_ok === "1").length > 0 ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }   

                                    </div>
                                    <div>
                                        <strong> cueillette  : </strong>
                                        {producteur.p_cueillette_ok ==="1" ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }   

                                    </div>
                                    <div>
                                        <strong> magasin  : </strong>
                                        {producteur.magasins.length > 0 ?
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/Yes_icon.png"}/>
                                            :
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/ico/No_icon.png"}/>
                                        }   

                                    </div>



                                    
                                </div>
                            </Tooltip>
                            )}
                            placement="top">
                                 <div className="container-fluid align-self-center h6">
                        <div className="row container-fluid">
                                 <div className="col-12">
                                        <h3 className="card-text d-flex justify-content-between">
                                    <a className="liensVerts" target="_blank" rel="noreferrer" href={"/producteur/" + producteur.p_url_servie}>
                                        <div style={{ cursor: "pointer" }} >
                                            <strong>{producteur.label1}</strong>
                                            <span> ●  {producteur.label2 !== "" && producteur.label2}{producteur.label2 !== "" && ","} {producteur.short_address}</span>
                                            {producteur.p_bio === "1" && <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"} />}
                                        </div>
                                    </a>
                                    <div className="d-flex">
                                        {/* affiche les icon correspondant au producteur ( livraisons, commande, cueillette ? ) */}
                                        {producteur.p_cmde_ok === "1" && document.getElementById(producteur.idproducteur+"panier") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"panier"} src={process.env.PUBLIC_URL + "/ico/paniers.png"} />}
                                        {producteur.p_retraits.filter(p_retrait => p_retrait.pr_surplace_ok === "1").length > 0 && document.getElementById(producteur.idproducteur+"drive") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"drive"} src={process.env.PUBLIC_URL + "/ico/voiture.png"} />}
                                        {producteur.magasins.length > 0 && document.getElementById(producteur.idproducteur+"magasin") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"magasin"} src={process.env.PUBLIC_URL + "/ico/magasin.png"} />}
                                        {producteur.p_livraison_ok === "1" && document.getElementById(producteur.idproducteur+"livraison") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"livraison"} src={process.env.PUBLIC_URL + "/ico/livraison.png"} />}
                                        {producteur.marches.length > 0 && document.getElementById(producteur.idproducteur+"marche") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"marche"} src={process.env.PUBLIC_URL + "/ico/marche.png"} />}
                                        {producteur.p_cueillette_ok ==="1" && document.getElementById(producteur.idproducteur+"cueillette") ===null && <img alt="" style={{ marginLeft: 5 + "px" }} height="40px" key={producteur.idproducteur+"cueillette"} src={process.env.PUBLIC_URL + "/ico/cueillette.png"} />}


                                        <button  id={"btnDescendArticle" + producteur.idproducteur} className="ml-2 px-2 py-1 btn btn-outline-success-lb " onClick={() => this.afficheArticlesProducteur(producteur)} >
                                            <span> <strong>En Savoir plus</strong></span>
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" src={process.env.PUBLIC_URL + "/ico/plus_Icon.png"} />
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor" className="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                            </svg> */}
                                        </button>
                                        <button style={{ display: 'none' }} id={"btnRemonteArticle" + producteur.idproducteur} className="ml-2 btn btn-outline-success-lb p-1" onClick={() => this.afficheArticlesProducteur(producteur)} >
                                            <span> <strong>En Savoir plus</strong></span>
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" src={process.env.PUBLIC_URL + "/ico/moins_Icon.png"} />
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor" className="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                                            </svg> */}
                                        </button>
                                    </div>
                                </h3>
                            </div>
                            <div style={{ display: "none" }} id={"articlesProducteur" + producteur.idproducteur} className="col-12 mt-2">
                                {this.getArticles(producteur)}
                            </div>
                             </div>          
                             </div>
                        </OverlayTrigger>
                            





                           
                       
                </React.Fragment>
            )
            :
            (
                <React.Fragment key={producteur.idproducteur}>
                    <div className="col-md-3 align-self-center" onClick={() => this.afficheArticlesProducteur(producteur)}>
                        <a className="liensVerts" target="_blank" rel="noreferrer" href={"/producteur/" + producteur.p_url_servie}>
                            <h3 style={{ cursor: "pointer" }} className="card-text">
                                <strong>{producteur.label1}</strong>
                            </h3>
                        </a>
                    </div>
                    <div className="col-md-4 align-self-center">
                        <a className="liensVerts" target="_blank" rel="noreferrer" href={"/producteur/" + producteur.p_url_servie}>

                            <h3 style={{ cursor: "pointer" }} className="card-text" onClick={() => this.afficheArticlesProducteur(producteur)}>
                                ● {producteur.label2 !== "" && producteur.label2}{producteur.label2 !== "" && ","} {producteur.short_address}
                                {producteur.p_bio === "1" && <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" key={producteur.idproducteur} src={process.env.PUBLIC_URL + "/svg/AB_minified_vecta_io_vertfonce_169a3bff.svg"} />}
                            </h3>
                        </a>
                        <div className='m-1 row justify-content-left'>
                                        {/* affiche les icon correspondant au producteur ( livraisons, commande, cueillette ? ) */}
                                        {producteur.p_cmde_ok === "1" && document.getElementById(producteur.idproducteur+"panier") ===null && <img alt="" className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"panier"} src={process.env.PUBLIC_URL + "/ico/paniers.png"} />}
                                        {producteur.p_retraits.filter(p_retrait => p_retrait.pr_surplace_ok === "1").length > 0 && document.getElementById(producteur.idproducteur+"drive") ===null && <img alt="" className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"drive"} src={process.env.PUBLIC_URL + "/ico/voiture.png"} />}
                                        {producteur.magasins.length > 0 && document.getElementById(producteur.idproducteur+"magasin") ===null && <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"magasin"} src={process.env.PUBLIC_URL + "/ico/magasin.png"} />}
                                        {producteur.p_livraison_ok === "1" && document.getElementById(producteur.idproducteur+"livraison") ===null && <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"livraison"} src={process.env.PUBLIC_URL + "/ico/livraison.png"} />}
                                        {producteur.marches.length > 0 && document.getElementById(producteur.idproducteur+"marche") ===null && <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"marche"} src={process.env.PUBLIC_URL + "/ico/marche.png"} />}
                                        {producteur.p_cueillette_ok ==="1" && document.getElementById(producteur.idproducteur+"cueillette") ===null && <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="25px" key={producteur.idproducteur+"cueillette"} src={process.env.PUBLIC_URL + "/ico/cueillette.png"} />}

                        </div>
                        <div className="row ">
                        
                        <button  id={"btnDescendArticle" + producteur.idproducteur} className="ml-2 px-2 py-1 btn btn-outline-success-lb " onClick={() => this.afficheArticlesProducteur(producteur)} >
                                            <span> <strong>En Savoir plus</strong></span>
                                            <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" src={process.env.PUBLIC_URL + "/ico/plus_Icon.png"} />
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor" className="bi bi-arrow-bar-down" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M1 3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5zM8 6a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 12.293V6.5A.5.5 0 0 1 8 6z" />
                                            </svg> */}
                            </button>
                            <button style={{ display: 'none' }} id={"btnRemonteArticle" + producteur.idproducteur} className="ml-2 btn btn-outline-success-lb p-1" onClick={() => this.afficheArticlesProducteur(producteur)} >
                                <span> <strong>En Savoir plus</strong></span>
                                <img alt="" style={{ marginLeft: 5 + "px" }} height="20px" src={process.env.PUBLIC_URL + "/ico/moins_Icon.png"} />
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" fill="currentColor" className="bi bi-arrow-bar-up" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 10a.5.5 0 0 0 .5-.5V3.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 3.707V9.5a.5.5 0 0 0 .5.5zm-7 2.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z" />
                                </svg> */}
                            </button>
                            
                        </div>
                    </div>
                    <div style={{ display: "none" }} id={"articlesProducteur" + producteur.idproducteur} className="col-12 mt-2">
                        {this.getArticles(producteur)}
                    </div>
                </React.Fragment>
            ));
    }

    getArticles = producteur => {
        let lProduitsDetail= this.props.lProduitsDetail

        let lArticlesProduitPrincipal = producteur.articles.filter(article => 
            lProduitsDetail.find(produitdetail => produitdetail.idproduitdetail === article.a_idproduitdetail).prd_produit === producteur.p_idproduit_principal
            ).sort((a,b) => {
            return a.a_libelle > b.a_libelle ? 1 : -1
        })

        let lArticlesAutre = producteur.articles.filter(article => 
                !lArticlesProduitPrincipal.includes(article)
            ).sort((a,b) => {
            return a.a_libelle > b.a_libelle ? 1 : -1
        })

        let lArticlesProducteurs = lArticlesProduitPrincipal.concat(lArticlesAutre).map(article =>
            <React.Fragment key={article.idarticle}>
                <hr className="my-1"/>
                <div className=""><img width='25px' height='25px' src={process.env.PUBLIC_URL + "/" + article.a_img} alt=" " /> {article.a_libelle}</div>
            </React.Fragment>
        )
        lArticlesProducteurs.push(
            <React.Fragment key="buttonCommande">
                {/* {this.props.dUserInformations.idUtilisateur !== null ? Plus utile, cmde sans connexion dispo */}
                    <a target="_blank" rel="noreferrer" href={"/commande/" + producteur.idproducteur + "/" + this.props.dUserInformations.idUtilisateur}>
                        <button style={{ fontSize: "calc(1em + 0.5vw)" }} type="button" className="btn btn-success mt-4 p-2 rounded"
                            disabled={producteur.p_cmde_ok === "1" ? false : true}
                            title={producteur.p_cmde_ok === "1" ? "Cliquez pour passer commande" : "Ce producteur ne gère pas de commande pour le moment"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill="currentColor" className="bi bi-basket mr-2 mb-1" viewBox="0 0 16 16">
                            <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        {producteur.p_cmde_ok === "1" ? "Cliquez pour passer commande" : "Ce producteur ne gère pas de commande pour le moment"}
                        </button>
                    </a>
                    {/* :
                    <a href={"/login.html"} onClick={(e) => this.formConnexion(e)}>
                        <input style={{ fontSize: "calc(0.75em + 0.5vw)" }} type="button" value="Connectez vous pour passer commande" className="mt-4" />
                    </a>
                } */}
            </React.Fragment>
        )
        return lArticlesProducteurs
    }

    afficheArticlesProducteur = producteurSelected => {
        // AN_DOC pour masquer autres producteurs que celui qu on vient de derouler :
        // this.props.lProducteurs.filter(producteur => producteur.idproducteur !== producteurSelected.idproducteur).forEach(producteur => {
        //     if (document.getElementById("divProducteur" + producteur.idproducteur) !== null) {
        //         document.getElementById("divProducteur" + producteur.idproducteur).style.border = ""
        //         document.getElementById("articlesProducteur" + producteur.idproducteur).style.display = "none"
        //         document.getElementById("btnRemonteArticle" + producteur.idproducteur).style.display = "none"
        //         document.getElementById("btnDescendArticle" + producteur.idproducteur).style.display = "inline"
        //     }
        // })
        let divArticlesProducteur = document.getElementById("articlesProducteur" + producteurSelected.idproducteur)
        let divProducteur = document.getElementById("divProducteur" + producteurSelected.idproducteur)
        if (divArticlesProducteur.style.display === "none") {
            divArticlesProducteur.style.display = "block"
            divProducteur.style.border = "solid 1px black"
            document.getElementById("btnRemonteArticle" + producteurSelected.idproducteur).style.display = "inline"
                document.getElementById("btnDescendArticle" + producteurSelected.idproducteur).style.display = "none"
        } else {
            divArticlesProducteur.style.display = "none"
            divProducteur.style.border = ""
            document.getElementById("btnRemonteArticle" + producteurSelected.idproducteur).style.display = "none"
            document.getElementById("btnDescendArticle" + producteurSelected.idproducteur).style.display = "inline"
        }
    }


    getProducteursDivHtml(producteur) {
        return (
            <div className="card-block-an rounded" id={"divProducteur" + producteur.idproducteur} key={producteur.idproducteur} >
                <div className="d-flex flex-sm-row flex-column container-fluid">
                    {this.getSvgIcon(producteur, 25)}
                    {this.attribClass(producteur)}
                    {/* {this.getImgProduits(producteur, 30)} */}
                </div>
                <hr className="barreHorizontale" />
            </div>
        );
    }

    componentDidMount = () => {
        window.onresize = () => { this.setState({ largeurEcran: window.screen.availWidth }) };
    }

    render() {
        return (
            <>
            {/* Legende des icons  */}
                <div className='container-fluid d-flex flex-sm-row m-3 flex-column justify-content-center'>
                    <div className='container-fluid'>
                        <img alt="" className='m-auto' style={{ marginLeft: 5 + "px" }} height="40px"  src={process.env.PUBLIC_URL + "/ico/paniers.png"} /> : Commande
                    </div>
                    <div className='container-fluid'>
                        <img alt="" className='m-auto' style={{ marginLeft: 5 + "px" }} height="38px"  src={process.env.PUBLIC_URL + "/ico/voiture.png"} /> : Retrait sur place
                    </div>
                    <div className='container-fluid'>
                        <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="40px"  src={process.env.PUBLIC_URL + "/ico/magasin.png"} /> : Magasins
                    </div>
                    <div className='container-fluid'>
                        <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="40px"  src={process.env.PUBLIC_URL + "/ico/livraison.png"} /> : Livraison
                    </div>
                    <div className='container-fluid'>
                        <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="40px"  src={process.env.PUBLIC_URL + "/ico/marche.png"} /> : Marchés
                    </div>
                    <div className='container-fluid'>
                        <img alt=""  className='m-auto' style={{ marginLeft: 5 + "px" }} height="40px"  src={process.env.PUBLIC_URL + "/ico/cueillette.png"} /> : Libre cueillette
                    </div>
                </div>
                {this.props.lProducteurs.map(producteur => this.getProducteursDivHtml(producteur))}
            </>
        )
    }
}

export default ListeProducteurs